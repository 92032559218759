import {getApiAxiosWithToken} from "../../utils/axiosUtil";
import {API_URL} from "../../config";


type TPersonalInfo = {
  name: string;
  birth: string;
  gender: string;
  lunar: string;
  lat: number;
  lng: number;
  knowTime: boolean;
}

export const getSajuInfo = async (personalInfo: TPersonalInfo, success: (id: string) => void, info: () => void, starcoinCheck: (knowTime: boolean) => void) => {
  const year = parseInt(personalInfo.birth.slice(0, 4));
  if (1920 <= year && year <= 2025) {
    await newFateFrame(personalInfo, success, info, starcoinCheck)
  } else {
    await fateFrame(personalInfo, success, info, starcoinCheck);
  }
};

const newFateFrame = async (personalInfo: TPersonalInfo, success: (id: string) => void, info: () => void, starcoinCheck: (knowTime: boolean) => void) => {
  const axiosWithToken = getApiAxiosWithToken();
  try {
    const resBirths = await axiosWithToken.post(
        API_URL + '/births',
        personalInfo,
    );
    const resp = await resBirths.data;
    if (resp["success"] === false) {
      info();
      return;
    }
    starcoinCheck(personalInfo.knowTime);
    await axiosWithToken.post(
        API_URL + `/births/${resp.data.id}/newfateframe`,
        personalInfo,
    );
    success(resp.data.id);
  } catch (e) {
    console.log(e);
  }
};

const fateFrame = async (personalInfo: TPersonalInfo, success: (id: string) => void, info: () => void, starcoinCheck: (knowTime: boolean) => void) => {
  const axiosWithToken = getApiAxiosWithToken();
  try {
    const resBirths = await axiosWithToken.post(
        API_URL + '/births',
        personalInfo,
    );
    const resp = await resBirths.data;
    if (resp["success"] === false) {
      info();
      return;
    }
    starcoinCheck(personalInfo.knowTime);
    await axiosWithToken.post(
        API_URL + `/births/${resp.data.id}/fateframe`,
        personalInfo,
    );
    success(resp.data.id);
  } catch (e) {
    console.log(e);
  }
};

export const getFateFrame = async (birthId: string) => {
  const axiosWithToken = getApiAxiosWithToken();
  try {
    const res = await axiosWithToken.get(API_URL + `/births/${birthId}/fateframe`);
    const resp = await res.data;
    return resp.data;
  } catch (e) {
    if (!e.response) return 'Server Error';
    return e.response.data.message;
  }
};

export const getFateFrames = async (birthId: string) => {
  const axiosWithToken = getApiAxiosWithToken();
  try {
    const res = await axiosWithToken.get(API_URL + `/births/${birthId}/fateframe`);
    const resp = await res.data;
    return resp.data;
  } catch (e) {
    if (!e.response) return 'Server Error';
    return e.response.data.message;
  }
};
