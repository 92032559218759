import axios from 'axios';

export const getApiAxiosWithToken = () => {
  const access_token = localStorage.getItem('access_token');
  return axios.create({
    headers: {
      'Authorization': `Bearer ${access_token}`
    },
    timeout: 60000,
  });
};
