import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Row = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #242424;
`;

export const FateframeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Highlight = styled.text`
  font-weight: bold;
`;

export const BtnModal = styled.button`
  cursor: pointer;
  color: white;
  padding: 5px 6px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #242424;
  border-radius: 8px;
  border: none;
  outline: none;
`;
