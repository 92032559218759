export const specialMap: any = {
  '일기격': {
    title: '일기격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '정신적 충일성이나 지향성과 행동 패턴이 통일성을 가지고 있는 특징이 있어, 삶의 방향을 추구하는 방식에서 일관성을 가짐',
          '이러한 기운적 편중으로 인하여 번영할 때 크게 번영하였다가 쇠락할 때 크게 쇠락하는 속성이 따르고 榮枯盛衰가 짧은 시간에 바뀌는 속성도 잘 따름',
        ]
      }
    ]
  },
  '시묘격': {
    title: '시묘격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '중년이나 말년에 한번 뜻밖의 큰 부귀를 취할 수 있음',
          '또는 중년 말이나 말년에 변화 적은 일이나 소극적인 형태로 활동하면 무사하게 지내는 특성도 따름'
        ]
      }
    ]
  },
  '시록격': {
    title: '시록격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '관직 運(운)에 매우 좋음.  ',
          '현대에서는 좋은 직장을 얻게 되거나 직업적 성공의 기운이 따르는 것으로 해석함'
        ]
      }
    ]
  },
  '일귀격': {
    title: '일귀격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '좋은 배우자를 얻는 기운이 유도되고 중년부터 사회적, 경제적 번영이 잘 이루어지는 특징을 가짐',
        ]
      }
    ]
  },
  '일덕격': {
    title: '일덕격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '좋은 조직이나 기술 분야에 인연하여 번영이 가능할 것을 의미함'
        ]
      }
    ]
  },
  '사위순전격': {
    title: '사위순적격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '運(운)의 흐름이 좋으면 1등을 이룩할 수 있는 기운이 있는 것으로 남부러운 번영을 이룩함'
        ]
      }
    ]
  },
  '자요사격': {
    title: '자요사격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '변화가 많지 않은 조직이나 전문직, 교수직, 연구직 등으로 무사 평탄한 삶을 살지만, 가정적인 안정이 약하여 굴곡이나 애로를 겪는 경우가 많음'
        ]
      }
    ]
  },
  '축요사격': {
    title: '축요사격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '변화가 많지 않은 조직이나 전문직, 교수직, 연구직 등으로 무사 평탄한 삶을 사는 경우가 많고,  전문적인 기술로 사업적 성공을 크게 이루는 경우도 많음',
          '고전에서는 고관대작을 이룬다 함. 가정적인 안정이 약하여 굴곡이나 애로를 겪는 경우가 많음'
        ]
      }
    ]
  },
  '비천록마격': {
    title: '비천록마격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '국가의 고관대작을 함',
          '특히 법조계, 경찰, 군인에서 실력자로 성장함'
        ]
      }
    ]
  },
  '임기용배격': {
    title: '임기용배격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '용의 등에 올라 탔다 하여, 정치가나 행정 관료등에 진출해서 출세가 많고, 일정 세월 관직이나 규모가 있는 조직 사회에서 기반을 이룩한 뒤, 자기 사업을 이룩하는 경우도 많음'
        ]
      }
    ]
  },
  '을요서귀격': {
    title: '을요서귀격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '사회적, 경제적 번영의 단위가 커지는 효과가 있음',
          '비슷한 運(운)의 흐름을 만났을 때 타인보다 월등한 단위의 성공이 이루어지는 경우임'
        ]
      }
    ]
  },
  '정란차격': {
    title: '정란차격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '재물이나 의식주의 번영이 원만한 흐름이 많고, 별도의 재물 번영을 재테크나 별도의 투자로 경제적 번영을 이루는 경우가 많음'
        ]
      }
    ]
  },
  '합록격': {
    title: '합록격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '중년부터 사회적, 경제적 번영이 원만한 경우가 많고, 말년에 가까우면서 직업적 실력 발휘, 경제적 번영이 잘 이루어짐.  '
        ]
      }
    ]
  },
  '육음조양격': {
    title: '육음조양격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '의식주나 건강, 재물의 번영이 원만한 경우가 일반적이고, 고전에서는 관직을 불러들이는 요소가 강하다고 봄.  ',
          '격이 잘짜이면 대중을 초월하고 고귀한 명예와 함께 부귀영화를 누린다'
        ]
      }
    ]
  },
  '형합격': {
    title: '형합격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '의식주나 건강, 재물의 번영이 다른 평균적인 수준보다 좋은 경우가 많고 운을 만나면 영웅호걸로서 명리쌍전하는 귀명임'
        ]
      }
    ]
  },
  '공록격': {
    title: '공록격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '변화가 많지 않은 공직, 교수직, 연구직 등에서 더 번영함'
        ]
      }
    ]
  },
  '공귀격': {
    title: '공귀격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '귀격의 삶을 살아가게 되고, 현대 사회에서는 경제적 성취의 단위가 커지는 효과가 따름'
        ]
      }
    ]
  },
  '육임추간격': {
    title: '육임추간격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '사회적 성공을 크게 이룰 수 있는 조건을 갖춘 운명으로, 사회적, 경제적 번영이 지속적이고 크게 이루어지는 속성이 따름'
        ]
      }
    ]
  },
  '육갑추건격': {
    title: '육갑추건격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '성공의 에너지가 많고, 성공의 흐름에서 그 정도가 비상함',
        ]
      }
    ]
  },
  '구진득위격': {
    title: '구진득위격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '큰 조직, 국가 조직, 큰 기업 조직 중심으로 성공함',
          '사업을 구하더라도 큰 조직과 손을 잡은 납품, 용역, 대리점, 프랜차이즈 등의 형태 비즈니스가 많음'
        ]
      }
    ]
  },
  '도충격': {
    title: '도충격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '현대에서는 자격, 교육, 전문 기술, 스포츠 등의 분야에서 성공을 이루는 경우가 많음',
          '성패가 시기에 따라 큰 폭으로 이루어지는 속성이 있음'
        ]
      }
    ]
  },
  '현무당권격': {
    title: '현무당권격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '성공이나 번영의 조건이 잘 주어진 것으로 양적 측면에서 성공을 더 많이 이루는 경우가 많고 인덕, 감투 등에도 상대적으로 발전의 기운이 많음 '
        ]
      }
    ]
  },
  '천관지축격': {
    title: '천관지축격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '번영의 에너지가 깔려 있어 직업적 성공, 경제적 성공의 기회가 많음',
          '가정만 돌보는 여성의 경우에도 내실과 안정적인 삶을 삶'
        ]
      }
    ]
  },
  '공재격': {
    title: '공재격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '큰 재물의 성취를 이룩함',
          '최소한 반듯한 조직 사회나 안정적인 일을 통하여 내실 있는 삶을 삶'
        ]
      }
    ]
  },
  '재관쌍미격': {
    title: '재관쌍미격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '남들에 비해 상대적으로 사회적, 경제적 번영의 기회가 많고, 안정적인 삶을 사는 경우가 대부분임'
        ]
      }
    ]
  },
  '복덕격': {
    title: '복덕격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '성공의 변화 폭이 큰 속성이 있어, 運(운)의 흐름이 안정적일 때에는 남다른 성취와 보상이 따르지만 運(운)의 쇠퇴기에는 평균적인 삶보다 더 힘들게 지내게 되는 고충이 잘 따름'
        ]
      }
    ]
  },
  '자오쌍포격': {
    title: '자오쌍포격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '현대에서는 역마 관련 분야(항공, 조선, 자동차, 전기 전자, 통신, 건설, 무역, 외교, 관광, 영업 등)에 인연하여 성공하는 예가 많음'
        ]
      }
    ]
  },
  '월묘격': {
    title: '월묘격',
    list: [
      {
        title: '특수격의 일반적 특성',
        contents: [
          '사회적인 번영이 다소 더디게 이루어지는 경우가 일반적으로 많은데 運(운)이 올 때 비상(非常)한 성공을 이룸'
        ]
      }
    ]
  },
};
