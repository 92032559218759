import React, {useState} from 'react';
import {useHistory} from 'react-router'
import * as styled from './Style';
// import DropDown from "../Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {isLoginedSelector, starCoinSelector} from "../../../modules/selectors";
import {useTranslation} from "react-i18next";
import "./index.css";
import {LogoutAsync} from "../../../modules/auth";
import Hamburger from "./Hamburger";


const Header = () => {
  const history = useHistory();
  const [pathName, setPathName] = useState(history.location.pathname);
  const [t] = useTranslation();

  const dispatch = useDispatch();


  const handlerLogout = () => {
    const success = () => {
      localStorage.clear();
      history.push('/home');
    };
    dispatch(LogoutAsync(success));
  };

  const isLogined = useSelector(isLoginedSelector);
  const starCoin = useSelector(starCoinSelector);
  // console.log(history.location);

  const handleMenuClick = (pathname: string) => {
    history.push(pathname);
    setPathName(pathname);
  };

  return (
      <styled.Wrapper>
        <styled.Container>
          <styled.ImgLogo onClick={() => handleMenuClick('/home')}/>
          <Hamburger starCoin={starCoin.starCoin} bonusStarCoin={starCoin.bonus} name={starCoin.nickname}
                     isLogined={isLogined}/>
          <styled.MenuWrapper>
            <styled.MenuItem isActive={pathName === '/birth'} onClick={() => handleMenuClick('/birth')}>
              새 생일 입력
            </styled.MenuItem>
            {/*<styled.MenuItem isActive={pathName === '/list'} onClick={() => handleMenuClick('/list')}>*/}
            {/*  생일 저장목록*/}
            {/*</styled.MenuItem>*/}
            {/*<styled.MenuItem*/}
            {/*    onClick={() => window.open("https://www.notion.so/Blog-by-Bo5stars-4f252774e29740d6a605a6d01b1b5ff4")}>*/}
            {/*  블로그*/}
            {/*</styled.MenuItem>*/}
            {/*<styled.MenuItem isActive={pathName === '/shop'} onClick={() => handleMenuClick('/shop')}>*/}
            {/*  코인 샵*/}
            {/*</styled.MenuItem>*/}
            {/*<styled.MenuItem onClick={() => history.push('/home')}>*/}
            {/*  FAQ*/}
            {/*</styled.MenuItem>*/}
            {isLogined ? (
                <>
                  <styled.MenuItem isActive={pathName === '/list'} onClick={() => handleMenuClick('/list')}>
                    생일 저장목록
                  </styled.MenuItem>
                  {/*<styled.MenuItem isActive={pathName === '/shop'} onClick={() => handleMenuClick('/shop')}>*/}
                  {/*  코인 샵*/}
                  {/*</styled.MenuItem>*/}
                  <styled.MenuItem
                      onClick={() => window.open("https://www.notion.so/Blog-by-Bo5stars-4f252774e29740d6a605a6d01b1b5ff4")}>
                    블로그
                  </styled.MenuItem>
                  <styled.MenuItem isActive={pathName === '/guide_faq'} onClick={() => handleMenuClick('/guide_faq')}>
                    가이드 / FAQ
                  </styled.MenuItem>
                  <styled.MenuItem onClick={() => handlerLogout()}>
                    로그아웃
                  </styled.MenuItem>
                  <styled.MenuItem>
                    <styled.StarCoinWrapper>
                      <styled.IconStarCoin/>
                      <styled.TextStarCoin>
                        {starCoin.starCoin}
                      </styled.TextStarCoin>
                    </styled.StarCoinWrapper>
                  </styled.MenuItem>
                  <styled.MenuItem onClick={() => history.push('/mypage')}>
                    <styled.Profile>
                      {starCoin.nickname[0] && starCoin.nickname[0].toUpperCase()}
                    </styled.Profile>
                  </styled.MenuItem>
                </>
            ) : (
                <>
                  <styled.MenuItem isActive={pathName === '/guide_faq'} onClick={() => handleMenuClick('/guide_faq')}>
                    가이드 / FAQ
                  </styled.MenuItem>
                  <styled.BtnSignin onClick={() => history.push('/login')}>
                    로그인
                  </styled.BtnSignin>
                </>
            )}
          </styled.MenuWrapper>
        </styled.Container>
      </styled.Wrapper>
  );
};

export default Header;
