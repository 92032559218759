import React from 'react';
import * as styled from '../Style';

export const Chowho = ({chowho}: any) => {
  return (
      <styled.Wrapper>
        {chowho.조후용신 === undefined ?
            <>
              <div>필요없음</div>
            </> :
            <div>
              <styled.Row>
                조후 용신 - {chowho.조후용신}
              </styled.Row>
              <styled.Row>
                조후 희신 - {chowho.조후희신}
              </styled.Row>
            </div>
        }
      </styled.Wrapper>
  )
};
