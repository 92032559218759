import React from 'react';
import * as styled from './Style';
// 甲乙丙丁戊己庚辛壬癸
// 子丑寅卯辰巳午未申酉戌亥
const backgroundColor = (hanja: string, index: number) => {
  if (index === 1) {
    if (hanja === '甲' || hanja === '乙') return 'rgba(71, 116, 182)';
    if (hanja === '丙' || hanja === '丁') return 'rgba(184, 53, 91)';
    if (hanja === '戊' || hanja === '己') return 'rgba(138, 110, 49)';
    if (hanja === '庚' || hanja === '辛') return 'rgba(158, 168, 166)';
    if (hanja === '壬' || hanja === '癸') return 'rgba(0, 0, 0)';
  } else if (index === 2) {
    if (hanja === '寅' || hanja === '卯' || hanja === '辰') return 'rgba(71, 116, 182)';
    if (hanja === '巳' || hanja === '午' || hanja === '未') return 'rgba(184, 53, 91)';
    if (hanja === '申' || hanja === '酉' || hanja === '戌') return 'rgba(158, 168, 166)';
    if (hanja === '亥' || hanja === '子' || hanja === '丑') return 'rgba(0, 0, 0)';
  }
  return 'rgba(255,255,255)';
};

const letterColor = (hanja: string, index: number) => {
  // 원래 노란색 : 'rgba(254, 248, 107)';
  const colorInsideColored = 'white';
  if (index === 1) {
    if (hanja === '甲' || hanja === '乙') return colorInsideColored;
    if (hanja === '丙' || hanja === '丁') return colorInsideColored;
    if (hanja === '戊' || hanja === '己') return colorInsideColored;
    if (hanja === '庚' || hanja === '辛') return colorInsideColored;
    if (hanja === '壬' || hanja === '癸') return colorInsideColored;
  } else if (index === 2) {
    if (hanja === '寅' || hanja === '卯' || hanja === '辰') return colorInsideColored;
    if (hanja === '巳' || hanja === '午' || hanja === '未') return colorInsideColored;
    if (hanja === '申' || hanja === '酉' || hanja === '戌') return colorInsideColored;
    if (hanja === '亥' || hanja === '子' || hanja === '丑') return colorInsideColored;
  }
  // 원래 흰색 : 'rgba(255,255,255)';
  return '#242424';
};

interface ILetter {
  hanja: string;
  index: number;
}

const SajuLetter = ({ hanja, index }: ILetter) => {
  return (
    <styled.LetterWrapper
      backgroudColor={backgroundColor(hanja, index)}
      letterColor={letterColor(hanja, index)}
    >
      <styled.Letter letterSize={'3.0rem'}>
        {hanja}
      </styled.Letter>
    </styled.LetterWrapper>
  )
};

export default SajuLetter;
