import styled from "styled-components";
import imgTitleStarCoin from "../../assets/image_starcoin_b.png";
import imgStarCoinS from "../../assets/image_starcoin_s.png";

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 15px;
  padding-bottom: 100px;
  @media (max-width: 768px) {
  }
  @media (min-width: 760px) {
    height: 100vh;
  }
`;

export const TitleContainer = styled.div`
  text-align: center;
`;
export const ImgTitleStarCoin = styled.img.attrs({ src: imgTitleStarCoin })`
  margin-bottom: 35px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
export const Title = styled.div`
  font-size: 42px;
  font-weight: 900;
  margin-bottom: 35px;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 15px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
    margin-bottom: 15px;
  }
`;
export const TitleHighlighted = styled.span`
  color: #fdaf0f;
`;
export const SubTitle = styled.div`
  font-size: 16px;
  color: #242424;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 20px;
  }
`;

export const ProductContainer = styled.div`
  margin-bottom: 90px;
  @media (max-width: 768px) {
    margin-bottom: 45px;
  }
`;

export const ProductFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const CoinWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const PackageTitleContainer = styled.div`
  text-align: center;
`;
export const PackageTitle = styled(Title)`
`;
export const PackageSubTitle = styled(SubTitle)`
`;
export const PackageFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const PackageItemWrapper = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  margin-right: 20px;
  cursor: pointer;
  &:last-child {
    margin-right: 0px;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
interface IPackageItemHeader {
  bgImgCss: string;
}
export const PackageItemHeader = styled.div<IPackageItemHeader>`
  padding: 30px;
  font-size: 24px;
  font-weight: 900;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: ${(props) => props.bgImgCss};
`;
export const PackageItemHeaderText = styled.div`
`;
export const PackageItemContent = styled.div`
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ImgStarCoinS = styled.img.attrs({ src: imgStarCoinS })`
  margin-right: 10px;
`;
export const PackageItemContentText = styled.div`
`;
export const PackageItemContentSmallText = styled.div`
`;
