import styled from 'styled-components';

export const YearColumnWrapper = styled.div`
  padding: 5px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
  }
`;