import styled from 'styled-components';

export const TableRowWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  max-width: 400px;
  margin: 0 auto;
  @media only screen and (max-width: 500px) {
    padding: 15px;
  }
`;

export const TableCellWrapper = styled.div`
  text-align: center;
  flex: 1;
  &:last-child {
    border-right: none;
  }
  @media only screen and (max-width: 500px) {
  }
`;

interface IAgeLetter {
  daeunAgeColor: string;
}

export const TableTitle = styled.div<IAgeLetter>`
  font-weight: bold;
  font-size: 1.6rem;
  color: ${(props) => props.daeunAgeColor || 'black'};
  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const SajuColumnWrapper = styled.div`
  padding: 5px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
  }
`;