import styled from 'styled-components';
import {Btn} from "../Signup/Styles";

export const Container = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
`;
export const Label = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  &:first-child {
    margin-top: 0px;
  }
`;
export const InputWrapper = styled.div`
  border-radius: 10px;
  display: flex;
  width: 100%;
  @media only screen and (max-width: 500px) {
    display: block;
    text-align: center;
  }
`;
export const Input = styled.input`
  border-radius: 10px;
  border: solid 2px #484848;
  max-height: 45px;
  padding: 15px 20px;
  outline: none;
  flex: 1;
  width: 100%;
  &::placeholder {
    color: #c1c1c1;
  }
`;

interface IHelpText {
  isError: boolean;
}

export const HelpText = styled.div<IHelpText>`
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  color: ${(props) => props.isError ? 'red' : 'blue'};
`;
export const BtnSignup = styled(Btn)`
  margin-top: 30px;
  cursor: pointer;
`;
export const Title = styled.div`
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 35px;
`;
