import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import GuideModal from 'src/components/Dragon/GuideModal';
import { Result } from 'src/components/Dragon/Result';
import { getFateFrame, getFateFrames } from "../../core/api/birth";
import { useDetectMobile } from "../../hooks/useDetectMobile";
import { Loading } from "../Loading";
import "./index.css";
import * as styled from "./Styles";

type TFateFrame = {
  saju_body: any[];
  daeun: any[];
  age: string;
  real_birth: string;
  nickname: string;
  gender: string;
  chowhodo: any;
  main_fateframe: any;
  sub_fateframe: any;
  seagod: any;
  whaframe: any;
  filter: any;
}

const Dragon = (location: any) => {
  const [fateFrame, setFateFrame] = useState<TFateFrame | null>(null);
  const [fateFrames, setFateFrames] = useState<TFateFrame[]>([]);

  const [knowTime, setKnowTime] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [timeIndex, setTimeIndex] = useState(0);
  const [showGuideModal, setShowGuideModal] = useState<boolean>(!!!localStorage.getItem('never_see_guide'));

  const isMobile = useDetectMobile();

  const [t] = useTranslation();

  useEffect(() => {
    const { match, history } = location;
    const { state } = history.location;
    const { id } = match.params;
    if (!id) history.goBack();

    setKnowTime(state.knowTime);
    if (state.knowTime) {
      handleGetFateFrame(id);
    } else {
      handleGetFateFrames(id);
    }
  }, []);

  const handleGetFateFrames = async (birthId: string) => {
    const _fateFrames = await getFateFrames(birthId);
    setFateFrames(_fateFrames);
    setIsLoading(false);
  };

  const handleGetFateFrame = async (birthId: string) => {
    const _fateFrame = await getFateFrame(birthId);
    setFateFrame(_fateFrame);
    setIsLoading(false);
  };

  if (isLoading) return <Loading />;

  if (knowTime && fateFrame) {
    return (
      <styled.DragonContainer>
        {showGuideModal && <GuideModal show={true} onClose={() => setShowGuideModal(false)} />}
        <Result fateFrame={fateFrame} />
      </styled.DragonContainer>
    )
  } else if (fateFrames[timeIndex]) {
    return (
      <styled.DragonContainer>
        {showGuideModal && <GuideModal show={true} onClose={() => setShowGuideModal(false)} />}
        <Result fateFrame={fateFrames[timeIndex]} timeIndex={timeIndex} setTimeIndex={setTimeIndex} />
      </styled.DragonContainer>
    )
  }
  return (
    <div>Server Error</div>
  )
};

export default Dragon;
