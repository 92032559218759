import styled from 'styled-components';
import imgGuide1 from '../../../assets/img_popup_01.png';
import imgGuide2 from '../../../assets/img_popup_02.png';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 310;
`;
export const Dimmed = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  opacity: 0.8;
  background-color: #15171a;
`;
export const ModalWrapper = styled.div`
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff; 
  position: relative;
  max-height: 100%;
  max-width: 500px;
  color: #242424;
  @media (max-width: 500px) {
    max-height: 700px;
    max-width: 380px;
    width: calc(100% - 30px);
  }
`;

export const BtnClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;
export const ContentWrapper = styled.div`
  overflow-y: scroll;
  margin-bottom: 50px;
  max-height: 500px;
  @media (max-width: 500px) {
    max-height: 300px;
    margin-bottom: 30px;
  }
`;
export const ContentList = styled.div`
`;
export const ContentTitle = styled.div`
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 15px;
`;
export const ContentDesc = styled.div`
  font-size: 14px;
  margin-bottom: 25px;
`;
export const ImgGuide1 = styled.img.attrs({ src: imgGuide1 })`
  max-width: 100%;
  margin-bottom: 20px;
`;
export const ImgGuide2 = styled.img.attrs({ src: imgGuide2 })`
  max-width: 100%;
  margin-bottom: 20px;
`;
export const CheckBoxWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;
export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 15px;
  cursor: pointer;
`;
export const TextCheckBox = styled.label`
  user-select: none;
  cursor: pointer;
`;
export const BtnWrapper = styled.div`
  text-align: center;
`;
export const BtnCheck = styled.button`
  cursor: pointer;
  border-radius: 21px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 5px 50px;
  margin-top: 5px;
  color: #ffffff;
  background-color: #242424;
`;
