import styled from 'styled-components';

interface ILetterWrapper{
    backgroudColor: string;
    letterColor: string;
    border: string;
}

export const LetterWrapper = styled.div<ILetterWrapper>`
    background-color: ${(props) => props.backgroudColor || 'white'};
    color: ${(props) => props.letterColor || 'black'};
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-radius: 5px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0px;
    }
`;

export const Letter = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 1px;
    @media (max-width: 850px) {
      font-size: 1.2rem;
    }
    @media (max-width: 450px) {
      font-size: 1rem;
    }
`;
