import React from 'react';
import {useHistory} from "react-router";
import * as styled from "./Style";
import {Button, Checkbox, Form} from "antd";
import {BASIC} from "../../assets/globalColor";
import {CheckSquareOutlined} from "@ant-design/icons/lib";
import "antd/dist/antd.css";
import {personPolicy} from "./personPolicy";
import {servicePolicy} from "./servicePolicy";

export const Policy = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = () => {
    history.push('/signup');
  };

  return (
      <Form
          onFinish={onFinish}
          name="basic"
          form={form}
      >
        <styled.Container>
          <styled.PolicyWrapper>
            <h2><CheckSquareOutlined/> 서비스 이용약관</h2>
            <styled.TextArea readOnly>
              {servicePolicy}
            </styled.TextArea>
          </styled.PolicyWrapper>
          <Form.Item
              name="service-agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject('약관의 내용에 동의하셔야 회원가입 하실 수 있습니다.'),
                },
              ]}
          >
            <Checkbox
                name="service-agreement"
            >
              위의 이용약관에 동의합니다.
            </Checkbox>
          </Form.Item>

          <styled.PolicyWrapper>
            <h2><CheckSquareOutlined/> 개인정보 수집 및 이용 동의</h2>
            <styled.TextArea readOnly>
              {personPolicy}
            </styled.TextArea>
          </styled.PolicyWrapper>

          <Form.Item
              name="personal-agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject('약관의 내용에 동의하셔야 회원가입 하실 수 있습니다.'),
                },
              ]}
          >
            <Checkbox
                name="service-agreement"
            >
              위의 이용약관에 동의합니다.
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
                type="primary" htmlType="submit"
                style={{background: BASIC.blue, borderColor: BASIC.blue}}
            >
              확 인
            </Button>
          </Form.Item>
        </styled.Container>
      </Form>
  )
};
