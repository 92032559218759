import React from "react";
import Footer from "rc-footer";
import "rc-footer/assets/index.css";
import "./index.css";
import {useHistory} from "react-router";
import {useDetectMobile} from "../../../hooks/useDetectMobile";

const MyFooter = () => {
  const history = useHistory();

  const isMobile = useDetectMobile();

  return (
      <Footer
          maxColumnsPerRow={4}
          theme={"dark"}
          columns={[
            {
              icon: <a><img
                  src={require("../../../assets/bo5_logo_box_white.png")}
                  style={{width: '130px'}}
                  onClick={() => history.push('/home')}
              /></a>,
              items: [
                {
                  title: '밸런스 오브 파이브',
                  url: '/home',
                },
                {
                  title: '모든 사람들이 손쉽게 이용할 수 있는 운명학 서비스를\n' +
                      '개발/운영하는 Technology Company입니다.\n' +
                      '\n' + '\n' +
                      '태양계의 천체 운동을 기준으로 바뀌어 가는 기운 변화와\n' +
                      '개인적 운명 변화를 매칭하여 쉽게 활용할 수 있도록\n' +
                      '끊임없이 기술적 개발을 하고 있으며,\n' +
                      '고객의 새로운 니즈에 부합하여\n' +
                      '최고의 운명학 분석 서비스를 지속적으로 제공하겠습니다\n'
                },
              ],
            },
            {
              title: 'CONTACT',
              items: [
                {
                  title: 'info.bo5stars@gmail.com\n',
                  url: 'mailto:info.bo5stars@gmail.com',
                  LinkComponent: 'a'
                },
                {
                  title: 'Office  |  213.739.5700',
                },
                {
                  title: 'Fax  |  213.739.5703',
                },
                {
                  title: '3959 Wilshire Blvd Ste B21, ' + '\n' +
                      'Los Angeles, CA 90010',
                  LinkComponent: 'pre'
                },
              ],
            },
            {
              title: 'SERVICES',
              items: [
                {
                  title: '사주 알아보기',
                  url: '/birth',
                  LinkComponent: 'a'
                },
                {
                  title: '블로그',
                  url: 'https://www.notion.so/Blog-by-Bo5stars-4f252774e29740d6a605a6d01b1b5ff4',
                  LinkComponent: 'a'
                },
                // {
                //   title: '코인샵',
                //   url: '/shop',
                //   LinkComponent: 'a'
                // },
                {
                  title: 'FAQ',
                  url: '/guide_faq',
                  LinkComponent: 'a'
                },
                {
                  title: '서비스이용약관',
                  url: '/corp',
                  LinkComponent: 'a'
                },
                {
                  title: '개인정보처리방침',
                  url: '/corp',
                  LinkComponent: 'a'
                },
              ],
            },
          ]}
          bottom="COPYRIGHT (C) bo5stars ALL RIGHTS RESERVED."
      />
  )
};

export default MyFooter;
