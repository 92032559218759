import React from 'react';
import imgRat from '../assets/01_rat.png';
import imgCow from '../assets/02_cow.png';
import imgRabbit from '../assets/03_rabbit.png';
import imgTiger from '../assets/04_tiger.png';
import imgDragon from '../assets/05_dragon.png';
import imgSnake from '../assets/06_snake.png';
import imgHorse from '../assets/07_horse.png';
import imgSheep from '../assets/08_sheep.png';
import imgMonkey from '../assets/09_monkey.png';
import imgChicken from '../assets/10_chicken.png';
import imgDog from '../assets/11_dog.png';
import imgPig from '../assets/12_pig.png';

export const markChowhodo = {
  0: {
    style: {
      color: '#108ee9',
    },
    label: <strong>추움</strong>,
  },
  20: {
    style: {
      color: '#3864b7',
    },
    label: <strong>차가움</strong>,
  },
  40: {
    style: {
      color: '#6b3f8c',
    },
    label: <strong>서늘함</strong>,
  },
  60: {
    style: {
      color: '#a52668',
    },
    label: <strong>따듯함</strong>,
  },
  80: {
    style: {
      color: '#df043d',
    },
    label: <strong>더움</strong>,
  },
  100: {
    style: {
      color: '#f50',
    },
    label: <strong>뜨거움</strong>,
  },
};

export const markFateframe = {
  0: {
    style: {
      color: '#108ee9',
    },
    label: <strong>태약</strong>,
  },
  20: {
    style: {
      color: '#3864b7',
    },
    label: <strong>신약</strong>,
  },
  40: {
    style: {
      color: '#6b3f8c',
    },
    label: <strong>신약_평</strong>,
  },
  60: {
    style: {
      color: '#a52668',
    },
    label: <strong>신강_평</strong>,
  },
  80: {
    style: {
      color: '#df043d',
    },
    label: <strong>신강</strong>,
  },
  100: {
    style: {
      color: '#f50',
    },
    label: <strong>태강</strong>,
  },
};

// 子丑寅卯 辰巳午未 申酉戌亥
export const chineseZodiac: any = {
  '子': {
    img: imgRat,
    month: 1,
  },
  '丑': {
    img: imgCow,
    month: 2,
  },
  '寅': {
    img: imgTiger,
    month: 3,
  },
  '卯': {
    img: imgRabbit,
    month: 4,
  },
  '辰': {
    img: imgDragon,
    month: 5,
  },
  '巳': {
    img: imgSnake,
    month: 6,
  },
  '午': {
    img: imgHorse,
    month: 7,
  },
  '未': {
    img: imgSheep,
    month: 8,
  },
  '申': {
    img: imgMonkey,
    month: 9,
  },
  '酉': {
    img: imgChicken,
    month: 10,
  },
  '戌': {
    img: imgDog,
    month: 11,
  },
  '亥': {
    img: imgPig,
    month: 12,
  },
};

