import {ThunkDispatch} from "redux-thunk";
import * as types from './types';
import {T_LOGIN_PARAMS} from './types';
import axios from 'axios';
import {getApiAxiosWithToken} from "../../utils/axiosUtil";
import {API_URL} from "../../config";
import {StarCoinAsync} from '../starcoin/action';

export const LoginAsync = (values: T_LOGIN_PARAMS, success: () => void, validAccount: () => void, invalidAccount: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
  dispatch({type: types.T_LOGIN.PENDING});
  try {
    if (values.SnsType === "naver" || values.SnsType === "kakao") {
      const res = await axios.post(API_URL + '/sns-session', values, {});
      dispatch({type: types.T_LOGIN.SUCCESS, payload: res.data});
    } else {
      const res = await axios.post(API_URL + '/session', values, {});
      dispatch({type: types.T_LOGIN.SUCCESS, payload: res.data});
    }
    success();
    validAccount();
    dispatch(StarCoinAsync())
  } catch (e) {
    dispatch({type: types.T_LOGIN.FAILURE, payload: e});
    invalidAccount();
  }
};

export const LogoutAsync = (success: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
  dispatch({type: types.T_LOGOUT.PENDING});
  try {
    await axios.delete(API_URL + '/session');
    dispatch({type: types.T_LOGOUT.SUCCESS});
    success();
  } catch (e) {
    dispatch({type: types.T_LOGOUT.FAILURE, payload: e});
  }
};

export const RefreshTokenAsync = (success: () => void) => async (dispatch: ThunkDispatch<types.AuthState, void, types.AuthAction>) => {
  dispatch({type: types.T_REFRESH_TOKEN.PENDING});
  try {
    //TODO 필요없어지면 지우기
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken || refreshToken === "undefined") return;

    const axiosWithToken = getApiAxiosWithToken();
    const payload = {refreshToken: refreshToken};
    const res = await axiosWithToken.post(API_URL + '/token/refresh', payload);
    dispatch({type: types.T_REFRESH_TOKEN.SUCCESS, payload: res.data});
    success();
  } catch (e) {
    dispatch({type: types.T_REFRESH_TOKEN.FAILURE, payload: e});
  }
};
