export const birthPreproccessing = (birth: any, knowTime: boolean): any => {
  const birthList = [...birth];
  const birthYMD = birthList.slice(0, 3);
  let resultBirth = '';
  if (knowTime) {
    const birthHM = birthList.slice(3, 5);
    resultBirth = birthYMD.join('-') + ' ' + birthHM.join(':');
  } else {
    resultBirth = birthYMD.join('-');
  }
  return resultBirth;
};
