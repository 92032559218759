import {ActionType} from 'typesafe-actions';
import {createAsyncActionTypes} from "../../utils/reduxUtil";

const prefix: string = 'auth';

export type T_LOGIN_PARAMS = {
  email?: string;
  password?: string;
  ContentType?: string;
  SnsType?: string;
  Authorization?: string;
}
export const T_LOGIN = createAsyncActionTypes(`${prefix}/LOGIN`);
export const T_LOGOUT = createAsyncActionTypes(`${prefix}/LOGOUT`);
export const T_REFRESH_TOKEN = createAsyncActionTypes(`${prefix}/REFRESH_TOKEN`);

export type AuthAction = ActionType<any>;

export type T_User = {
  name: string;
  email: string;
  user_id: number;
}

export type AuthState = {
  isLogining: boolean;
  user: null | T_User;
}

