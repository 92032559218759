import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 10%;
  padding-top: 25px;
  padding-bottom: 50px;
  height: 100vh;
  @media (max-width: 1500px) {
    height: auto;
  }
`;

export const PolicyWrapper = styled.div`
  padding-bottom: 20px;
`;

export const TextArea = styled.textarea`
  display: block;
  padding: 20px;
  width: 50%;
  height: 150px;
  background: #fff;
  line-height: 1.6em;
  resize: none;
  @media (max-width: 960px) {
    width: 90%;
  }
`;
