import React, { useEffect, useState } from 'react';
import * as styled from './Style';
import { useHistory } from "react-router";
import { TProduct } from 'src/containers/Shop';

interface IProductProps {
  product: TProduct;
}

export const Product = ({ product }: IProductProps) => {
  const history = useHistory();
  const [hover, setHover] = useState<boolean>(false);

  return (
    <styled.ProductItemWrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} key={'product-' + product.id} onClick={() => history.push(`/checkout/${product.id}`)}>
      {hover &&
        <styled.HighlightWrapper>
          <styled.HighlightSelectWrapper />
          <styled.HighlightSelectText>SELECT</styled.HighlightSelectText>
        </styled.HighlightWrapper>
      }
      <styled.CoinWrapper>
        <styled.ImgStarCoinM /> X {product.normal_token_increment}
      </styled.CoinWrapper>
      <styled.PriceWrapper>{product.total} $</styled.PriceWrapper>
    </styled.ProductItemWrapper>
  )
};
