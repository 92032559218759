import React from 'react';
import * as styled from '../Style';

export const SeaGod = ({seagod}: any) => {
  const {jongkuk} = seagod;
  let whichSeagod = null;
  if (seagod.병약_용신 !== undefined || seagod.통관_용신 !== undefined || Object.entries(jongkuk).length !== 0) {
    whichSeagod = true;
  } else {
    whichSeagod = false;
  }

  // 종격, 병약, 통관의 값이 있으면 -> 종격, 병약, 통관 중 하나 값 보여주면 됨.
  // 종격, 병약, 통관의 값이 없으면 -> 억부 보여주면 됨.
  return (
      <styled.Wrapper>
        {whichSeagod ?
            <>
              {seagod.병약_용신 &&
              <>
                <styled.Row>
                  병약 용신 - {seagod.병약_용신}
                </styled.Row>
                <styled.Row>
                  병약 희신 - {seagod.병약_희신}
                </styled.Row>
              </>
              }
              {seagod.통관_용신 &&
              <>
                <styled.Row>
                  통관 용신 - {seagod.통관_용신}
                </styled.Row>
              </>
              }
              {Object.entries(jongkuk).length !== 0 &&
              <>
                {jongkuk.name}
                <styled.Row>
                  용신: {jongkuk.five_element}
                </styled.Row>
                <styled.Row>
                  희신: {jongkuk.first}
                </styled.Row>
                <br/>
              </>
              }
            </>
            :
            <>
              <styled.Row>
                억부 용신: {seagod.억부_용신}
              </styled.Row>
              <styled.Row>
                억부 희신: {seagod.억부_희신}
              </styled.Row>
            </>
        }
      </styled.Wrapper>
  )
};
