import React from 'react';
import DaeunColumn from "../DaeunColumn";
import * as styled from './Style';


const ageLetterColor = (age: number, daeunAge: string) => {
  if (parseInt(daeunAge) < age && age < parseInt(daeunAge) + 10) {
    return 'rgb(252, 13, 27)';
  }
  return 'rgb(0, 0, 0)';
};

const DaeunResult = ({ daeun, age }: any) => {
  ageLetterColor(age, daeun);
  return (
    <styled.TableRowWrapper>
      {daeun.map((s: any, idx: number) => {
        return (
          <styled.TableCellWrapper key={s.age}>
            <styled.TableTitle daeunAgeColor={ageLetterColor(age, s.age)}>
              {s.age}
            </styled.TableTitle>
            <DaeunColumn gabja={s.gabja} index={idx} />
          </styled.TableCellWrapper>
        )
      })}
    </styled.TableRowWrapper>
  )
};

export default DaeunResult;
