import * as types from './types';
import {createReducer} from "typesafe-actions";

const initialState: types.AuthState = {
  isLogining: false,
  user: null
};

const AuthReducer = createReducer(initialState)
    .handleAction(types.T_LOGIN.PENDING, (state: types.AuthState, action: types.AuthAction) => {
      return {...state, isLogining: true, user: null};
    })
    .handleAction(types.T_LOGIN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
      const {payload}: any = action;
      const {access_token, refresh_token} = payload;

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      return {...state, isLogining: false, user: payload};
    })
    .handleAction(types.T_LOGIN.FAILURE, (state: types.AuthState, action: types.AuthAction) => {
      return {...state, isLogining: false, user: null};
    })
    .handleAction(types.T_LOGOUT.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
      return {...state, user: null};
    })
    .handleAction(types.T_REFRESH_TOKEN.SUCCESS, (state: types.AuthState, action: types.AuthAction) => {
      const {payload}: any = action;
      const {access_token, refresh_token} = payload;

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      return {...state, isLogining: false, user: payload};
    });

export default AuthReducer;
