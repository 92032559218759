import React from 'react';
import * as styled from './Style';
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {isLoginedSelector} from "../../modules/selectors";

export const Home = () => {
  const history = useHistory();
  const isLogined = useSelector(isLoginedSelector);

  return (
      <styled.Container>
        <styled.ContentWrapper backgroundColor={'#212121'}>
          <styled.IntroWrapper>
            <styled.LogoWrapper>
              <styled.ImgLogo/>
              <styled.LogoSubtitle>
                베타 서비스 스페셜 프로모션이 진행중입니다!<br/>
                지금 바로 가입하고 스타코인 20개를 선물로 받아보세요
              </styled.LogoSubtitle>
              <styled.BtnNavigateWrapper>
                <styled.BtnNavitgateToForm onClick={() => history.push('/birth')}>
                  {isLogined ? <>격국/용신 계산기</> : <>지금 바로 시작하기</>}
                </styled.BtnNavitgateToForm>
                <styled.BtnNavitgateToForm onClick={() => history.push('/guide_faq')}>
                  격국/용신 가이드 보러가기
                </styled.BtnNavitgateToForm>
              </styled.BtnNavigateWrapper>
              <styled.DownText>
                더 알아보기
              </styled.DownText>
              <styled.DownPointer/>
            </styled.LogoWrapper>
          </styled.IntroWrapper>
        </styled.ContentWrapper>
        <styled.ContentWrapperColumn>
          <styled.ContentContainerColumn>
            <styled.FlexWrapper style={{paddingBottom: '10px'}}>
              <styled.SubLogoImg/>
            </styled.FlexWrapper>
            <styled.FlexWrapper style={{paddingTop: '10px'}}>
              <styled.DescSubTitle color={"#242424"} style={{textAlign: 'center'}}>
                사주의 과학적, 수학적, 입체적 솔루션 플랫폼
              </styled.DescSubTitle>
            </styled.FlexWrapper>
          </styled.ContentContainerColumn>
        </styled.ContentWrapperColumn>
        <styled.ContentWrapper backgroundColor={"#242424"}>
          <styled.ContentContainer>
            <styled.FlexWrapper>
              <styled.ImgIntro1/>
            </styled.FlexWrapper>
            <styled.FlexWrapper>
              <styled.DescTitle>
                가장 어렵다는 격국 / 용신
                 그 해결에서부터 시작하는
                마스터 AI 솔루션
              </styled.DescTitle>
              <styled.DescSubTitle>
                격국용신을 단순히 통계적으로만 접근해오던
                기존 서비스들과 달리,
                수많은 고전과 현존하는 사주이론들을 통합한
                수학적/공학적 계산식으로
                518,400개의 케이스를 분석드립니다
              </styled.DescSubTitle>
              <styled.BtnNavitgateToForm onClick={() => history.push('/guide_faq')} style={{
                marginTop: '20px',
                border: '1px solid black',
                boxShadow: '0 0 6px 0 rgba(6, 0, 1, 0.2)'
              }}>
                격국/용신 가이드 보러가기
              </styled.BtnNavitgateToForm>
            </styled.FlexWrapper>
          </styled.ContentContainer>
        </styled.ContentWrapper>
        <styled.ContentWrapper>
          <styled.ContentContainer>
            <styled.FlexWrapper>
              <styled.ImgIntro2/>
            </styled.FlexWrapper>
            <styled.FlexWrapper>
              <styled.DescTitle color={"#242424"}>
                신강신약
                다중 X-RAY (MRI)
                분석 솔루션
              </styled.DescTitle>
              <styled.DescSubTitle color={"#242424"}>
                신강신약을 해석하는 6가지 방법이 존재한다는 것을 아십니까?
                밸런스오브파이브는 신강신약을 6가지 방향에서
                입체적으로 통합 분석하여 제공해드립니다
              </styled.DescSubTitle>
            </styled.FlexWrapper>
          </styled.ContentContainer>
        </styled.ContentWrapper>
        <styled.ContentWrapper backgroundColor={"#242424"}>
          <styled.ContentContainer>
            <styled.FlexWrapper>
              <styled.ImgIntro5/>
            </styled.FlexWrapper>
            <styled.FlexWrapper>
              <styled.DescTitle>
                폴킴 박사의 사주 명리학에서의 격국 / 용신 / 운명 관련 글 모음 활용해 보세요
              </styled.DescTitle>
              <styled.DescSubTitle>
                폴킴 박사는 MIT 공학 박사의 運(운) 이야기를 TTimes에 연재하셨던 분입니다.
              </styled.DescSubTitle>
              <styled.BtnNavitgateToForm
                  onClick={() => window.open("https://www.notion.so/Blog-by-Bo5stars-4f252774e29740d6a605a6d01b1b5ff4")}
                  style={{marginTop: '20px'}}>
                블로그 글 보러가기
              </styled.BtnNavitgateToForm>
            </styled.FlexWrapper>
          </styled.ContentContainer>
        </styled.ContentWrapper>
        <styled.VerticalLine/>
        <styled.ContentWrapperColumn>
          <styled.ContentContainerColumn>
            <styled.FlexWrapper style={{paddingBottom: '10px'}}>
              <styled.SubLogoImg/>
            </styled.FlexWrapper>
            <styled.FlexWrapper style={{paddingTop: '10px'}}>
              <styled.DescSubTitle color={"#242424"} style={{textAlign: 'center', paddingBottom: '20px'}}>
                모든 사람들이 손쉽게 이용할 수 있는 운명학 서비스를
                개발/운영하는 Technology Company입니다.
              </styled.DescSubTitle>
              <styled.DescSubTitle color={"#242424"} style={{textAlign: 'center'}}>
                태양계의 천체 운동을 기준으로 바뀌어 가는 기운 변화와
                개인적 운명 변화를 매칭하여 쉽게 활용할 수 있도록
                끊임없이 기술적 개발을 하고 있으며,
                고객의 새로운 니즈에 부합하여
                최고의 운명학 분석 서비스를 지속적으로 제공하겠습니다
              </styled.DescSubTitle>
            </styled.FlexWrapper>
            <styled.FlexWrapperRow>
              <styled.FlexWrapper style={{display: 'flex', paddingTop: '10px'}}>
                <styled.IconMain1/>
                <styled.DescIconSubTitle color={"#242424"}>
                  운명분석에 관한
                  종합적 정보 서비스
                </styled.DescIconSubTitle>
              </styled.FlexWrapper>
              <styled.VerticalLine/>
              <styled.FlexWrapper style={{display: 'flex', paddingTop: '10px'}}>
                <styled.IconMain2/>
                <styled.DescIconSubTitle color={"#242424"}>
                  운명에 관한
                  모든 종류의 기술적 분석
                </styled.DescIconSubTitle>
              </styled.FlexWrapper>
              <styled.VerticalLine/>
              <styled.FlexWrapper style={{display: 'flex', paddingTop: '10px'}}>
                <styled.IconMain3/>
                <styled.DescIconSubTitle color={"#242424"}>
                  Software,
                  Solution Platform 개발
                </styled.DescIconSubTitle>
              </styled.FlexWrapper>
            </styled.FlexWrapperRow>
          </styled.ContentContainerColumn>
        </styled.ContentWrapperColumn>
        {/*<styled.ContentWrapper backgroundColor={"#242424"}>*/}
        {/*  <styled.ContentContainer>*/}
        {/*    <styled.FlexWrapper>*/}
        {/*      <styled.ImgIntro4/>*/}
        {/*    </styled.FlexWrapper>*/}
        {/*    <styled.FlexWrapper>*/}
        {/*      <styled.DescTitle>*/}
        {/*         시간을 모를 때*/}
        {/*        정확한 정보를*/}
        {/*        제공하는 솔루션*/}
        {/*      </styled.DescTitle>*/}
        {/*      <styled.DescSubTitle>*/}
        {/*        일반 양력이 아니라 태어난 지역과 생시를 기반으로*/}
        {/*        지구 자전 위치를 정밀하게 보정하여*/}
        {/*        더욱 더 정확한 분석이 가능합니다.*/}
        {/*      </styled.DescSubTitle>*/}
        {/*    </styled.FlexWrapper>*/}
        {/*  </styled.ContentContainer>*/}
        {/*</styled.ContentWrapper>*/}
        {/*<styled.ContentWrapper backgroundColor={'#242424'}>*/}
        {/*  <styled.ContentContainer>*/}
        {/*    <styled.FlexWrapper>*/}
        {/*      <styled.ImgIntro3/>*/}
        {/*    </styled.FlexWrapper>*/}
        {/*    <styled.FlexWrapper>*/}
        {/*      <styled.DescTitle>*/}
        {/*        진태양시를 기준으로 하는*/}
        {/*        정밀한 분석 솔루션*/}
        {/*      </styled.DescTitle>*/}
        {/*      <styled.DescSubTitle>*/}
        {/*        일반 양력이 아니라 태어난 지역과 생시를 기반으로*/}
        {/*        지구 자전 위치를 정밀하게 보정하여*/}
        {/*        더욱 더 정확한 분석이 가능합니다.*/}
        {/*      </styled.DescSubTitle>*/}
        {/*    </styled.FlexWrapper>*/}
        {/*  </styled.ContentContainer>*/}
        {/*</styled.ContentWrapper>*/}
        {/*<styled.ContentWrapper>*/}
        {/*  <styled.ContentContainer>*/}
        {/*    <styled.FlexWrapper>*/}
        {/*      <styled.ImgIntro6/>*/}
        {/*    </styled.FlexWrapper>*/}
        {/*    <styled.FlexWrapper>*/}
        {/*      <styled.DescTitle color={'#242424'}>*/}
        {/*        STAR COIN 으로*/}
        {/*        자유로운 서비스를*/}
        {/*        즐겨보세요*/}
        {/*      </styled.DescTitle>*/}
        {/*      <styled.DescSubTitle color={'#242424'}>*/}
        {/*        스타코인은 더욱 폭넓은 서비스 사용을 가능하게 합니다.*/}
        {/*        스타코인은 가입시 무료로 획득하거나*/}
        {/*        추가로 구매하실 수 있습니다*/}
        {/*      </styled.DescSubTitle>*/}
        {/*    </styled.FlexWrapper>*/}
        {/*  </styled.ContentContainer>*/}
        {/*</styled.ContentWrapper>*/}
        {/*<hr/>*/}
      </styled.Container>
  )
};
