import React from 'react';
import * as styled from './Style';

interface IFateModalProps {
  show: boolean,
  onClose: () => void,
  title?: string,
  data?: any,
}

const FateModal = ({show, onClose, title, data}: IFateModalProps) => {
  if (!show) return null;

  return (
      <styled.Wrapper>
        <styled.Dimmed onClick={onClose}/>
        <styled.ModalWrapper>
          <styled.BtnClose onClick={onClose}>X</styled.BtnClose>
          <styled.Title>{title}</styled.Title>
          <styled.ContentWrapper>
            <styled.ContentList>
              {data.map((d: any) => {
                return (
                    <styled.ContentListItemWrapper>
                      <styled.ContentTitle>{d.title}</styled.ContentTitle>
                      {d.contents.map((content: string, idx: number) => {
                        return (
                            <styled.ContentListItem>{`${idx + 1}. ${content}`}</styled.ContentListItem>
                        )
                      })}
                    </styled.ContentListItemWrapper>
                )
              })}
            </styled.ContentList>
          </styled.ContentWrapper>
          <styled.BtnWrapper>
            <styled.BtnCheck onClick={onClose}>
              확인
            </styled.BtnCheck>
          </styled.BtnWrapper>
        </styled.ModalWrapper>
      </styled.Wrapper>
  );
};

export default FateModal;
