import React from 'react';
import * as styled from './Style';
import DaeunLetter from "../DaeunLetter";

interface IYearColumn {
  gabja: string;
  index: number;
}

const DaeunColumn = ({ gabja, index }: IYearColumn) => {
  const upperLetter = gabja[0];
  const downLetter = gabja[1];
  return (
    <styled.YearColumnWrapper>
      <DaeunLetter hanja={upperLetter} index={index}/>
      <DaeunLetter hanja={downLetter} index={index}/>
    </styled.YearColumnWrapper>
  )
};

export default DaeunColumn;
