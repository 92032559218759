import React, {useEffect, useState} from 'react';
import {getApiAxiosWithToken} from "../../utils/axiosUtil";
import {starCoinSelector} from "../../modules/selectors";
import {Modal, Spin, Table} from "antd";
import {API_URL} from "../../config";
import * as styled from "./Style";
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {decreaseCoin} from "../../modules/starcoin";

const STATUS: any = {
  'CREATED': '결제취소',
  'COMPLETED': '결제완료',
  'REFUND COMPLETED': '환불완료'
};
type TUser = {
  bonus_token_balance: string;
  created_at: string;
  email: string;
  name: string
  normal_token_balance: string;
  updated_at: string;
}
type TRefund = {
  success: boolean,
  msg: string,
  starCoin: number
}

export const MyPage = () => {
  const axiosWithToken = getApiAxiosWithToken();
  const [orderList, setOrderList] = useState([]);
  const [tab, setTab] = useState<number>(1);
  const starCoin = useSelector(starCoinSelector);
  const [user, setUser] = useState<TUser | null>(null);
  const [isRefunding, setIsRefunding] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getOrderHistory();
    _loadUserInfo();
  }, []);

  const columns = [
    {
      title: '주문번호',
      dataIndex: 'order_id',
      render: (text: string) => <styled.ListRowHover>{text}</styled.ListRowHover>,
    },
    {
      title: '주문일자',
      dataIndex: 'date_created',
      render: (text: string) => <styled.ListRowHover>{text.slice(0, 16)}</styled.ListRowHover>,
    },
    {
      title: '상태',
      dataIndex: 'status',
      render: (text: string) => <styled.ListRowHover>{STATUS[text]}</styled.ListRowHover>,
    },
    {
      title: '금액',
      dataIndex: 'total',
      render: (text: string) => <styled.ListRowHover>{text} $</styled.ListRowHover>,
    },
    // {
    //   title: '',
    //   dataIndex: 'id',
    //   render: (order_id: string, row: any, index: number) => {
    //     if (index === 0 && row.status) {
    //       return (
    //           <styled.ListRowHover
    //               onClick={(e: React.MouseEvent) => showDeleteModal(e, order_id)}>
    //             <text style={{color: 'red'}}>환불</text>
    //           </styled.ListRowHover>
    //       )
    //     }
    //     return null;
    //   },
    // }
  ];

  const showRefundResultModal = async (title: string, msg: string) => {
    Modal.confirm({
      title: title,
      content: (
          <div>
            <p>{msg}</p>
          </div>
      ),
      async onOk() {
      },
    });
  };

  const showDeleteModal = async (e: React.MouseEvent, order_id: string) => {
    e.stopPropagation();
    Modal.confirm({
      title: '환불 요청',
      content: (
          <div>
            <p>환불 하시겠습니까?</p>
          </div>
      ),
      async onOk() {
        setIsRefunding(true);
        handleRefund(order_id);
      },
      onCancel() {
      },
    });
  };
  const handleRefund = async (order_id: string) => {
    try {
      const resp = await axiosWithToken.post(API_URL + `/paypal/${order_id}/refund`);
      const {starCoin}: TRefund = resp.data;
      setIsRefunding(false);
      showRefundResultModal(
          "환불 완료", "환불 신청이 정상적으로 처리되었습니다. 2~3일 이내로 환불될 예정입니다."
      );
      dispatch(decreaseCoin(starCoin));
      await getOrderHistory();
    } catch (e) {
      if (e.response) {
        const {status} = e.response;
        if (status === 400) {
          setIsRefunding(false);
          showRefundResultModal("환불 완료 상품", "이미 환불이 된 상품입니다.");
        } else if (status === 406) {
          setIsRefunding(false);
          showRefundResultModal("환불 불가 상품", "환불할 수 없습니다.")
        }
        // else if (status === 400) setEmailError('이미 가입된 이메일입니다.');
      }
    }
  };

  const getOrderHistory = async () => {
    const orderHistory = await axiosWithToken.get(API_URL + `/orders`);
    orderHistory.data.result.slice().reverse().forEach((item: any, index: number, object: any) => {
      if (item.payer_id === "None") {
        orderHistory.data.result.splice(object.length - 1 - index, 1);
      }
    });
    setOrderList(Object.values(orderHistory.data.result));
  };

  const _loadUserInfo = async () => {
    const {data} = await axiosWithToken.get(`${API_URL}/users`);
    data.data && setUser(data.data);
  };


  return (
      <styled.Container>
        {isRefunding &&
        <styled.LoadingWrapper>
          <styled.LoadingDimmed/>
          <Spin/>
        </styled.LoadingWrapper>
        }
        <styled.TitleWrapper>
          {user?.name}
        </styled.TitleWrapper>
        <styled.TabsWrapper>
          <styled.TabItem isActive={tab === 0} onClick={() => setTab(0)}>
            결제내역
          </styled.TabItem>
          <styled.TabItem isActive={tab === 1} onClick={() => setTab(1)}>
            개인정보
          </styled.TabItem>
        </styled.TabsWrapper>
        <styled.TabHighLighterWrapper>
          <styled.TabHighLighter left={`${50 * tab}%`}/>
        </styled.TabHighLighterWrapper>
        {tab === 0 ? (
            <styled.ContentWrapper>
              <styled.listWrapper>
                <styled.tableWrapper>
                  {/*@ts-ignore*/}
                  <Table columns={columns}
                         dataSource={orderList}
                  />
                </styled.tableWrapper>
              </styled.listWrapper>
            </styled.ContentWrapper>
        ) : (
            <styled.ContentWrapper>
              <styled.ProfileName>{user?.name}</styled.ProfileName>
              <styled.ContentRowWrapper>
                <styled.ContentRowTitle>STARCOIN 개수</styled.ContentRowTitle>
                <styled.ContentRowContent>
                  <styled.StarCoinWrapper>
                    <styled.IconStarCoin/>
                    <styled.TextStarCoin>
                      {starCoin.starCoin}
                    </styled.TextStarCoin>
                  </styled.StarCoinWrapper>
                </styled.ContentRowContent>
              </styled.ContentRowWrapper>
              <styled.ContentRowWrapper>
                <styled.ContentRowTitle>이메일</styled.ContentRowTitle>
                <styled.ContentRowContent>{user?.email}</styled.ContentRowContent>
              </styled.ContentRowWrapper>
              <styled.ContentRowWrapper>
                <styled.ContentRowTitle>가입한 날짜</styled.ContentRowTitle>
                <styled.ContentRowContent>{moment(user?.created_at).format('YYYY-MM-DD')}</styled.ContentRowContent>
              </styled.ContentRowWrapper>
              {/*<styled.BtnEdit>수정</styled.BtnEdit>*/}
            </styled.ContentWrapper>
        )}
      </styled.Container>
  )
};
