import React, {useState} from 'react';
import {Form, InputNumber, Modal, Radio, Skeleton} from 'antd';
import {useHistory} from "react-router";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {getLatLngFromAddr} from "../../utils/locationHelper";
import * as styled from "./Styles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isLoginedSelector} from "../../modules/selectors";
import "react-datetime/css/react-datetime.css";
import {Loading} from "../Loading";
import "./index.css";
import {GOOGLE_PLACE_API_KEY} from "../../config";
import {getSajuInfo} from "../../core/api/birth";
import {decreaseCoin} from "../../modules/starcoin";
import {birthPreproccessing} from "../../utils/birthTableHelper";

const todayDate = new Date();

const re = /^[0-9\b]+$/;  // Only number
const solar_or_lunar: { [key: number]: string } = {
  1: '양력',
  2: '음력(평달)',
  3: '음력(윤달)'
};

const Birth = () => {
  const [personalInfo, setPersonalInfo] = useState({
    user: {
      name: '무명',
      birth: '',
      gender: 'man',
      lunar: '양력',
      lat: 37.549,
      lng: 126.988,
      knowTime: true,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [birth, setBirth] = useState<number[]>([
    -1, -1, -1, -1, -1
  ]);
  const [birthError, setBirthErrors] = useState<boolean[]>([false, false, false, false, false]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isLogined = useSelector(isLoginedSelector);

  const checkBirthDateError = (e: React.MouseEvent) => {
    let result = false;
    const newBirthError = [...birthError];
    birth.map((b: number, idx: number) => {
      if (b === -1 || b === undefined) {
        result = true;
        newBirthError[idx] = true;
      }
    });
    setBirthErrors(newBirthError);
    if (result) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsModalVisible(true);
    return result;
  };

  const onFinish = async () => {
    const birthList = [...birth];
    const birthYMD = birthList.slice(0, 3);
    let resultBirth = '';
    if (personalInfo.user.knowTime) {
      const birthHM = birthList.slice(3, 5);
      resultBirth = birthYMD.join('-') + ' ' + birthHM.join(':');
    } else {
      resultBirth = birthYMD.join('-');
    }
    const _user = { ...personalInfo.user };
    _user.birth = resultBirth;

    setIsLoading(true);
    const success = (id: string) => {
      setIsLoading(false);
      history.push({
        pathname: '/dragon/' + id,
        state: { knowTime: personalInfo.user.knowTime }
      }
      );
      const usedCoin = personalInfo.user.knowTime ? 1 : 3;
      dispatch(decreaseCoin(usedCoin));
    };

    const starcoinCheck = (knowTime: boolean) => {
      let starcoinNoti = '';
      if (knowTime) {
        starcoinNoti = '스타코인 1개 사용하셨습니다.';
      } else {
        starcoinNoti = '스타코인 3개를 사용하셨습니다.';
      }
      Modal.info({
        title: '이용권 사용 내역',
        content: (
          <div>
            <p>{starcoinNoti}</p>
          </div>
        ),
        onOk() {
        },
      });
    };

    const info = () => {
      setIsLoading(false);
      Modal.info({
        title: '스타코인이 부족합니다.',
        content: (
          <div>
            <p>스타코인을 구매해주세요.</p>
          </div>
        ),
        onOk() {
          // history.push('/shop')
        },
      });
    };
    await getSajuInfo(_user, success, info, starcoinCheck);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    onFinish();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const onFinishFailed = (errorInfo: object) => {
    console.log('Failed:', errorInfo);
  };

  const handlerGender = (e: any) => {
    const _user = { ...personalInfo.user };
    if (e.target.value) {
      _user.gender = 'man';
    } else {
      _user.gender = 'woman';
    }
    setPersonalInfo({ user: _user });
  };

  const handlerLunar = (e: any) => {
    const _user = { ...personalInfo.user };
    _user.lunar = solar_or_lunar[e.target.value];
    setPersonalInfo({ user: _user });
  };

  const handlerTime = (e: any) => {
    if (e.target.value === false) {
      const _birth = [
        birth[0], birth[1], birth[2]
      ];
      setBirth(_birth);
    } else {
      const _birth = [
        birth[0], birth[1], birth[2], birth[3], birth[4]
      ];
      setBirth(_birth);
    }
    const _user = { ...personalInfo.user };
    _user.knowTime = e.target.value;
    setPersonalInfo({ user: _user });
  };

  const handlerName = async (e: any) => {
    const _user = { ...personalInfo.user };
    _user.name = e.target.value;
    setPersonalInfo({ user: _user });
  };

  const handlerAddress = async (e: any) => {
    const currentAddr: any = await getLatLngFromAddr(e.label);
    const _user = { ...personalInfo.user };
    _user.lat = currentAddr.lat;
    _user.lng = currentAddr.lng;
    setPersonalInfo({ user: _user });
  };

  const handlerBirthDate = (e: any, idx: number) => {
    if (e === '' || re.test(e)) {
      const _birth = [...birth];
      const _birthErrors = [...birthError];
      _birthErrors[idx] = false;
      _birth[idx] = Number(e);
      setBirthErrors(_birthErrors);
      setBirth(_birth);
    }
  };

  if (isLoading) return <Loading />;

  return (
    isLogined ?
      <styled.ContentWrapper>
        <styled.BirthIntroImg>
          <styled.BirthIntroWrapper>
            <styled.BirthIntroTitle>
              격국 용신에 관한<br />
              모든 정보를 정리해드립니다.<br/>
              생년월일시를<br/>
              입력해 주세요.
            </styled.BirthIntroTitle>
            <styled.BirthIntroSubTitle>
              격국 용신에 관한 현대적 AI 완성판!
            </styled.BirthIntroSubTitle>
          </styled.BirthIntroWrapper>
        </styled.BirthIntroImg>
        <styled.BirthFormWrapper>
          <Form
            name={"basic"}
              // onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <styled.InputLabel>{t("birthForm.gender")}</styled.InputLabel>
            <styled.InputWrapper>
              <styled.InputContent>
                <Radio.Group name={"gender"} onChange={handlerGender} defaultValue={true}>
                  <Radio value={true}>
                    <styled.BirthGender isActive={personalInfo.user.gender === 'man'}>
                      {t("birthForm.male")}
                    </styled.BirthGender>
                  </Radio>
                  <Radio value={false}>
                    <styled.BirthGender isActive={personalInfo.user.gender !== 'man'}>
                      {t("birthForm.female")}
                    </styled.BirthGender>
                  </Radio>
                </Radio.Group>
              </styled.InputContent>
            </styled.InputWrapper>

            <styled.InputLabel>{t("birthForm.name")}</styled.InputLabel>
            <styled.InputWrapper>
              <styled.InputContent>
                <styled.InputName onChange={handlerName} placeholder={"이름을 입력해주세요"} />
              </styled.InputContent>
            </styled.InputWrapper>

            <styled.InputLabel>{t("birthForm.knowTime")}</styled.InputLabel>
            <styled.InputWrapper>
              <styled.InputContent>
                <Radio.Group name={"know-time"} onChange={handlerTime}>
                  <Radio value={true}>{t("birthForm.yes")}</Radio>
                  <Radio value={false} disabled={false}>{t("birthForm.no")}</Radio>
                </Radio.Group>
              </styled.InputContent>
            </styled.InputWrapper>
            <styled.NoTimeExplanation knowTime={personalInfo.user.knowTime}>
              {t("birthForm.noTimeExplanation")}
            </styled.NoTimeExplanation>

            <styled.InputLabel>{t("birthForm.birthday")}</styled.InputLabel>
            <styled.InputBirthWrapper>
              <styled.InputBirth>
                <styled.InputBirthContainer>
                  <InputNumber
                    style={{
                      width: '50px',
                      minWidth: '50px',
                      borderRadius: '10px',
                      border: `solid 2px ${birthError[0] ? 'red' : '#484848'}`,
                      marginRight: '8px',
                    }}
                    placeholder={todayDate.getFullYear().toString()}
                    maxLength={4} onChange={(e: any) => handlerBirthDate(e, 0)} min={0} max={3000}
                  />년
                    </styled.InputBirthContainer>
                <styled.InputBirthContainer>
                  <InputNumber
                    maxLength={2} onChange={(e: any) => handlerBirthDate(e, 1)} min={1} max={12}
                    placeholder={(todayDate.getMonth() + 1).toString()}
                    style={{
                      width: '50px',
                      minWidth: '50px',
                      borderRadius: '10px',
                      border: `solid 2px ${birthError[1] ? 'red' : '#484848'}`,
                      marginRight: '8px'
                    }}
                  />
                      월
                    </styled.InputBirthContainer>
                <styled.InputBirthContainer>
                  <InputNumber
                    maxLength={2} min={1} max={31} onChange={(e: any) => handlerBirthDate(e, 2)}
                    placeholder={todayDate.getDate().toString()}
                    style={{
                      width: '50px',
                      minWidth: '50px',
                      borderRadius: '10px',
                      border: `solid 2px ${birthError[2] ? 'red' : '#484848'}`,
                      marginRight: '8px'
                    }}
                  />일
                    </styled.InputBirthContainer>
                {personalInfo.user.knowTime &&
                  <>
                    <styled.InputBirthContainer>
                      <InputNumber
                        maxLength={2} min={0} max={23} onChange={(e: any) => handlerBirthDate(e, 3)}
                        placeholder={todayDate.getHours().toString()}
                        style={{
                          width: '50px',
                          borderRadius: '10px',
                          border: `solid 2px ${birthError[3] ? 'red' : '#484848'}`,
                          marginRight: '8px'
                        }}
                      />
                        시
                      </styled.InputBirthContainer>
                    <styled.InputBirthContainer>
                      <InputNumber
                        maxLength={2} onChange={(e: any) => handlerBirthDate(e, 4)} min={0} max={59}
                        placeholder={todayDate.getMinutes().toString()}
                        style={{
                          width: '50px',
                          minWidth: '50px',
                          borderRadius: '10px',
                          border: `solid 2px ${birthError[4] ? 'red' : '#484848'}`,
                          marginRight: '8px'
                        }}
                      />분
                      </styled.InputBirthContainer>
                  </>
                }
              </styled.InputBirth>
            </styled.InputBirthWrapper>

            <styled.InputLabel>{t("birthForm.solar or lunar")}</styled.InputLabel>
            <styled.InputWrapper>
              <styled.InputContent>
                <Radio.Group name={"lunar"} onChange={handlerLunar} defaultValue={1}>
                  <Radio value={1}>
                    <styled.BirthLunar isActive={personalInfo.user.lunar === '양력'}>
                      {t("birthForm.solar")}
                    </styled.BirthLunar>
                  </Radio>
                  <Radio value={2}>
                    <styled.BirthLunar isActive={personalInfo.user.lunar === '음력(평달)'}>
                      {t("birthForm.lunar")}
                    </styled.BirthLunar>
                  </Radio>
                  <Radio value={3}>
                    <styled.BirthLunar isActive={personalInfo.user.lunar === '음력(윤달)'}>
                      {t("birthForm.lunar(leap month)")}
                    </styled.BirthLunar>
                  </Radio>
                </Radio.Group>
              </styled.InputContent>
            </styled.InputWrapper>

            <styled.InputLabel>{t("birthForm.location")}</styled.InputLabel>
            <styled.InputWrapper>
              <styled.InputContent>
                <GooglePlacesAutocomplete
                  apiKey={GOOGLE_PLACE_API_KEY}
                  selectProps={{
                    onChange: handlerAddress,
                    placeholder: '대한민국 서울특별시',
                  }}
                />
              </styled.InputContent>
            </styled.InputWrapper>

            <Form.Item style={{ textAlign: 'center' }}>
              <styled.BirthBtn onClick={checkBirthDateError}>
                <styled.BirthBtnText>
                  {t("birthForm.submit")}
                </styled.BirthBtnText>
              </styled.BirthBtn>
            </Form.Item>
          </Form>

          <Modal title="정확한 정보를 입력했는지 확인 바랍니다." visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <p><strong>이름</strong>: {personalInfo.user.name}</p>
            <p><strong>성별</strong>: {personalInfo.user.gender === 'man' ? '남' : '녀'}</p>
            <p><strong>음/양력 선택</strong>: {personalInfo.user.lunar}</p>
            <p><strong>생년월일</strong>: {birthPreproccessing(birth, personalInfo.user.knowTime)}</p>
          </Modal>

        </styled.BirthFormWrapper>
      </styled.ContentWrapper>
      :
      <Skeleton>
        {t("isLogin.no")}
        {history.push('/login')}
      </Skeleton>
  );
};

export default Birth;
