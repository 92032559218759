import {TFAQ} from "src/containers/GuideFaq";

// 이용안내
const faq0: TFAQ[] = [
  {
    question: '문의 혹은 요청을 하고 싶어요.',
    answer: ``
  },
  {
    question: '사주 풀이도 해 주시나요?',
    answer: `Bo5stars는 모든 사람들이 손쉽게 이용할 수 있는 운명학 서비스를 개발/운영하는 Technology Company로서, 사주 풀이는 없습니다.`
  },
  {
    question: '스타코인이 부족합니다.',
    answer: `Promotion 기간 중에는 자신이 사용하고 있는 이메일과 함께\ninfo.bo5stars@gmail.com으로 스타코인을 부탁하시면\n20개씩 드리겠습니다.`
  },
  {
    question: '비밀번호를 잊어버렸습니다.',
    answer: `
로그인 페이지에 패스워드 입력 창 왼쪽 아래에 비밀번호를 잊으셨나요? 를\n클릭하시면 이메일 인증 화면이 나옵니다.\n아래 절차대로 차근히 진행 하시기 바랍니다.
  
  1.	가입시 이용했던 이메일을 입력
  2.	인증코드 발송 후 인증코드 입력 및 확인
  3.	새로 사용할 패스워드 입력
  4.	새 비밀번호로 다시 로그인
`
  },
  {
    question: '아이디 변경을 하고 싶어요.',
    answer: `등록된 아이디는 변경이 불가하며 1인 1아이디를 권장합니다.\n또한 이메일을 통해 회원가입을 하고 있으므로 중복된 이메일로 회원가입이 불가능합니다.`
  },
  {
    question: '환불은 어떻게 하나요?',
    answer: `환불은 마이페이지에 들어가 결제내역에 들어가시면 환불하실 수 있습니다.
마이페이지는 웹의 경우, 스타코인 옆에 있는 프로필을 누르면 마이페이지로 갈 수 있습니다.\n모바일의 경우, 오른쪽 위에 있는 아이콘을 눌러 마이페이지를 누르시면 가실 수 있습니다. 
    `
  },
  {
    question: '환불 정책이 어떻게 되나요?',
    answer: `환불은 가장 마지막에 결제한 내역(최근에 결제한 내역)만 환불이 가능하며\n최근에 결제하여 받은 스타코인 보다 현재 가지고 있는 스타코인의 수가 크다면 환불이 가능합니다.`
  },
];


// 격국용신
const faq1: TFAQ[] = [
  {
    question: '당령(當令)은 무엇인가요?',
    answer: `12개월 지구의 공전 운동에 의하여 지지의 순환이 이루어지고 자연 지장간의 순환도 이루어지는데 지장간의 배속 기간도 별도로 정하여 집니다.\n 
실제 예를 본다면 인월의 경우 지장간의 순환과 일짜별 배속이 무(戊) 7일, 병(丙) 7일, 갑(甲) 16일 순으로 이루어집니다. 이 때 절기 후 10일에 해당한다면 그 팔자는 월지에서 병(丙)이 당령한 것이라 표현합니다.\n 
상기의 경우 격국을 채택할 때 이 병(丙)이 천간에 투출하면 기운적으로 그 개성이 강하게 드러나는데 격의 성립에서 최상위 형태가 조성될 수 있습니다.\n
상기의 경우 천간의 구성에서 갑(甲)이 드러나 형태상 갑(甲)의 기운을 중심으로 우선 격이 성립이 이룩되더라도 병(丙) 당령의 기운이 내부적으로 지속적인 작용을 일으키게 된다.`
  },
  {
    question: '사령(司令)은 무엇인가요?',
    answer: `용어의 차이는 있지만 앞의 당령(當令)과 같은 의미로 해석해도 되겠습니다.`
  },
  {
    question: '득령(得令)은 무엇인가요?',
    answer: `득령(得令)은 일간이 월지에서 육친상 인성(印星)이나 비겁(比劫)을 얻은 경우를 주로 말하는데 월령에서 오행적 힘을 잘 얻고 있는 경우를 말합니다.\n
신강, 신약을 나눌 때 월지로부터 득령(得令) 여부를 많이 따지게 되는데 그 힘이 상대적으로 크다고 보기 때문입니다.\n`
  },
  {
    question: '신강(身强)은 무엇인가요?',
    answer:
        `사주가 신강하다는 것은 즉, 일간이 오행상 상대적으로 강하다는 것을 의미합니다.\n
자신의 삶을 주체적으로 이끌어 가려는 기운이 더 강하다는 의미로 풀이합니다.\n
삶에 대한 결정이든, 성공을 위한 방식이든 거의 모두 주체적인 결정을 우선하고 그 책임도 본인이 더 주체적으로 지는 기질이 따릅니다.\n
자기 주관을 더 앞세우는 측면으로 주위와 불화를 자주 만들기도 하지만 운의 흐름이 원만할 때에는 큰 성공을 거둘 수 있는 힘을 가지고 있는 기운이 따른다고 해석합니다.\n`
  },
  {
    question: '신약(身弱)은 무엇인가요?',
    answer: `사주가 신약하다는 것은 즉, 일간이 오행상 상대적으로 약하다는 것을 의미합니다.\n
신약하면 주변의 흐름에 따라가는 타율성이 더 강한 것으로 해석합니다.\n
비록 주체적이지 못하고 주도력이 약한 단점이 따르지만 주변의 상황에 나름 대처하면서 생존의 방식이나 성공의 기회를 획득하는 장점도 가집니다.\n
신약을 길흉론 측면에서 나쁘다고 해석하기보다 삶의 방식이 그러한 것으로 이해하시면 되겠습니다.\n`
  },
  {
    question: '신강신약은 어떻게 구해졌나요?',
    answer:
        `본 서비스는 518,400개의 사주에 관한 신강 신약을 계산하여, 상대적인 강약 여부 값을 비교하여 정리하였습니다.\n
간지, 음양, 오행, 육친 등 6가지 신강신약 계산 요소를 다면적으로 적용하였고,  신강, 신약의 채택은 사주의 기본 가짓수 518,400 케이스를 기준으로 분포표를 사용하여 강약 여부를 채택, 제시하였습니다.\n 
구체적인 내용은 아래 글을 참조하시기 바랍니다.\n
https://www.notion.so/bo5stars/IIIb75d910dce1349c9ab0d159f8aace659`
  },
  {
    question: '신강신약의 강약순서는 어떻게 분류되나요?',
    answer:
        `신강신약의 강약 순서는\n
태약 < 신약 < 신약_평 < 신강_평 < 신강 < 태강 \n
의 6 단계로 분류되어 있습니다.`
  },
  {
    question: '격국은 무엇인가요?',
    answer: `격국이란 자동차에 비유한다면 그 팔자의 기본 프레임 내지는 동력 전달 장치에 비유할 수 있습니다. 자동차에서 기본 프레임 내지는 동력 전달 장치를 알면 그 차의 속성을 잘 파악할 수 있는 것처럼 어떤 팔자의 개성도 쉽게 파악할 수 있습니다.\n
직업적 개성, 인생관, 목표 달성의 방식, 바꾸기 어려운 가치관 등을 보여주므로 한 개인의 운명을 해석하는데 매우 중요한 틀이 됩니다.\n
운명의 모든 특성을 나누는 기준이 아니지만 격국을 버리고 그 팔자의 특성을 논하기 어려울 만큼 중요한 인자가 됩니다.\n
사주명리를 공부할 때 필수적으로 학습하는 이유는 상기의 큰 특성과 이유 때문입니다.`
  },
  {
    question: '용신은 무엇인가요?',
    answer: `격국이 승용차, 화물차, 포크레인 등 그 기본적인 개성을 나누는 것과 같다면 용신은 그 차가 잘 달릴 수 있는 길, 분야 등에 비유할 수 있습니다.\n
즉, 자신이 타고난 기운을 원활하게 발휘할 수 있는 운명적 계절을 찾아서 정하는 것이 용신의 채택 방법이 됩니다. 역동적인 활동과 사회적인 성과를 낼 수 있는 시기를 오행적으로 찾아서 정하는 것이 용신 개념으로 아시면 되겠습니다.\n
운의 길흉을 따질 때 용신이나 용신을 돕는 운을 길하다고 하고 용신을 억제하거나 약하게 하는 운을 흉하다고 봅니다. 상대적으로 용신을 억제하거나 약하게 하는 오행을 기신(忌神)이라고 합니다.\n
좀더 세분한다면 용신, 용신을 도와주는 희신(喜神), 기신(忌神), 기신을 도와주는 구신(仇神), 기운에 크게 관여하지 않는 한신(閑神)으로 나눌 수 있습니다.`
  },
  {
    question: '성격(成格)은 무엇인가요?',
    answer: `월지의 지장간을 기준으로 투출이 된 인자가 있으면 그 인자를 기준으로 십신(十神)을 따져서 그 팔자의 기본적인 특성을 부여하는 것을 격의 성립이라고 합니다.\n
십신 중에서 비견, 겁재는 투출되더라도 별도의 명칭을 붙이지 않습니다.\n
만약에 둘 이상의 지장간이 동시에 출현한 경우 이를 이중(二重)격이라고 합니다.\n
동일 지장간이 둘 이상 중복으로 드러난 경우는 이중격으로 명명하지 않습니다.\n
자(子), 묘(卯), 유(酉) 월의 경우는 월지 지장간이 투출되지 않더라도 그 월지의 육친의 명칭을 따라서 그대로 격을 취하여 성격(成格)이라고 합니다.`
  },
  {
    question: '파격(破格)은 무엇인가요?',
    answer: `격이 성립이 형태상 이루어진다고 하더라도 격이 일정 부분 훼손되는 경우가 발생하는데 이렇게 격이 손상된 형태를 파격(破格)이라고 합니다.\n
합탁(合濁)은 투출된 천간을 합(合)하는 인자가 있어서 그 천간이 가지는 고유의 기능이 퇴색(退色)된 것을 말합니다. 합탁(合濁)은 격이 훼손된 정도가 덜하지만 격을 일정 정도 훼손한 경우라 일종월지가 다른 지지의 간섭이 없이 안정된 경우에 격의 성립이 더 충실하여지는데 형(刑), 충(冲), 파(破), 해(害), 원진(元辰)에 의하여 훼손된 경우 이를 파격으로 분류합니다.\n
파격을 만드는 인자 중에 강하게 파격 작용을 만드는 인자로 공망(空亡)의 작용을 크게 해석합니다.\n
훼손의 정도에 따라 파격의 정도도 나눌 수 있는데 그 사람의 직업적 개성이나 삶의 패턴에 많은 격 차이와 영향을 주니 사주명리학 연구에 꼭 기준을 삼을 필요가 있습니다.`
  },
  {
    question: '조후는 무엇인가요?',
    answer: `
사주명리학에서는 조후를 추위와 더위, 차가움과 따뜻함을 오행이나 계절, 하루의 밤낮 등으로 나누는 것을 의미합니다.\n
주로 어떤 사람이 태어난 달과 시간의 상태에 따라서 한난(寒暖), 조습(燥濕)을 따집니다.\n
지나치게 덥거나 차가운 기운이 몰려 있을 때에는 역동성이 매우 떨어지므로 부득이 조절이 필요한 상태가 됩니다. 이 때 실조(失調)한 상태라고 부릅니다.\n
주로 태어난 달을 기준으로 자(子)월, 축(丑)월, 오(午)월, 미(未)월의 경우에 다른 지지 구성에 따라 실조가 많이 발생합니다.`
  },
  {
    question: '조후는 어떻게 적용, 활용하나요?',
    answer: `지나치게 덥거나 차가운 기운이 몰려 있을 때에는 오행의 편중(偏重)을 따지기 보다 조후 balancing을 하여 주는 인자가 좋은 작용을 많이 이루어 준다고 봅니다.\n
형태상 격국의 성립도 하나의 개성으로 채택하지만 실조의 경우에는 조후 balancing을 하여 주는 인자를 우선하여 좋은 인자로, 용신(用神) 개념으로 적용합니다.\n
이때 채택하는 것이 조후 용신인데 다른 용신보다 우선하는 것으로 해석하게 됩니다.`
  },
  {
    question: '조후도는  어떤 기준으로 구해졌나요? ',
    answer:
        `조후는 생명력과 번영을 위한 최우선의 조건으로 추위와 더위를 의미합니다.\n
본 서비스는 사주의 기본 가짓수 518,400 케이스를 기준으로 조후의 정도를 상대적으로 파악, 분포 방식으로 조후도의 정도를 제시하였습니다.
        
조후도의 강약은\n
추움 < 차가움 < 서늘함 < 따뜻함 < 더움 < 뜨거움\n
의 6단계로 분류되어 있습니다.`
  },
];


// 명리일반
const faq2: TFAQ[] = [
  {
    question: '음양은 무엇인가요?',
    answer:
        `자연의 운동을 크게 두 단위로 나눈 것입니다.\n
 살아 움직이는 것은 오르거나 내리거나, 펼쳐지거나 오므라들거나, 액티브하게 움직이거나 활동이 둔화되거나 등 어떤 형태나 방향이든 움직임이 생기는데 벌어지고, 오르고, 액티브하고 움직이면 양(陽) 또는 양운동이라고 하고, 오므라들고, 내리고, 둔화된 모양으로 움직이면 음(陰) 또는 음운동이라고 합니다.\n
 음과 양은 절대 고정이 아니고 상대적인 개념입니다.\n
 그런 운동이 사물이나 현상에 일어난 것을 여러 가지 예로 파악할 수 있습니다.`
  },  // Table_1 삽입
  {
    question: '오행은 무엇인가요?',
    answer:
        `음양에서 설명하였듯이 자연의 운동을 크게 두 단위로 나눈 것은 음양이라고 하였는데 자연의 운동을 더 세분하여 다섯 단위로 나눈 것이 오행입니다.\n
목(木), 화(火), 토(土), 금(金), 수(水)가 그것입니다.\n
봄에 만물이 소생하듯이 모양을 드러내고 길어나는 운동을 목(木), 여름에 백화(白花)가 만발하고 빠른 성장을 이루는 운동을 화(火), 늦여름에 더 이상 성장을 하지 않고 모든 기운을 펼치고 유지하고 있는 운동을 토(土), 가을에 열매를 맺고 낙엽이 말라 떨어지듯이 수렴하는 운동을 금(金), 겨울처럼 모든 생명들이 기운을 안으로 감추어 숨기고 있는 운동을 수(水)라고 합니다.\n
만물은 목(木), 화(火), 토(土), 금(金), 수(水) 오행의 기운과 호응되어 있는데 비록 그 성질이나 운동 성향이 섞여 있을지라도 어느 성질이나 운동 성향이 더 강화된 속성을 가집니다.\n
오행이라는 것은 절대적인 개념이 아니라 상대적 개념이 더 우선합니다.`
  },
  {
    question: '오행의 상생(相生)은 무엇인가요?',
    answer:
        `오행 편에서 설명하였듯이(오행 참조) 세상 만물은 어떤 운동 성향과 성질을 가지고 있는데 이것들 사이에 관계가 발생하면 서로 운동을 돕기도 하고 억제하기도 합니다. 이들 운동 중에 서로 돕는 작용이 발생하는 경우, 이를 상생(相生)이라고 합니다.\n
목(木)은 화(火)를 생하고, 화(火)는 토(土)를 생하고, 토(土)는 금(金)을 생하고, 금(金)은 수(水)를 생하고, 수(水)는 목(木)을 생합니다.\n
상생(相生)이라는 뜻은 서로 돕는다는 의미를 가지고 있어서 보편적인 생(生)의 방향 외에 서로 돕고 보완하는 작용이 따릅니다.`
  },
  {
    question: '오행의 상극(相克)은 무엇인가요?',
    answer: `오행 편에서 설명하였듯이(오행 참조) 세상 만물은 어떤 운동 성향과 성질을 가지고 있는데 이것들 사이에 관계가 발생하면 서로 운동을 돕기도 하고 억제하기도 합니다. 이들 운동 중에 서로 억제하는 작용이 발생하는 경우, 이를 상극(相克)이라고 합니다.\n
목(木)은 를 극(克)하고, 토(土)는 수(水)를 극(克)하고, 수(水)는 화(火)를 극(克)하고, 화(火)는 금(金)을 극(克)하고, 금(金)은 목(木)을 극(克)합니다.\n
상극이라는 것은 고유의 운동을 억제하는 작용을 일으켜서 상대 오행의 고유 작용을 막는 효과가 있습니다.\n
상극은 그 자체로 길흉을 뜻하는 것이 아니고 어떤 오행의 작용을 억제하여 지나치지 않게 하는 작용을 합니다.\n
운명학에서 해석할 때에는 서로 억제, controlling하는 작용을 합니다. 잘 억제할 때 생명 활동이 활발하여지는 작용을 가지게 됩니다.`
  },
  {
    question: '간지는 무엇인가요?',
    answer: `간지는 천간(天干)과 지지(地支)를 줄여서 일컫는 말입니다.\n
천간(天干)은 갑(甲), 을(乙), 병(丙), 정(丁), 무(戊), 기(己), 경(庚), 신(辛), 임(壬), 계(癸)를 말합니다. 하늘의 오운(五運), 오행(五行)에 음양 기준을 더하여 배속한 것입니다.\n
지지(地支)는 자(子), 축(丑), 인(寅), 묘(卯), 진(辰), 사(巳), 오(午), 미(未), 신(申), 유(酉), 술(戌), 해(亥)를 말합니다. 땅의 육기(六氣)가 기운의 방향에 따라 12개로 펼쳐진 것을 뜻합니다.\n
천간과 지지의 조합을 갑(甲)과 자(子), 을(乙)과 축(丑), 병(丙)과 인(寅) 식으로 조합한 것이 간지 조합의 구조입니다. 10천간과 12지지를 조합하는데 10과 12의 최소공배수인 60간지가 하나의 circle이 됩니다.`
  },
  {
    question: '간지는 어떻게 적용, 활용하나요?',
    answer: `천간은 주로 별과 별의 각도 개념으로 정리할 수 있고 지지는 땅에 펼쳐지는 실제 계절과 기운 개념으로 정리할 수 있습니다.\n
천간은 주로 정신적인 면, 지지는 주로 육체와 현실적인 면을 파악할 수 있는 인자가 됩니다.\n
천간은 주로 추구성, 지향성, 개성의 대표성 등을 보여주고, 지지는 주로 실제적인 힘, 계절, 환경 면을 보여주는 인자가 됩니다.\n
한 개인의 운명을 사주에서 해석할 때 상기의 기준으로 해석합니다.\n
격국 용신 이론에서 격의 채택도 상기의 기준을 배경으로 우선 적용합니다.`
  },
  {
    question: '육친(六親)은 무엇인가요?',
    answer: `육친은 오행의 생극제화 현상을 간지 사이의 작용에 적용하여 해석한 것입니다. 육친의 육(六)은 여섯 개의 관계를 의미하는데 그 관계를 정리하여 관계의 명칭으로 나누면 십신(十神)으로 분류됩니다.\n
십신(육친)의 성립에 관해서 정리하여 보겠습니다. 어떤 기운(예를 들어서 일간의 속성)이 목(木)이라고 한다면 목(木)을 만났을 때 비겁(比劫), 화(火)를 만났을 때 식상(食傷), 토(土)를 만났을 때 재성(財星), 금(金)을 만났을 때 관성(官星), 수(水)를 만났을 때 인성(印星)이라고 합니다.\n
원리는 아래와 같습니다. 자신의 기운과 오행이 같으면 비겁(比劫), 자신이 생(生)하는 오행을 만났을 때 식상(食傷), 자신이 극(克)하는 오행을 만났을 때 재성(財星), 자신을 극(克)하는 오행을 만났을 때 관성(官星), 자신을 생하여주는 오행을 만났을 때 인성(印星)이라고 합니다.\n
비겁(比劫), 식상(食傷), 재성(財星), 관성(官星), 인성(印星) 그리고 자신 이렇게 여섯 개의 관계 명칭을 모아서 여섯 육(六), 육친이라고 명칭합니다.\n
간지 사이에 육친의 형성을 명칭으로 표를 작성하면 아래와 같습니다(천간 대 지지, 가로기준).`
  },
  {
    question: '육친은 어떻게 적용, 활용하나요?',
    answer: `사주의 명식에 간지가 구성되면 상기처럼 육친으로 관계가 다양하게 만들어집니다.\n
이런 간지의 조합을 기준으로 그 사람의 기질, 행동 패턴, 직업적 개성 등이 분류됩니다.\n
월지와 월지에서 투출되는 인자(지장간의 투출, 지장간 참조)를 기준으로 한 팔자의 큰 개성을 나누기도 합니다. 이를 격의 성립이라고 합니다.\n
육친 끼리 상호작용을 일으켜서 많은 조합의 운명적 특징을 만들어 냅니다. 재물을 상징하는 편재가 있는데 재물을 약탈하는 겁재가 다시 드러나면 늘 경쟁이 많은 사업성을 발휘하게 된다고 해석합니다.\n
사주 명리학에서 해석의 기준이나 틀로 삼는 중요한 기준이라 매우 중요하게 접근, 해석, 활용합니다.`
  },
  {
    question: '월지는 무엇인가요?',
    answer: `월지는 어떤 사람이 태어난 달을 의미합니다.\n
일상적으로 양력, 음력으로 사용하고 있는 역법(曆法)에 의한 달도 의미하지만 사주학에서는 절기(節氣)를 기준으로 달을 나눕니다.\n
지구가 태양을 공전할 때 공전 궤도에서 지구의 위치를 기준으로 하기 때문입니다. 자연의 기운 변화는 공전상 절대 위치가 영향이 크기 때문입니다.\n
계절의 변화나 절기의 변화에 따라 어김없이 실제 현상의 변화가 생기는 것을 알 수 있는 까닭은 상기의 기준을 따르기 때문입니다.\n
사주의 해석은 상기 기준의 기운 변화를 우선 기준으로 합니다. `
  },
  {
    question: '월령은 무엇인가요?',
    answer: `사주학에서는 역법에서 제시하는 달력을 쓰지 않고 절기를 중심으로 쓰는 월령 개념의 달력을 기준으로 합니다.\n
주로 기후 변화를 예측할 필요성이 큰 농사에 많이 활용되었는데 실제 기후의 변화와 기운 변화와 매칭되어 있음을 알 수 있습니다.`
  },
  {
    question: '지장간(支藏干)은 무엇인가요?',
    answer: `주로 월의 12지지가 계절적 변화를 진행하면서 발생하는 기운을 순기(純氣) 중심으로 천간에 배속하여 나열한 것을 의미합니다. 물론 하루 12지지 시간 변화 속에서도 같은 원리로 기운이 변화하는 것을 지지에 배속한 것입니다.\n
여기(餘氣)는 앞 지지의 기운이 그대로 이어져서 그 오행을 취한 것이고 중기(中氣)는 그 지지의 삼합 인자가 취기(聚氣)된 오행과 천간을 취한 것이고, 정기(正氣)는 그 지지의 동일 구조의 천간을 취한 것입니다. 월지의 지장간 배열은 월률분야(月律分野)라고 칭하고 다른 지지의 지장간 배열은 인원용사(人元用事)라 칭합니다.\n
지장간 요소는 외부적인 조건에 따라 활발하게 작용하기도 하고 대외적으로 거의 작용이 없는 은장(隱藏)된 기운으로 존재하기도 합니다.\n
여기, 중기, 정기의 배속 비중을 정리하면 아래와 같습니다.\n
         寅申巳亥월:   7일, 7일, 16일
         辰戌丑未월:   9일, 3일, 18일
          子卯酉월:   10일, 20일
             午월:   10일, 9일, 11일`
  },
  {
    question: '12운성(運星)은 무엇인가요?',
    answer: `사주 간지에서 천간이 지지에 뿌리내리거나 세력을 얻고 있는 정도를 12단계로 나누어 정리한 도식(圖式)적 표현을 12운성이라고 합니다.\n
한 인간이 힘을 얻어 태어나고 다시 생명력을 잃어 수명을 다한 뒤, 다시 새로운 기운을 얻는 과정을 비유하여 용어를 정리한 것입니다. 일년생(一年生) 초목이 발아하여 성장 과정을 마무리하고 다시 씨앗으로 돌아갔다가 다시 생명력을 일깨우는 과정으로도 이해할 수 있습니다. 포태(胞胎)법이라는 별칭도 사용합니다.\n
어떤 천간이 사주 전체에서 어느 정도 오행적 힘이나 간지의 힘을 얻고 있는 지를 따지는 기준으로 활용합니다.`
  },
  {
    question: '12운성(運星)은 어떻게 적용, 활용하나요?',
    answer: `12운성은 천간에 드러난 인자가 지지로부터 어느 정도 세력을 확보하였는지를 나누어 보는 것이 기준이 되므로 오행강약과는 그 의미 차이가 있습니다.\n
예를 들어서 월지가 해(亥)이고 일주가 경(庚)인 사람에게 갑(甲)과 을(乙)이 드러난 경우 오행상 수(水)의 힘이 3(천간1, 지지 3의 비중을 기본으로 할 때) 금(金)의 힘이 1, 목(木)의 힘이 2로 나눌 수 있는데 해(亥)월, 경(庚), 갑(甲), 갑(甲)의 경우나 해(亥)월, 경(庚), 을(乙), 을(乙)의 경우 오행강약으로는 그 편차가 없는 것으로 해석합니다. 하지만 12운성의 원리를 적용하면 그 힘의 차이가 각각 발생하는데 해(亥)월, 경(庚), 갑(甲), 갑(甲)의 경우는 갑목(甲木)이 해(亥) 월지에 장생하여 강한 기운을 유지하게 됩니다. 하지만 해(亥)월, 경(庚), 을(乙), 을(乙)의 경우는 을목(乙木)이 해(亥) 월지에 사(死)에 해당하여 목의 기운이 크게 약한 상태임을 파악할 수 있습니다.\n
따라서 오행강약에 의한 상대적 오행의 힘을 따지는 것도 의미가 있지만, 어떤 한 팔자의 세세한 간지 강약을 따질 때에는 12운성의 강약 요소를 감안하여 가감하여 결론을 얻는 것이 합리적이라 할 수 있습니다.`
  },
];

// 공지사항
const faq3: TFAQ[] = [
  {
    question: '12운성(運星)은 어떻게 적용, 활용하나요?',
    answer: `12운성은 천간에 드러난 인자가 지지로부터 어느 정도 세력을 확보하였는지를 나누어 보는 것이 기준이 되므로 오행강약과는 그 의미 차이가 있습니다.\n
예를 들어서 월지가 해(亥)이고 일주가 경(庚)인 사람에게 갑(甲)과 을(乙)이 드러난 경우 오행상 수(水)의 힘이 3(천간1, 지지 3의 비중을 기본으로 할 때) 금(金)의 힘이 1, 목(木)의 힘이 2로 나눌 수 있는데 해(亥)월, 경(庚), 갑(甲), 갑(甲)의 경우나 해(亥)월, 경(庚), 을(乙), 을(乙)의 경우 오행강약으로는 그 편차가 없는 것으로 해석합니다. 하지만 12운성의 원리를 적용하면 그 힘의 차이가 각각 발생하는데 해(亥)월, 경(庚), 갑(甲), 갑(甲)의 경우는 갑목(甲木)이 해(亥) 월지에 장생하여 강한 기운을 유지하게 됩니다. 하지만 해(亥)월, 경(庚), 을(乙), 을(乙)의 경우는 을목(乙木)이 해(亥) 월지에 사(死)에 해당하여 목의 기운이 크게 약한 상태임을 파악할 수 있습니다.\n
따라서 오행강약에 의한 상대적 오행의 힘을 따지는 것도 의미가 있지만, 어떤 한 팔자의 세세한 간지 강약을 따질 때에는 12운성의 강약 요소를 감안하여 가감하여 결론을 얻는 것이 합리적이라 할 수 있습니다.`
  },
];

export const faqs: TFAQ[][] = [faq0, faq1, faq2, faq3];
