import {Redirect, Route, Switch} from "react-router";
import {Signup} from "../containers/Signup";
import {Login} from "../containers/Login";
import Birth from "../containers/Birth";
import Dragon from "../containers/Dragon";
import BirthList from "../containers/List";
import React from "react";
import App from "../App";
import Header from "../components/Common/Header";
import {Layout} from "antd";
import Shop from "../containers/Shop";
import {Home} from "../containers/Home";
import {Checkout} from "../containers/Checkout";
import {MyPage} from "../containers/MyPage";
import Footer from "../components/Common/Footer";
import {Blog} from "../containers/Blog";
import {Corp} from "../containers/IntroCorp";
import {Policy} from "../containers/Policy";
import {PasswordHelper} from "../containers/PasswordHelper";
import {PasswordReset} from "../containers/PasswordReset";
import { GuideFaq } from "src/containers/GuideFaq";
// import {EmailHelper} from "../containers/EmailHelper";
// import {PasswordHelper} from "../containers/PasswordHelper";

const Root = () => {
  const isLogin = localStorage.getItem('token');
  return (
      <App>
        <Layout style={{minHeight: '100vh', height: '100%', backgroundColor: 'white'}}>
          <Header/>
          <Switch>
            <Route path="/home" component={Home}/>
            <Route path="/signup" component={Signup}/>
            <Route path="/login" component={Login}/>
            <Route path="/birth" component={Birth}/>
            <Route path="/dragon/:id" component={Dragon}/>
            <Route path="/list" component={BirthList}/>
            <Route path="/shop" component={Shop}/>
            <Route path="/mypage" component={MyPage}/>
            <Route path="/blog" component={Blog}/>
            <Route path="/corp" component={Corp}/>
            <Route path="/policy" component={Policy}/>
            <Route path="/guide_faq" component={GuideFaq}/>
            {/*/!* <Route path="/help/email" component={EmailHelper}/>*/}
            <Route path="/help/password" component={PasswordHelper}/>
            <Route path="/help/reset-password" component={PasswordReset}/>
            {/*<Route path="/corp" component={Corp}/>*/}
            <Route path="/checkout/:id" component={Checkout}/>
            {isLogin ?
                <Redirect to={"/birth"}/> :
                <Redirect to={"/home"}/>}
          </Switch>
          <Footer/>
        </Layout>
      </App>
  );
};

export default Root;

