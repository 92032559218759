import * as types from './types';
import {createReducer} from "typesafe-actions";

const initialState: types.StarCoinState = {
  starCoin: 0,
  normal: 0,
  bonus: 0,
  nickname: '',
};

const StarCoinReducer = createReducer(initialState)
    .handleAction(types.T_LOAD_COIN_INFO.PENDING, (state: types.StarCoinState, action: types.StarCoinAction) => {
      return {...state, starCoin: 0, normal: 0, bonus: 0, nickname: ''};
    })
    .handleAction(types.T_LOAD_COIN_INFO.SUCCESS, (state: types.StarCoinState, action: types.StarCoinAction) => {
      const {payload}: any = action;
      const {starcoin, normal, bonus, nickname} = payload;

      return {...state, starCoin: starcoin, normal: normal, bonus: bonus, nickname: nickname};
    })
    .handleAction(types.T_LOAD_COIN_INFO.FAILURE, (state: types.StarCoinState, action: types.StarCoinAction) => {
      return {...state};
    })
    .handleAction(types.T_DECREASE_COIN_AMOUNT, (state: types.StarCoinState, action: types.StarCoinAction) => {
      return {...state, starCoin: state.starCoin - action.payload};
    })
    .handleAction(types.T_INCREASE_COIN_AMOUNT, (state: types.StarCoinState, action: types.StarCoinAction) => {
      return {...state, starCoin: state.starCoin + action.payload};
    });

export default StarCoinReducer;
