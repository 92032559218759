import styled from 'styled-components';

export const DragonContainer = styled.div`
  height: auto;
  color: #242424;
  position: relative;
  @media (max-width: 768px) {
    padding: 15px 10% 130px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    height: auto;
  }
`;
