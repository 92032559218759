import React, {useEffect, useRef, useState} from 'react';
import {useScript} from "../../hooks/userScript";
import {useHistory} from "react-router";
import {getApiAxiosWithToken} from "../../utils/axiosUtil";
import {API_URL, PAYPAL_CLIENT_ID} from "../../config";
import * as styled from "./Style";
import {increaseCoin} from "../../modules/starcoin";
import {useDispatch} from "react-redux";

const axiosWithToken = getApiAxiosWithToken();
const increasedCoin: any = {
  '1': 1,
  '2': 10,
  '3': 50,
  '4': 100
};

declare global {
  interface Window {
    paypal: any
  }
}

export const Checkout = (location: any) => {
  const [paypalLoaded, paypalError] = useScript(
      `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=USD`
  );
  const paypalRef = useRef(null);
  // const [productInfo, setProductInfo] = useState<TProductInfo | ''>('');
  const [subtotal, setSubtotal] = useState('');
  const [tax, setTax] = useState('');
  const [total, setTotal] = useState('');
  const [desc, setDesc] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const {match} = location;
  const {id} = match.params;
  const productId = id;

  useEffect(() => {
    const getProductInfo = async () => {
      const productInfoResp = await axiosWithToken.get(API_URL + `/products/${productId}`);
      // setProductInfo(productInfoResp.data.result);
      setSubtotal(productInfoResp.data.result.sub_total);
      setTax(productInfoResp.data.result.tax);
      setTotal(productInfoResp.data.result.total);
      setDesc(productInfoResp.data.result.description);
    };
    getProductInfo();
  }, []);

  useEffect(() => {
    if (paypalLoaded) _initPaypal(window.paypal);
  }, [paypalLoaded]);


  const _initPaypal = (windowPaypal: any) => {
    if (!windowPaypal) return;

    windowPaypal.Buttons({
      createOrder: async () => {
        const resp = await axiosWithToken.post(API_URL + `/paypal/${productId}/orders`);
        return resp.data.order_id;
      },
      onApprove: async (data: any) => {
        await axiosWithToken.get(API_URL + `/paypal/${productId}/orders/${data.orderID}/capture`);
        dispatch(increaseCoin(increasedCoin[productId]));
        history.push('/home')
      },
      onErr: (err: any) => {
        console.log(err);
      }
    }).render(paypalRef.current)
  };

  return (
      <styled.ShoppingContainer>
        <styled.SummaryBox>
          <styled.OrderDetailsBox>
            <styled.OrderDetailsText>Order Details</styled.OrderDetailsText>
            <styled.CheckoutDetailsLabel>{desc}</styled.CheckoutDetailsLabel>
          </styled.OrderDetailsBox>
          <styled.CheckoutHeader>Summary</styled.CheckoutHeader>
          <styled.CheckoutDetailsOriginal>
            <tbody>
            <tr>
              <styled.CheckoutDetailsLabel>Original price:</styled.CheckoutDetailsLabel>
              <styled.CheckoutDetailsAmount>{subtotal}$</styled.CheckoutDetailsAmount>
            </tr>
            {/*<tr>*/}
            {/*  <styled.CheckoutDetailsLabel>tax:</styled.CheckoutDetailsLabel>*/}
            {/*  <styled.CheckoutDetailsAmount>{tax}$</styled.CheckoutDetailsAmount>*/}
            {/*</tr>*/}
            </tbody>
          </styled.CheckoutDetailsOriginal>
          <styled.CheckoutDetailsTotal>
            <tbody>
            <tr>
              <styled.CheckoutDetailsLabel>Total:</styled.CheckoutDetailsLabel>
              <styled.CheckoutDetailsAmount>{total}$</styled.CheckoutDetailsAmount>
            </tr>
            </tbody>
          </styled.CheckoutDetailsTotal>
          <styled.CheckoutText>
            Checkout
          </styled.CheckoutText>
          {(paypalLoaded && !paypalError) && <div style={{textAlign: 'center'}} ref={paypalRef}/>}
        </styled.SummaryBox>
      </styled.ShoppingContainer>
  )
};
