import React, {useState} from 'react';
import FAQListItem from 'src/components/GuideFaq/FaqListItem';
import {faqs} from 'src/utils/faqExamples';
import * as styled from "./Style";

export type TFAQ = {
  question: string;
  answer: string;
}

export const GuideFaq = () => {
  const [tab, setTab] = useState<number>(0);
  const [selected, setSelected] = useState<number>(-1);
  const [tag, setTag] = useState<number>(0);

  const _handleSelectedChange = (s: number) => {
    if (s === selected) setSelected(-1);
    else setSelected(s);
  };

  const _handleTagChange = (t: number) => {
    setSelected(-1);
    setTag(t);
  };

  return (
    <styled.Container>
      <styled.TabsWrapper>
        <styled.TabItem isActive={tab === 0} onClick={() => setTab(0)}>
          결과 가이드
          </styled.TabItem>
        <styled.TabItem isActive={tab === 1} onClick={() => setTab(1)}>
          FAQ
          </styled.TabItem>
      </styled.TabsWrapper>
      <styled.TabHighLighterWrapper>
        <styled.TabHighLighter left={`${50 * tab}%`} />
      </styled.TabHighLighterWrapper>
      {/*<styled.SearchWrapper>*/}
      {/*  <styled.SearchTitleWrapper>FAQ 검색</styled.SearchTitleWrapper>*/}
      {/*  <styled.SearchInputWrapper>*/}
      {/*    <styled.InputSearch placeholder="검색어를 입력해주세요." />*/}
      {/*    <styled.BtnSearch>검색</styled.BtnSearch>*/}
      {/*  </styled.SearchInputWrapper>*/}
      {/*</styled.SearchWrapper>*/}
      {tab === 0 ? (
        <styled.ContentWrapper>
          <styled.GuideImg/>
        </styled.ContentWrapper>
      ) : (
          <styled.ContentWrapper>
            <styled.TagWrapper>
              <styled.TagItem isActive={tag === 0} disabled={tag === 0} onClick={() => _handleTagChange(0)}>이용안내</styled.TagItem>
              <styled.TagItem isActive={tag === 1} disabled={tag === 1} onClick={() => _handleTagChange(1)}>격국용신</styled.TagItem>
              <styled.TagItem isActive={tag === 2} disabled={tag === 2} onClick={() => _handleTagChange(2)}>명리일반
              </styled.TagItem>
              <styled.TagItem isActive={tag === 3} disabled={tag === 3} onClick={() => _handleTagChange(3)}>공지사항
              </styled.TagItem>
            </styled.TagWrapper>
            <styled.listWrapper>
              {faqs[tag].map((f: TFAQ, idx: number) => {
                return (
                    <FAQListItem tag={tag} key={'faq-' + tag + '-' + idx} faq={f} idx={idx + 1}
                                 open={selected === idx + 1} handleClick={_handleSelectedChange}/>
                )
              })}
            </styled.listWrapper>
          </styled.ContentWrapper>
        )}
    </styled.Container>
  )
};
