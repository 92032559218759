import {Modal, Spin} from 'antd';
import "antd/dist/antd.css";
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory} from "react-router";
import {useTimer} from "react-timer-hook";
import {API_URL} from "../../config";
import {isLoginedSelector} from "../../modules/selectors";
import {personPolicy} from '../Policy/personPolicy';
import {servicePolicy} from '../Policy/servicePolicy';
import "./custom.css";
import * as styled from './Styles';
import {marketingPolicy} from "../Policy/marketingPolicy";
import {LoginAsync} from "../../modules/auth";

export const Signup = () => {
  const history = useHistory();
  const [t] = useTranslation();

  const [nickname, setNickname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [randomCode, setRandomCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [policyChecked, setPolicyChecked] = useState<boolean>(false);
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [marketingChecked, setMarketingChecked] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<string>('');
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordConfirmError, setPasswordConfirmError] = useState<string>('');
  const [randomCodeError, setRandomCodeError] = useState<string>('');

  const isLogined = useSelector(isLoginedSelector);
  const dispatch = useDispatch();

  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 5 minutes timer

  const handleSubmit = async () => {
    try {
      const data = {
        ...{ nickname },
        ...{ email },
        ...{ password },
        marketing: marketingChecked,
        random_code: randomCode,
        confirm: passwordConfirm,
      };
      const { status } = await axios.post(API_URL + '/signup', data, {});
      if (status === 200) {
        const values = {email: email, password: password};
        const singupInfo = () => {
          Modal.info({
            title: '회원가입을 축하합니다!',
            content: (
                <div>
                  <p>{nickname}님, bo5stars 회원이 되신 것을 환영합니다.</p>
                </div>
            ),
            onOk() {
            },
          });
        };
        const success = () => {
          history.push('/birth');
        };
        dispatch(LoginAsync(values, success, () => {
        }, () => {
        }));
        singupInfo();
      }
    } catch (e) {
      if (e.response) {
        const {status} = e.response;
        console.log('status : ', status);
        if (status === 404) setRandomCodeError('인증코드를 잘못 입력하였습니다.');
        else if (status === 400) setEmailError('이미 가입된 이메일입니다.');
      }
      console.log(e);
    }
  };

  const getCode = async () => {
    if (email === '') return setEmailError('이메일을 입력해주세요.');
    try {
      setIsSending(true);
      await axios.post(API_URL + '/email', { email: email });
      setIsSent(true);
    } catch (e) {
      console.log('error : ', e);
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    function validateEmail(email: string) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    if (validateEmail(email)) setEmailError('');
    // else setEmailError('잘못된 이메일 형식입니다.');
  }, [email]);

  useEffect(() => {
    if (password.length < 8) setPasswordError('8글자 이상이어야 합니다.');
    else setPasswordError('');

    if (password !== passwordConfirm) setPasswordConfirmError('비밀번호가 일치하지 않습니다.');
    else setPasswordConfirmError('');
  }, [password, passwordConfirm]);

  const showModal = (title: string, content: any) => {
    Modal.info({
      title: title,
      content: (
        <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
          <pre style={{ whiteSpace: 'break-spaces' }}>{content}</pre>
        </div>
      ),
      onOk() {
      },
    });
  };

  return (
    isLogined ? <Redirect to={"/birth"} /> :
      <styled.AccountWrapper>
        {isSending &&
          <styled.LoadingWrapper>
            <styled.LoadingDimmed />
            <Spin />
          </styled.LoadingWrapper>
        }
        <styled.Title>회원가입</styled.Title>
        <styled.AccountFormWrapper>
          <styled.Label>이름</styled.Label>
          <styled.InputWrapper>
            <styled.Input type='text' placeholder="이름을 입력해주세요." onChange={(e) => setNickname(e.target.value)} />
          </styled.InputWrapper>
          <styled.Label>이메일</styled.Label>
          <styled.InputWrapper>
            <styled.Input type='text' placeholder="이메일을 입력해주세요." onChange={(e) => setEmail(e.target.value)} />
            {/*<styled.BtnSend disabled={!!emailError || isSent || isSending} onClick={getCode}>인증코드 발송</styled.BtnSend>*/}
            {/*{isSent && <MyTimer expiryTimestamp={time} />}*/}
          </styled.InputWrapper>
          {(!!emailError && email !== '') && <styled.HelpText isError={true}>{emailError}</styled.HelpText>}
          {/*{isSent &&*/}
          {/*  <>*/}
          {/*    <styled.HelpText isError={false}>위의 이메일로 인증코드를 발송하였습니다.</styled.HelpText>*/}
          {/*    <styled.Label>인증코드</styled.Label>*/}
          {/*    <styled.InputWrapper>*/}
          {/*      <styled.Input type='text' placeholder="인증코드를 입력해주세요." onChange={(e) => setRandomCode(e.target.value)} />*/}
          {/*    </styled.InputWrapper>*/}
          {/*    {!!randomCodeError && <styled.HelpText isError={true}>{randomCodeError}</styled.HelpText>}*/}
          {/*  </>*/}
          {/*}*/}
          <styled.Label>패스워드</styled.Label>
          <styled.InputWrapper>
            <styled.Input type='password' placeholder="********" onChange={(e) => setPassword(e.target.value)} />
          </styled.InputWrapper>
          {(!!passwordError && password !== '') && <styled.HelpText isError={true}>{passwordError}</styled.HelpText>}
          <styled.Label>패스워드 확인</styled.Label>
          <styled.InputWrapper>
            <styled.Input type='password' placeholder="********" onChange={(e) => setPasswordConfirm(e.target.value)} />
          </styled.InputWrapper>
          {(passwordConfirmError !== '' && passwordConfirm !== '') && <styled.HelpText isError={true}>{passwordConfirmError}</styled.HelpText>}
          <styled.CheckboxWrapper style={{ marginTop: '20px' }}>
            <styled.Checkbox checked={policyChecked} onChange={(e) => setPolicyChecked(e.target.checked)} />
            <styled.CheckboxText onClick={() => showModal('회원약관 확인', personPolicy)}>회원약관 확인</styled.CheckboxText>
          </styled.CheckboxWrapper>
          <styled.CheckboxWrapper>
            <styled.Checkbox checked={privacyChecked} onChange={(e) => setPrivacyChecked(e.target.checked)} />
            <styled.CheckboxText onClick={() => showModal('개인정보 수집 및 이용 동의', servicePolicy)}>개인정보 수집 및 이용 동의</styled.CheckboxText>
          </styled.CheckboxWrapper>
          <styled.CheckboxWrapper>
            <styled.Checkbox checked={marketingChecked} onChange={(e) => setMarketingChecked(e.target.checked)} />
            <styled.CheckboxText onClick={() => showModal('마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집 이용 동의', marketingPolicy)}>마케팅
              정보 수신 여부 및 마케팅을 위한 개인정보 수집 이용 동의
            </styled.CheckboxText>
          </styled.CheckboxWrapper>
          <styled.BtnSignup
              disabled={!!emailError || !!passwordConfirmError || !!passwordError || nickname === '' || !policyChecked || !privacyChecked}
              onClick={handleSubmit}
          >
            회원가입
          </styled.BtnSignup>
        </styled.AccountFormWrapper>
      </styled.AccountWrapper>
  );
};

function MyTimer({ expiryTimestamp }: any) {
  const {
    seconds,
    minutes,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center', height: '32p', fontSize: '14px', paddingLeft: '5px', marginLeft: '10px' }}>
      제한시간 <span style={{ paddingLeft: '5px' }}>{minutes}</span>:<span>{seconds < 10 ? "0" : ""}{seconds}</span>
    </div>
  );
}
