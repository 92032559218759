import React from 'react';
import {personPolicy} from "../Policy/personPolicy";
import {servicePolicy} from "../Policy/servicePolicy";
import * as styled from "./Style";

export const Corp = () => {
  return (
      <styled.Container>
        {/*<styled.Title>회사 소개</styled.Title>*/}
        {/*Balance of 5의 서비스는 현재 격국 용신에 관한 모든 고전 이론을 배우고 익히는 과정을 줄여 드리고 각 고전 이론의 이해를 돕는 것을 진행하고 있습니다.<br/>*/}
        {/*Balance of 5의 서비스에서 드리는 정보를 바탕으로 각 개인들이 본인의 방식을 개인화(customize)해서 활용할 수 있도록 만들어 드리는 것을 목표로 삼고 있습니다.<br/>*/}
        {/*518,400개의 모든 사주를 각 고전에서 나오는 기준점들– 자평, 명리정종, 궁통보감, 적천수-을 모두 적용, 분류, 분석해서 格과 오행(五行) 강약(强弱)을 구하고, 용신을 계산식으로 산출한*/}
        {/*것입니다.*/}

        <styled.PolicyWrapper>
          <styled.Title>서비스 이용약관</styled.Title>
          <styled.TextArea readOnly>
            {servicePolicy}
          </styled.TextArea>
        </styled.PolicyWrapper>


        <styled.PolicyWrapper>
          <styled.Title>개인정보 수집 및 이용 동의서</styled.Title>
          <styled.TextArea readOnly>
            {personPolicy}
          </styled.TextArea>
        </styled.PolicyWrapper>
      </styled.Container>
  )
};
