export const createAsyncActionTypes = (actionType: string) => ({
  PENDING: `${actionType}/PENDING`,
  SUCCESS: `${actionType}/SUCCESS`,
  FAILURE: `${actionType}/FAILURE`,
});

export const createAsyncStarCoinTypes = (actionType: string) => ({
  PENDING: `${actionType}/PENDING`,
  SUCCESS: `${actionType}/SUCCESS`,
  FAILURE: `${actionType}/FAILURE`,
});
