import styled from 'styled-components';

export const LoginWrapper = styled.div`
  height: 100vh;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  @media only screen and (max-width: 600px) {
    height: 700px;
  }
`;

export const LoginFormWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  margin: 50px auto 10px auto;
  border-radius: 8px;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LoginTitle = styled.div`
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
`;

export const InputEmail = styled.input`
  //width: 430px;
  width: 100%;
  height: 48px;
  padding: 10px;
  border-radius: 10px;
  border: solid 2px #484848;
`;

export const BtnLogin = styled.button`
  width: 170px;
  height: 42px;
  border-radius: 21px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #c8c8c8;
  cursor: pointer;
  &:hover {
    background-color: #242424;
  }
`;

export const BtnLetter = styled.text`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

export const BtnLoginWrapper = styled.div`
  text-align: center;
`;

export const SNSContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  @media only screen and (max-width: 600px) {
    display: block; 
    text-align: center;
  }
`;

export const NaverContainer = styled.div`
  margin-bottom: 10px;
`;

export const KakaoContainer = styled.div`
  width: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const PasswordWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  color: #979797;
`;

export const SignupWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #979797;
  cursor: pointer;
`;

export const EmailSubTitle = styled.div`
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.26;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
`;

export const PasswordFormWrapper = styled.div`
  margin: 0 auto;
  align-items: center;
`;

export const InvalidAccountWrapper = styled.div`
  padding-bottom: 24px;
  @media (max-width: 600px) {
    padding-left: 0%; 
  }
`;

export const InvalidAccountText = styled.text`
  color: red;
  @media (max-width: 600px) {
    font-size: 13px;
  }
`;
