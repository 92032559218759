import {ThunkDispatch} from "redux-thunk";
import * as types from './types';
import {API_URL} from "../../config";
import axios from 'axios';


export const StarCoinAsync = () => async (dispatch: ThunkDispatch<types.StarCoinState, void, types.StarCoinAction>) => {
  // const axiosWithToken = getApiAxiosWithToken();
  dispatch({type: types.T_LOAD_COIN_INFO.PENDING});
  try {
    const access_token = localStorage.getItem('access_token');
    if (!access_token) return;
    const config = {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    };
    const res = await axios.get(API_URL + '/coin', config);
    dispatch({type: types.T_LOAD_COIN_INFO.SUCCESS, payload: res.data});
  } catch (e) {
    dispatch({type: types.T_LOAD_COIN_INFO.FAILURE, payload: e});
  }
};

export const decreaseCoin = (amount: number) => ({type: types.T_DECREASE_COIN_AMOUNT, payload: amount});
export const increaseCoin = (amount: number) => ({type: types.T_INCREASE_COIN_AMOUNT, payload: amount});


