import React from 'react';
import {Spin} from "antd";
import * as styled from './Styles';
import {useTranslation} from "react-i18next";

export const Loading = () => {
  const [t] = useTranslation();

  const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  const mobile = Boolean(
      userAgent.match(
          /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
  );

  return (
      <styled.SpinWrapper>
        <Spin size={mobile ? "default" : "large"} tip={t("birthForm.loading")}/>
      </styled.SpinWrapper>
  )
};
