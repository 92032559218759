import styled from 'styled-components';
import guideImg from '../../assets/img_guide_modi.jpeg';
import guideImg2 from '../../assets/guide_img2.jpg';

interface ITabItem {
  isActive: boolean;
}
interface ITabHighLighter {
  left: string;
}

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 670px;
  padding: 80px 0px;
  color: #242424;
  @media only screen and (max-width: 500px) {
    padding: 40px 15px;
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

export const TabItem = styled.div<ITabItem>`
  flex: 1;
  padding: 20px 0;
  text-align: center;
  color: ${(props) => props.isActive ? '#242424' : '#c8c8c8'};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

export const TabHighLighterWrapper = styled.div`
  width: 100%;
  height: 4px;
  background-color: #f2f2f5;
  position: relative;
  margin-bottom: 40px;
`;
export const TabHighLighter = styled.div<ITabHighLighter>`
  width: 50%;
  position: absolute;
  top: 0;
  height: 4px;
  left: ${(props) => props.left};
  transition: left 0.2s linear;
  background-color: #242424;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
  }
`;

export const SearchTitleWrapper = styled.div`
  font-size: 18px;
  font-weight: 900;
  width: 100%;
  margin-bottom: 15px;
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InputSearch = styled.input`
  flex: 1;
  padding: 10px 15px;
  border-radius: 10px;
  border: solid 2px #484848;
  background-color: white;
  margin-right: 10px;
  outline: none;
  &::placeholder {
    color: #c1c1c1;
  }
`;

export const BtnSearch = styled.button`
  flex: 1;
  max-width: 130px;
  height: 46px;
  border: none;
  outline: none;
  border-radius: 21px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #242424;
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  @media only screen and (max-width: 500px) {
    //padding: 20px;
  }
`;
export const GuideImg = styled.img.attrs({src: guideImg})`
  padding: 0px;
  @media (max-width: 768px) {
    max-width: 350px;
  }
`;
export const MGuideImg = styled.img.attrs({src: guideImg2})`
`;
export const TagWrapper  = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

interface ITagItem {
  isActive: boolean;
}
export const TagItem  = styled.button<ITagItem>`
  color: ${(props) => props.isActive ? '#242424' : '#444544'};
  border: ${(props) => props.isActive ? 'solid 3px #242424' : 'solid 3px #d7d7d7'};
  padding: 5px 20px;
  border-radius: 30px;
  margin-right: 15px;
  background-color: white;
  outline: none;
  cursor: pointer;
`;

export const listWrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;
