import React, {useState} from 'react';
import * as styled from '../Style';
import {gyeok} from "../../../../utils/finalFateFeature";
import {specialMap} from "../../../../utils/specialFateFeature";
import FateModal from '../FateModal';

export const FateFrameMain = ({main, special, dayGan}: any) => {
  const [data, setData] = useState<any>(null);
  const _handleOpenFateFrameModal = (key: string) => {
    const _data = gyeok(key, dayGan);
    _data && setData(_data);
  };
  const _handleOpenSpecialModal = (key: string) => {
    const _data = specialMap[key];
    _data && setData(_data);
  };
  return (
      <styled.Wrapper>
        <FateModal
            title={data && data.title}
            show={!!data}
            onClose={() => setData(null)}
            data={data && data.list}
        />
        <styled.FateframeWrapper>
          <styled.Row style={{paddingRight: '24%'}}>
            {
              main.fateframe === '격이 존재하지 않음.' ?
                  <div>
                    격 명칭 - <styled.Highlight>{main.fateframe}</styled.Highlight>
                  </div>
                  :
                  <div>
                    격 명칭 - <styled.BtnModal
                      onClick={() => _handleOpenFateFrameModal(main.fateframe)}>{main.fateframe}</styled.BtnModal>
                  </div>
            }
            <br/>
            월지 당령 - <styled.Highlight>{main.성격월득령}</styled.Highlight>
          </styled.Row>
          <styled.Row>
            {main.multi_fateframes.length !== 0 ? (
                `다중격 - ${main.multi_fateframes.map((key: any) => key)}`
            ) : (null)}<br/>
            특수격
            - <styled.Highlight>{special.map((specialFrame: string, idx: number) =>
              <>{idx > 0 && ", "}
                <styled.BtnModal
                    onClick={() => _handleOpenSpecialModal(specialFrame)}>
                  {specialFrame}
                </styled.BtnModal>
              </>
          )}</styled.Highlight>
          </styled.Row>
        </styled.FateframeWrapper>
      </styled.Wrapper>
  )
};
{/*격 명칭 - <styled.Highlight>{main.fateframe}</styled.Highlight><br/>*/
}
