import styled from 'styled-components';

export const TableRowWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  max-width: 500px;
  margin: 0 auto;
  @media only screen and (max-width: 500px) {
    padding: 15px;
  }
`;

export const TableCellWrapper = styled.div`
  text-align: center;
  flex: 1;
  &:last-child {
    border-right: none;
  }
  @media only screen and (max-width: 500px) {
  }
`;

interface IAgeLetter {
  daeunAgeColor: string;
}

export const TableTitle = styled.div<IAgeLetter>`
  font-weight: bold;
  font-size: 1.2rem;
  color: ${(props) => props.daeunAgeColor || 'black'};
  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;