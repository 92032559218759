import React, {useState} from 'react';
import * as styled from './Style';
import {MenuOutlined} from '@ant-design/icons';
import {CloseOutlined} from "@ant-design/icons/lib";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LogoutAsync} from "../../../../modules/auth";
import {useDispatch} from "react-redux";

const LinkStyle = {
  width: '100%',
  textAlign: 'center' as 'center',
  padding: '10px'
};

const Hamburger = ({starCoin, bonusStarCoin, name, isLogined}: any) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const handlerLogout = () => {
    const success = () => {
      localStorage.clear();
      history.push('/home');
    };
    dispatch(LogoutAsync(success));
  };

  if (isOpen) {
    return (
        <>
          <CloseOutlined onClick={() => setIsOpen(false)} style={{position: 'relative', zIndex: 10, fontSize: '20px'}}/>
          {isLogined ?
              <styled.NarrowMenuContainer>
                <Link style={LinkStyle} to={'/birth'} onClick={() => setIsOpen(false)}>
                  <styled.NavFont>
                    {t("header.birth")} <span role={"img"} aria-label={"cake"}>🎂</span>
                  </styled.NavFont>
                </Link>
                <Link style={LinkStyle} to={'/list'} onClick={() => setIsOpen(false)}>
                  <styled.NavFont>
                    {t("header.birth list")} <span role={"img"} aria-label={"birth-lists"}>📋</span>
                  </styled.NavFont>
                </Link>
                {/*<Link style={LinkStyle} to={'/shop'} onClick={() => setIsOpen(false)}>*/}
                {/*  <styled.NavFont>*/}
                {/*    {t("header.shop")} <span role={"img"} aria-label={"purchase"}>🛒</span>*/}
                {/*  </styled.NavFont>*/}
                {/*</Link>*/}
                <Link style={LinkStyle} to={'/guide_faq'} onClick={() => setIsOpen(false)}>
                  <styled.NavFont>
                    가이드 / FAQ <span role={"img"} aria-label={"faq"}>🔖</span>
                  </styled.NavFont>
                </Link>
                <Link style={LinkStyle} to={'/birth'}
                      onClick={() => window.open("https://www.notion.so/Blog-by-Bo5stars-4f252774e29740d6a605a6d01b1b5ff4")}>
                  <styled.NavFont>
                    {t("header.blog")} <span role={"img"} aria-label={"mypage"}>📓</span>
                  </styled.NavFont>
                </Link>
                <Link style={LinkStyle} to={'/mypage'} onClick={() => setIsOpen(false)}>
                  <styled.NavFont>
                    마이페이지 <span role={"img"} aria-label={"mypage"}>🏡</span>
                  </styled.NavFont>
                </Link>
                <styled.NavFont style={LinkStyle}>
                  <div onClick={handlerLogout}>
                    {t("header.logout")} <span role={"img"} aria-label={"fire"}>🚪</span>
                  </div>
                </styled.NavFont>
              </styled.NarrowMenuContainer>
              :
              <styled.NarrowMenuContainer>
                <Link style={LinkStyle} to={'/login'} onClick={() => setIsOpen(false)}>
                  <styled.NavFont>
                    {t("header.login")} <span role={"img"} aria-label={"mypage"}>🚪</span>
                  </styled.NavFont>
                </Link>
              </styled.NarrowMenuContainer>
          }
          {/*<styled.NarrowMenuContainer>*/}
          {/*  <styled.NavFont>*/}
          {/*    /!*<Popover placement="bottom" title={text} content={content} trigger="click">*!/*/}
          {/*    /!*  {name[0]}*!/*/}
          {/*    /!*</Popover>*!/*/}
          {/*  </styled.NavFont>*/}
          {/*</styled.NarrowMenuContainer>*/}
        </>
    )
  }

  return (
      <styled.Container>
        <MenuOutlined onClick={() => setIsOpen(true)}
                      style={{position: 'relative', zIndex: 10, fontSize: '20px', color: 'white'}}/>
      </styled.Container>
  );
};

export default Hamburger;
