import React, {useEffect, useState} from 'react';
import {Product} from 'src/components/Shop/Product';
import {API_URL} from 'src/config';
import {getApiAxiosWithToken} from 'src/utils/axiosUtil';
import * as styled from "./Style";

const packageArr = [
  { plan: 'BASIC', price: '9.6 $', coin_quantity: 10, bgImgCss: 'linear-gradient(135deg, #15acf8, #6e33e7)' },
  { plan: 'ADVANCED', price: '29.99 $', coin_quantity: 50, bgImgCss: 'linear-gradient(135deg, #fdaf0f, #fe5816)' },
  { plan: 'PREMIUM', price: '44.99 $', coin_quantity: 100, bgImgCss: 'linear-gradient(135deg, #e70fb7, #6e33e7)' },
];

const axiosWithToken = getApiAxiosWithToken();

export type TProduct = {
  id: string;
  normal_token_increment: string;
  total: string;
}

const Shop = () => {
  const [products, setProducts] = useState<TProduct[]>([]);

  useEffect(() => {
    const loadProducts = async () => {
      const { data } = await axiosWithToken.get(API_URL + `/products`);
      setProducts(data.result);
    };
    loadProducts();
  }, []);

  return (
    <styled.Container>
      <styled.TitleContainer>
        <styled.ImgTitleStarCoin />
        <styled.Title>
          <styled.TitleHighlighted>STARCOIN</styled.TitleHighlighted>으로 <br />
          확장된 서비스를 즐겨보세요!
        </styled.Title>
        <styled.SubTitle>
          STARCOIN은 가입시에 무료로 지급받을 수 있습니다
        </styled.SubTitle>
      </styled.TitleContainer>
      {products.length > 0 &&
        <styled.ProductContainer>
          <styled.ProductFlexWrapper>
            {products.slice(0, 2).map((product: TProduct) => {
              return <Product product={product} />
            })}
          </styled.ProductFlexWrapper>
          <styled.ProductFlexWrapper>
            {products.slice(2, products.length).map((product: TProduct) => {
              return <Product product={product} />
            })}
          </styled.ProductFlexWrapper>
        </styled.ProductContainer>
      }
      {/*<styled.PackageTitleContainer>*/}
      {/*  <styled.PackageTitle>BALANCE PACKAGE</styled.PackageTitle>*/}
      {/*  <styled.PackageSubTitle>밸런스 패키지를 구매시 유료강의 및 강의 요약집을 보내드립니다</styled.PackageSubTitle>*/}
      {/*</styled.PackageTitleContainer>*/}
      {/*<styled.PackageFlexWrapper>*/}
      {/*  {packageArr.map((p) => {*/}
      {/*    return (*/}
      {/*      <styled.PackageItemWrapper key={p.plan}>*/}
      {/*        <styled.PackageItemHeader bgImgCss={p.bgImgCss}>*/}
      {/*          <styled.PackageItemHeaderText>{p.plan}</styled.PackageItemHeaderText>*/}
      {/*          <styled.PackageItemHeaderText>{p.price}</styled.PackageItemHeaderText>*/}
      {/*        </styled.PackageItemHeader>*/}
      {/*        <styled.PackageItemContent>*/}
      {/*          <styled.CoinWrapper>*/}
      {/*            <styled.ImgStarCoinS />*/}
      {/*            <styled.PackageItemContentText>*/}
      {/*              X {p.coin_quantity}*/}
      {/*            </styled.PackageItemContentText>*/}
      {/*          </styled.CoinWrapper>*/}
      {/*          <styled.PackageItemContentSmallText>+</styled.PackageItemContentSmallText>*/}
      {/*          <styled.PackageItemContentSmallText>유료 강의 오픈</styled.PackageItemContentSmallText>*/}
      {/*        </styled.PackageItemContent>*/}
      {/*      </styled.PackageItemWrapper>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</styled.PackageFlexWrapper>*/}
    </styled.Container>
  )
};

export default Shop;
