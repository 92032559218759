import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: -130px;
  width: 112px;
  height: 740px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff;
  z-index: 300;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px 25px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

interface IItem {
  isActive: boolean;
}
export const Item = styled.div<IItem>`
  flex: 1;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.isActive ? '#3e3a39' : '#9b9b9b'};
  cursor: pointer;
`;

interface IHighLighter {
  top: number;
}
export const HighLighter = styled.span<IHighLighter>`
  position: absolute;
  right: 0;
  top: ${(props) => (33 + (53.8 * props.top)) + 'px'};
  transition: top 0.2s linear;
  width: 4px;
  height: 32px;
  background-color: black;
`;

// export const MobileContainer = styled.div`
//   display: none;
//   &::-webkit-scrollbar {
//     display: none;
//   }
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
//   @media (max-width: 1200px) {
//     display: block;
//     width: 100%;
//     height: 60px;
//     overflow-x: scroll;
//     overflow-y: hidden;
//     -webkit-overflow-scrolling: touch;
//     margin-bottom: 20px;
//     position: relative;
//     padding: 15px 0px;
//     touch-action: pan-x;
//     white-space: nowrap;
//     border-radius: 20px;
//     box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
//     background-color: #ffffff;
//   }
//   @media (max-width: 768px) {
//     margin-bottom: 10px;
//   }
// `;
// export const MobileItem = styled.div<IItem>`
//   font-size: 16px;
//   display: inline-block;
//   min-width: 80px;
//   width: calc(100% / 12);
//   max-width: calc(100% / 12);
//   color: ${(props) => props.isActive ? '#3e3a39' : '#9b9b9b'};
//   text-align: center;
//   cursor: pointer;
// `;
// interface IMobileHighLighterWrapper {
//   left: number;
// }
// export const MobileHighLighterWrapper = styled.div<IMobileHighLighterWrapper>`
//   position: absolute;
//   bottom: 10px;
//   left: ${(props) => (0 + 80 * props.left)}px;
//   transition: left 0.2s linear;
//   width: 80px;
//   text-align: center;
//   display: flex;
//   justify-content: center;
// `;
// export const MobileHighLighter = styled.span`
//   width: 32px;
//   height: 4px;
//   background-color: black;
//   display: block;
// `;

export const MobileContainer = styled.div`
  display: none;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  padding: 15px 0px;
  @media (max-width: 1200px) {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    display: block;
    height: 60px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 15px 0px;
    touch-action: pan-x;
    white-space: nowrap;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
export const MobileItem = styled.div<IItem>`
  flex: 1;
  font-size: 16px;
  width: calc(100% / 13);
  color: ${(props) => props.isActive ? '#3e3a39' : '#9b9b9b'};
  text-align: center;
  cursor: pointer;
  @media (max-width: 550px) {
    display: inline-block;
    font-size: 15px;
    min-width: 60px;
    width: calc(100% / 13);
    max-width: calc(100% / 13);
  }
`;
interface IMobileHighLighterWrapper {
  left: number;
}
export const MobileHighLighterWrapper = styled.div<IMobileHighLighterWrapper>`
  position: absolute;
  bottom: 10px;
  left: ${(props) => (1 / 13 * props.left * 100)}%;
  transition: left 0.2s linear;
  width: calc(100% / 13);
  text-align: center;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    left: ${(props) => (0 + 60 * props.left)}px;
    width: 60px;
  }
`;
export const MobileHighLighter = styled.span`
  width: 100%;
  max-width: 30px;
  height: 4px;
  background-color: black;
  display: block;
  @media (max-width: 500px)  {
    width: 32px;
  }
`;
