import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 310;
`;
export const Dimmed = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  opacity: 0.8;
  background-color: #15171a;
`;
export const ModalWrapper = styled.div`
  padding: 50px;
   border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff; 
  position: relative;
  max-height: 500px;
  max-width: 380px;
  color: #242424;
`;

export const BtnClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 40px;
  text-align: center;
`;
export const ContentWrapper = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;
export const ContentList = styled.div`
`;
export const ContentTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
`;
export const ContentListItemWrapper = styled.div`
  margin-bottom: 15px;
`;
export const ContentListItem = styled.div`
  margin-bottom: 15px;
`;
export const BtnWrapper = styled.div`
  text-align: center;
`;
export const BtnCheck = styled.button`
  cursor: pointer;
  border-radius: 21px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 5px 50px;
  margin-top: 5px;
  color: #ffffff;
  background-color: #242424;
`;
