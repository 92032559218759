import styled from 'styled-components';
import BirthIntro from "../../assets/W_02_01_01/image_area.png"

export const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  padding-top: 50px;
  //height: 100vh;
  @media (max-width: 500px) {
    padding: 10px;
    padding-top: 30px;
  }
`;

export const BirthIntroImg = styled.div`
  background-image: url(${BirthIntro});
  width: 500px;
  height: 589px;
  margin: 0 auto;
  background-repeat: no-repeat;
  @media (max-width: 975px) {
    display: none;
  }
`;
export const BirthIntroWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const BirthIntroTitle = styled.div`
  padding-left: 40px;
  width: 100%;
  font-size: 42px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
`;
export const BirthIntroSubTitle = styled.div`
  padding-left: 40px;
  padding-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.67;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
`;

export const BirthFormWrapper = styled.div`
  width: 500px;
  padding: 20px 30px;
  padding-top: 0px;
  //margin: 10px auto -5px;
  //@media only screen and (max-width: 600px) {
  //    display: block;
  //    max-width: 100%;
  //    padding: 20px 7px;
  //}
  @media only screen and (max-width: 500px) {
    width: 100%;
    display: block;
    //max-width: 350px;
    padding: 20px 7px;
  }
`;

interface ICheckItem {
  isActive?: boolean;
}

export const BirthGender = styled.text<ICheckItem>`
  font-weight: ${(props) => props.isActive ? 'bold' : 'normal'};
  color: ${(props) => props.isActive ? '#242424' : '#c1c1c1'};
`;

export const BirthLunar = styled.text<ICheckItem>`
  font-weight: ${(props) => props.isActive ? 'bold' : 'normal'};
  color: ${(props) => props.isActive ? '#242424' : '#c1c1c1'};
`;
export const BirthBtn = styled.button<ICheckItem>`
  padding: 13px 41px 13px 42px;
  margin-top: 40px;
  border-radius: 27.5px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #c8c8c8;
  cursor: pointer;
  &:hover {
    background-color: #242424;
  }
`;
export const BirthBtnText = styled.text`
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;


export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const InputLabel = styled.div`
  width: 100px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.26;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
`;

export const InputContent = styled.div`
  flex: 1;
  //@media (max-width: 650px) {
  //  max-width: 350px;
  //}
`;

interface IKnowTime {
  knowTime: boolean;
}

export const NoTimeExplanation = styled.div<IKnowTime>`
  font-size: 13px;
  color: #999999;
  display: ${props => props.knowTime ? 'none' : 'block'};
  //padding-bottom: 5px;
`;

export const InputBirth = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InputBirthWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  //@media (max-width: 650px) {
  //  width: 350px;
  //}
`;

export const InputBirthContainer = styled.div`
  //flex: 1;
  padding: 0px 3px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.26;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 500px) {
    min-width: 90px;
  //  flex-direction: column-reverse;
  }
`;
export const InputName = styled.input`
  width: 100%;
  //max-height: 44px;
  padding: 10px;
  border-radius: 10px;
  border: solid 2px #484848;
  //@media (max-width: 650px) {
  //  width: 350px;
  //}
`;
