import styled from "styled-components";
import imgStarCoinM from "../../../assets/image_starcoin_m.png";

export const ProductItemWrapper = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  font-weight: 900;
  position: relative;
  cursor: pointer;
  &:first-child {
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    padding: 10px 20px;
    font-size: 20px;
  }
`;
export const HighlightWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  border: solid 4px #ffe328;
  overflow: hidden;
`;

export const HighlightSelectWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 70px solid #ffe328;
  border-right: 70px solid transparent;
  @media (max-width: 500px) {
    border-top: 60px solid #ffe328;
    border-right: 60px solid transparent;
  }
`;
export const HighlightSelectText = styled.div`
  position: absolute;
  top: 15px;
  left: -5px;
  font-size: 14px;
  color: #eca80a;
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
  @media (max-width: 500px) {
    top: 10px;
    left: 0;
    font-size: 11px;
  }
`;
export const CoinWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const PriceWrapper = styled.div`
`;
export const ImgStarCoinM = styled.img.attrs({ src: imgStarCoinM })`
  margin-right: 20px;
  @media (max-width: 500px) {
    max-width: 50px
  }
`;