const ganChar: any = {
  '甲': [
    '굳건하게 밀고 나간다.',
    '추진력이 강하다.',
    '‘못 먹어도 고’ 스타일이다.',
    '자존감 때문에 실속을 빨리 챙기지 못한다.',
    '남다른 아이디어가 많다.',
    '상대적으로 마무리가 좀 약하다.',
  ],
  '乙': [
    '밀고 나가다가 상황에 따라 돌아가기도 한다.',
    '생각은 많은데 실행에 옮기는 것은 한계가 따른다.',
    '늘 희망적 요소를 더 찾아 움직인다.',
    '섣부른 판단과 행동으로 소모가 잦다.',
  ],
  '丙': [
    '명랑 쾌활한 성격이 고유의 성격이다.',
    '무엇이든 선명하게 처리하려는 기운 때문에 다른 희생이 따른다.',
    '낙천적 기질 때문에 현실 면에서 손해가 자주 따른다.',
    '늘 희망을 버리지 않으니 기회가 많다.',
  ],
  '丁': [
    '정확하고 세밀한 것을 추구한다.',
    '늘 희망적인 심상을 가지면서 이면도 함께 생각한다.',
    '이런저런 생각의 번잡함이 자꾸 따른다.',
    '잃어버린 것을 잘 찾아내는 밝은 눈, 혜안이 있다.'
  ],
  '戊': [
    '감정을 숨기기 어려워서 내면의 감정을 쉽게 드러낸다.',
    '사심이 없으니 활달함을 그대로 드러내어 오해를 받기도 한다.',
    '은근히 전통적 가치를 충요하게 여겨서 옛스럽다는 오해도 받는다.',
  ],
  '己': [
    '주위와 융화력을 잘 발휘하니 가장 무난한 성격을 가진 것으로 주변에서 여긴다.',
    '자신의 개성을 강조하지 않으니 개인적인 개성이 자꾸 묻혀버린다.',
    '때로는 자신의 생각이나 경향이 어떤 것인지 잘 구별되지 않는다.',
  ],
  '庚': [
    '강한 의지력이 앞서니 주도적 행동을 자주 하게 된다.',
    '의리를 앞세우다 보니 융통성, 융화력을 놓치는 경우가 잘 발생한다.',
    '굳건한 의지로 미션을 끝까지 잘 완성하는 장점도 가진다.',
  ],
  '辛': [
    '매사에 정밀하고 정확한 것을 추구하는 성향이 강하다.',
    '남과 어울리는 융통성을 발휘하는데 능숙하지 못한 측면도 많다.',
    '다양한 성공을 이룰 수 있는 기운이 있지만 끊고 맺음이 강하여 동반한 소모도 많다.'
  ],
  '壬': [
    '추구성이나 목적 의식이 강하여 성공을 크게 이룰 수 있는 기질을 가진다.',
    '내심을 잘 들내지 않고 야욕을 가지고 지속적으로 노력하는 힘을 가지고 있다.',
    '융통성에 따른 능력 발휘도 잘 한다.',
  ],
  '癸': [
    '맑음을 추구하는 기운이 강하여 도덕적 정당성을 많이 추구한다.',
    '남다른 기준이나 목표 의식으로 인내심을 발휘한다.',
    '속내를 잘 드러내지 않고 지속적인 노력을 통하여 개인적인 목표를 잘 성취하는 기질이 따른다.',
  ],
};

export const gyeokMap: any = {
  '건록격': {
    title: '건록격',
    list: [
      {
        title: '건록의 에너지가 담기면',
        contents: [
          '조직 사회, 자기 사업 모두 가능한 멀티 형이다. 국가 조직과도 인연이 깊다.',
          '리더쉽 기질이 강하여 많은 사람을 이끌어 나가는 스타일이다.',
          '공사를 분명히 하고, 공익을 우선시 한다.',
          '독립적인 사고, 남에게 의지하지 않고, 신세지는 것, 구속 받는 것 싫어한다.',
          '친구에 살고 친구에 죽는다.  運(운)이 좋을 때는 친구로 흥하고, 運(운)이 나쁘면 친구로 망한다.',
          '조상의 풍요로움을 물려받기 보다 스스로 자수 성가한다.',
          '형제가 많아도 결국 집안의 맏이 노릇 한다.',
          '에너지가 많아지면 손재, 극처의 상황이 펼쳐진다.',
          '평상시에는 경쟁을 하지만 외부에서 압력이 오면 서로 뭉쳐 한 목소리를 내는 협동행위도 잘한다.'
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: [],
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '공직, 자격증 분야, 교육, 전문성이 있는 분야나 기술 사업 등에 인연이 많고 천성과 어울린다.'
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '상사와의 관계 면에서 자신의 주장을 절충하는 것, 휘하를 잘 포용하고 리드하는 것 등이 성공의 지름길이 된다.  포용성, 융화력을 발휘하는 것이 중요하다.'
        ]
      },
    ]
  },
  '양인격': {
    title: '양인격',
    list: [
      {
        title: '양인의 에너지가 담기면',
        contents: [
          '지도력 중심의 기운이 강하다.',
          '조직 사회, 자기 사업 모두 가능한 멀티 형이다.',
          '이기려는 마음이 강하고, 인상이 선해도 강력한 승부사 기질이 있다.',
          '약자를 진심으로 보호하고, 속마음은 따뜻하며 공익에 대한 의협심이 있다.',
          '타고난 그릇이 커서 사사로운 재물에 연연해 하지 않고, 통이 크다.',
          '독립심과 도전 정신이 강하며, 삶의 굴곡도 있지만 역경에도 쉽게 굴하지 않고 목표를 향해 전념하여 멋지게 극복한다.',
          '부모의 번영을 뒤로하고, 결국 무에서 유를 다시 창조하는 경험을 한다.',
          '에너지가 거듭 쌓이면, 재물이 흩어지는 시간이 올 수 있다.',
          '에너지가 거듭 쌓이면 가족들과 멀어져 지내는 세월이 온다.',
          '에너지의 순환이 어려울 때, 독선적인 기질이 강해지면서 고독해 질 수 있음을 유념하라',
          '누구에게도 굴복하지 않고, 불의를 보고 참지 못하는 별이니, 주변, 라이벌 중심의 안티 세력이 생길 수 있다.',
          '의리의 아이콘임과 동시에 배신의 아이콘이기도 하다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '지배력이 강하고, 사업성 발휘도 잘하여 의료, 압력이나 파워 요소가 많은 제조, 법무 등 에너지가 많이 몰리는 분야 중심 사업에 성공한다.'
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '리더십이 너무 강하여 반발심을 가지는 사람도 많이 있으니 주변 환경을 살펴서 조화력을 발휘할 필요가 있다.',
          '경쟁이 따르는 분야에서 액티브하게 움직이는 기운이 많아, 남다른 노력으로 많은 것을 이룩하는 타입이다.'
        ]
      },
    ]
  },
  '식신격': {
    title: '식신격',
    list: [
      {
        title: '식신의 에너지가 담기면',
        contents: [
          '도량이 넓고 희생정신도 강하며, 수명이 긴 것이 일반적이다.',
          '좋아하는 일과 생활이 일치되는 행운이 따른다.',
          '명랑하고 여유가 있으며, 원만하고 성실하다.',
          '풍부한 표현 능력과 탐구심으로 에너지가 치우치지 않는다면, 원하는 분야의 성취가 무난하다.',
          '다양한 것을 추구하지 않고 자기만의 세계를 유지하며 빠져 나오지 않는 경향이 강하다.',
          '에너지가 너무 치우치면, 때론 남의 말을 잘 듣지 않고, 자신만의 기준에서 생각하고 판단하여 행동하므로 상대방에게 자신도 모르게 민폐를 끼치는 경우도 많다.',
          '에너지가 다소 퇴색되어 있는 경우, 자신감 결여, 결단력이 다소 부족해 보일 수 있으나, 가족의 위로와 격려로 다시 크게 해낼 수 있는 순수한 열정이 있다.',
          '보여주고 내세우려는 심리가 있다.',
          '남들과의 교류에 서투른 경향이 있다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '일반적으로 깊은 이해와 지식이 필요한 분야에서 활동을 많이 함 (인문, 사회, 글쓰기 분야등)',
          '의료, 자격증 분야, 교육, 제조 관련 기술 분야, 식품 관련 분야, 어학 관련 분야 등에 인연이 많고 천성과 어울린다.'
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '일을 성취함에 있어 결과가 더디고, 세월이 다소 걸리는 약점이 있지만 지속적인 노력과 안정적인 노력, 주변의 인덕(人德)으로 성취하는 기운이 강하다.',
          '성장기에 에너지가 경직되어 학업 성취가 어려웠다면, 이후라도 책을 가까이 하고, 마음을 아름답게 가꾸어야 편안한 삶이 펼쳐진다.',
          '횡적인 관계나 아랫사람들과는 조화도가 높지만 윗사람과는 상대적으로 부조화성이 발생하기 쉬우니 염두에 둘 필요가 있다.',
          '귀인이 주변에 항상 있으니, 삶의 위급한 변곡점에서도 매사 긍정적인 마음으로, 무리하거나 서두르지 마라.',
        ]
      },
    ]
  },
  '상관격': {
    title: '상관격',
    list: [
      {
        title: '상관의 에너지가 담기면',
        contents: [
          '독특한 창작 능력을 가지고 태어난다.',
          '에너지가 조화롭다면 공부하면 박사, 운동하면 세계 프로, 예술하면 신의 경지에 이를 법한 팔방미인의 능력을 갖추고 있다.',
          '대체로 아래 사람에게 너그럽고 측은 지심이 강하며, 베풀고 대접하는 걸 즐기는 경향이 있다.',
          '변함 없는 조직의 틀보다는 유연하게, 본인의 독자적인 능력으로 비지니스와 학문, 교육 활동에 가담한다.',
          '얽매어진 틀이나 규칙등을 배격하며, 자유롭고 평등하고 개혁적인 면을 추구한다.  혁명성이 내제되어 있다.',
          '외향적으로 남들과 잘 어울리며 자기 PR을 잘하여 자신의 능력을 밖으로 잘 발휘한다.',
          '머리 회전이 빠르고 총기 발랄하여 지적, 재능이 탁월하다.',
          '에너지가 과한 경우, 습득 능력은 탁월하나, 무슨 일이든 용두사미로 끝까지 해 내지 못하는 아쉬움이 있다.',
          '알면 말하고 싶고, 몰라도 아는 척하기 쉽고 지식의 정도가 깊지는 않은데 많이 아는 것처럼 보이며, 전달력/표현력이 매우 좋다.',
          '에너지가 치우치면 심신이 쇠약해 지기 쉬우니 건강에 유지를 위해 신경을 써야한다.',
          '퍼주는데 인화가 잘 안되는 경우가 많다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '직업적으로 가장 다양한 분야에 진출이 가능한 타입이다.',
          '직업적으로 매우 다양한 모양으로 살아가는데 예술, 교육, 스포츠, 기술 등 창작 요소가 많이 필요한 분야, 전문성이 있는 분야나 부가가치가 높은 기술사업 중심으로 진출하면 성공이 원만하다.',
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '남다른 창조력으로 많은 것을 해결할 수 있으니 평소 아이디어 추구를 잘 하는 것도 성공의 지름길이 된다.',
          '가만히 있어도 유독 시선을 끄는 에너지의 별인데다가, 비범한 말솜씨와 설득력이 있어 반드시 자기의 의지를 관철시키려는 마음이 강하다. 이로 인해 말로써 상대방에게 마음의 상처를 주는 경향도 발생하니, 늘 말을 줄이고, 항상 겸손과 양보의 처세술이 요구된다.',
          '사업을 하면 틈새 시장을 노려서 해야 성공확률이 높다.',
          '오랜 조직 생활을 하기는 어렵고, 조직생활을 하더라도 반복적이고 평범한 일보다는 역동적이고 창작성을 요구하는 부서가 적합하다.',
          '특히 조직사회, 윗사람과의 갈등은 잘 극복할 필요가 있다.'
        ]
      },
    ]
  },
  '편재격': {
    title: '편재격',
    list: [
      {
        title: '편재의 에너지가 담기면',
        contents: [
          '뚜렷한 목표 의식과 실천력은 큰 성공을 위한 기질과 장점이 된다.',
          '호기심이 많고, 성격이 명랑하며, 상대의 기분을 잘 맞춘다.',
          '에너지가 균형을 이루면, 호방하고 인심이 후한 사업가 체질이다.',
          '다방면에 재주가 많으며, 다양한 대인 관계에서 체득한 경험이 그대로 재물이 성취 내용으로 이어진다.',
          '작은 문제에 집착하지 않으며, 무엇이든 조직화, 구조화 시키는 거시적인 안목이 있다.',
          '궂은 일을 마다하지 않고, 그대로 수용하고 대처하는 능력이 좋다.',
          '에너지가 조화롭게 배치되지 않은 경우 한탕주의, 투기 성향이 강해 질 수 있고, 허례허식, 낭비벽의 모습이 나타날 수 있다.',
          '이 별이 있는 남성은 이성에 대한 단정한 자기 관리와 윤리관이 필요하다.',
          '에너지가 치우쳐 있으면, 평생 재물의 흐름이 아쉬우며, 말만 앞세우는 사람으로 평가 받을 수 있다.',
          '에너지가 넘치면 분수외의 지출(교제나 유흥에 금전을 아끼지 않음)이 생긴다. 절제 있는 생활이 필요하다. (취미가 많은 풍류인 타입)',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '안정적인 재물 활동이나 직업 분야 중심으로 안정하기 어렵고 변화가 많은 사업이나 투자 중심으로 직업적 활동이 잘 이루어진다.',
          '타인의 돈이나 재화를 다루는 금융, 유통 분야에 진출하면 본인은 직장 활동도 가능하다.',
          '유통, 무역, 재무, 금융, 영업 관련 분야, 역마(驛馬) 관련 분야(항공, 해운, 조선, 자동차, 무역, 언론 방송, 건설, 외교 등) 등에 인연이 많고 천성과도 어울린다.'
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '융통성과 적응력, 재치 등이 남다르므로 동반하여 사회적 성취와 발전 기회를 많이 잡을 수 있는 그릇이지만 일관성을 놓치기 쉬우니 유념할 필요가 있다.',
          '상황에 대처하는 순발력 때문에 일관성을 잃거나 중요한 약속을 놓치는 경우가 많으니 챙길 필요가 있다. 즉 상대에게 신뢰를 얻도록 자기 관리를 잘 할 필요가 있다.',
          '투기와 투자를 돈으로 벌어 들이고, 안되면 빈털털이가 된다.',
          '유통되는 재를 운영하여 거부가 됨'
        ]
      },
    ]
  },
  '정재격': {
    title: '정재격',
    list: [
      {
        title: '정재의 기운이 담기면',
        contents: [
          '임기응변식의 일처리를 싫어하며, 신용과 성실이 기본이고, 준법적이다.',
          '자상하고 신중한 기질, 현실주의자, 알뜰살뜰 실속을 추구한다.',
          '명랑하면서도 인색하며, 의심이 많아 조심성이 있다.',
          '내 것에 대한 애착심이 강하고, 나은 삶으로의 노력을 꾸준히 하는 대기만성형',
          '손해보는 일을 잘 하지 않으며, 목표를 세운 것에 대해 의외의 집착이 있다.',
          '투자보다는 안정적인 수입을 원하여, 무리한 투기는 계획에 없다.',
          '돌다리도 두들기며 걷는 격으로,허영이나 일확천금의 망상이 없이 착실하다.',
          '이 에너지가 좋은 남성은 상속의 수혜가 있으며, 결혼 후, 더욱 발복 하고 경제 흐름이 아름답게 펼쳐진다.  ',
          '여자는 애교가 있고 남자는 애처가이다.',
          '안정감을 주는 성품에 반해, 돈에 관한 한 냉정한 이중성이 있다 (구두쇠 기질)',
          '실리와 명분이 없으면 학업에 매진하지 않고, 시간도 쓰지 않는다.',
          '에너지가 치우치면, 욕심 많고, 인색하다는 평을 들을 수 있으니, 베풀고 또 베풀라',
          '모든 면에서 낙천적이고 긍정적인 성격을 유지하는 장점이 따른다. 그러나 낙천적이고 긍정적인 성격으로 인해 상대적으로 경쟁심, 치열함, 강한 성취욕 등이 떨어진다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '부지런히 저축해 나가는 봉급 생활(안정적 수입)이 좋다.',
          '변화가 많지 않은 공직이나 조직 사회, 자격증 분야, 교육, 금융, 재무, 유통 등에 인연이 많고 직업 분야로 어울린다.'
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '목표나 목적을 이룩하는 방식이나 성공까지 시간적으로 더 걸리는 측면도 있지만 꾸준한 노력과 계단식 발전을 통하여 안정적인 삶을 영위한다.',
          '강한 목적 의식, 치열함, 조직성, 사회적 조화력이 상대적으로 약할 수 있으니 사회 활동을 할 때 완전히 극복하기는 어렵더라도 이런 요소들을 관리하고 신경을 쓸 필요가 있다.'
        ]
      },
    ]
  },
  '편관격': {
    title: '편관격',
    list: [
      {
        title: '편관의 기운이 담기면',
        contents: [
          '열정과 목표의식이 뚜렷하다. 뱉은 말에 책임지려는 영웅 심리, 호걸의 기상이 있다.',
          '생각대로 행동하는 실천주의자로, 한다면 한다.',
          '스스로를 엄격하게 통제하려는 강박증을 보일 수 있으니 항상 자신에게 관대하고 여유있는 마음을 가질 것',
          '자존심, 폼을 추구하는 기운이 강하여 자기 통제와 노력이 남다를 수 있는 기질이 따른다.',
          '에너지가 균형을 얻어 학업에 정진, 세상을 구제하고 통제하는 위치이 직업이 유리하며, 합법적인 라이센스가 있다면 무난 발전이다.',
          '상대적으로 세상살이에 대한 낭만적 생각이나 행동이 부족하니 보완에 신경을 쓸 필요가 있다.',
          '대의를 위해 기꺼이 나를 희생해서 옳다고 판단하는 방향으로 리드하고, 설사 실익이 없더라도 그 과정에서 보람을 느끼나 고독하다.',
          '마음의 앙금이 끝까지 가는 스타일, 오래된 일도 생생하며 상황판단이 빠르다.',
          '에너지가 치우치면, 건강이 불안하면 각종 사고에 노출될 수 있고, 삶의 기복이 심하다.',
          '흑백논리 고지식한 선입견을 가질 수 있는 별이니 매사 포용심을 키워라',
          '에너지 균형이 무너지면 다툼을 즐기고 목적을 위해 남을 이용하는 권모술수가 교묘하다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '특수성이 있는 공직(법무, 치안, 외교, 군무 등), 공학 기술, 사람을 많이 상대하는 접객성 사업 분야, 큰 조직과 손을 잡는 형태의 사업 등에 인연이 많고 성공도 수월하다.'
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '집안이나 핏줄에 관계없이 자신의 지력과 안력으로 성공을 거머쥐는 자수성가형의 성공을 이룬다.',
          '모범적인 행동과 개인적인 절제로 성공을 잘 이룩할 수 있고 여러 사람을 이끌 수 있는 역량이 충분하다.',
          '흑백논리 고지식한 선입견을 가질 수 있는 별이니 매사 포용심을 키워라',
          '지나친 자존심과 폼의 추구는 주위와 불화를 만들 수 있으니 조절을 요한다.',
        ]
      },
    ]
  },
  '정관격': {
    title: '정관격',
    list: [
      {
        title: '정관의 기운이 담기면',
        contents: [
          '올바른 명예, 권위, 반듯하고 섬기는 리더쉽을 보여주어, 룰과 규칙이 요구되어지는 사회에서 중히 쓰인다.',
          '타인에 대한 배려, 조화도가 좋으니 인덕(人德)도 많이 따르는 장점이 있다.',
          '엄친아 소리를 들을 수 있는 FM 세월을 보내며, 학교와 조직에 충실하다.',
          '품행이 단정하고, 예의를 중시하며, 책임감이 강하다. 원리 원칙을 지키며 고지식하기도 함.',
          '사물의 핵심을 파악하는데 능하고, 비교적 신중하다.',
          '안정된 사고 속에서 이성적으로 해결하려는 기질이 있으며, 기본적으로 평화주의자이다.',
          '윗 사람에 대한 충성심이 좋으며, 그에 따른 혜택이 크고 수월하다.',
          '합리적이고, 지적인 매우 유능한 지도자이나, 너무 치밀해서 인색하다는 평도 듣는다.',
          '에너지의 균형이 깨지면, 체면을 중시하는 요소로 인하여 실리를 빨리 취하기 어려운 측면이 있다.',
          '에너지가 치우쳐 있으면, 융통성이 떨어지고, 유연한 사고가 다소 아쉬우며, 자기 고집이 강하게 드러날 수 있다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '부하나 수하를 잘 관리하는 능력이 탁월하고, 사회적인 면이 강하여 조직에서 중책을 맡는다.',
          '일반 행정 중심의 공직, 안정적인 조직 사회, 규모가 크거나 상당한 조직 사회, 자격증 분야 등의 직업에 인연이 많고 천성과도 어울린다.',
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '모범적 행동과 노력으로 여러 사람을 이끌 수 있는 역량이 충분하고 성공을 위한 조건을 충분히 갖출 수 있으니 별도의 솔루션 요소가 필요 없는 경우에 해당한다.',
          '상대적으로 여러 사람이나 주위와 융통성 발휘 부족, 낭만성 발휘의 부족(이성적 판단 중심) 등으로 무미(無味)한 관계가 잘 발생하니 보완할 필요가 있다.'
        ]
      },
    ]
  },
  '편인격': {
    title: '편인격',
    list: [
      {
        title: '편인의 기운이 담기면',
        contents: [
          '상황판단을 잘하고, 눈치가 빠르고 머리 회전이 빠르다.',
          '영감이 발달하고 응용력이 탁월한 싫증을 잘내는 천재 타입임',
          '남이 뭐라고 하든 자기만 좋으면 만족하는 성향이 강함.',
          '세상은 뭔가 잘못된 것이라 생각하면 그 잘못을 밝혀야 하고 고쳐야 한다는 사고를 갖고 있다. 이 잘못을 찾기 위해 종교와 철학에도 관심이 많다.',
          '천혜의 수단을 가지지 못하여 자신의 힘으로 모든 것을 만들어야 하는 상황을 만난 격으로 개인적인 노력으로 남다른 성공을 이룩하는 기질을 가진다.',
          '일에서 처음은 있으나 끝이 없는 즉 용두사미가 많다.',
          '자존심이 강하여 이상과 현실의 괴리감으로 번민하는 세월이 있다.',
          '다양한 공상, 신비주의, 외골수 기질이 있고, 자기 과신과 의심이 많아 기회를 놓칠 수 있다.',
          '사물에 대한 예민한 감수성과 통찰력으로 세상을 깜짝 놀라게 하는 히트의 인자(특이한 아이디어)가 있다.',
          '에너지가 치우치면 비제도권 학문에 관심이 많고, 전공과 직업이 여러 번 바뀐다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '특수분야에 있어서 독보적인 천재성을 발휘함',
          '수단이 빈약하여 남다른 노력을 통하여 성공을 이루는데 주로 자격증 분야, 전문 기술, 특수한 분야, 전문성이 있는 교육, 의약, 짧은 시간에 힘을 집중하는 성격의 스포츠 등에 인연하여 직업적 성공을 거둔다.',
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '다재다능하고 잘 뒤엎고 새로시작하는 모험심도 있어, 이것저것 해보나 고단하고 화려한 이력만 쌓일 뿐 실속이 부족 할 수 있으니 한가지 일을 지속적으로 하는 것이 중요함.',
          '기발한 아이디어와 독창성, 남다른 인내심과 특별한 방식은 성공을 크게 이룩할 수 있는 힘이 된다.',
          '하지만 특이성을 통하여 성공을 이룬다는 것은 보편적 방식을 떠난 것이므로 주위와 갈등을 많이 만들기도 하고 소모적인 과정을 만들기도 한다.  이런 점에 유의하면서 성공을 꾀하는 것이 좋을 것을 뜻한다.',
          '베풀고 돌아오는 허탈감을 당연하게 즐겨라.  당장은 고독해도, 그 경험이 훗날 든든한 내면의 자산이 된다.',
        ]
      },
    ]
  },
  '정인격': {
    title: '정인격',
    list: [
      {
        title: '정인의 기운이 담기면',
        contents: [
          '도덕적 표준을 잘 지키고 동반한 노력도 지속적이라 미션의 완성도가 높은 타입이다.',
          '품행 단정, 어질고 예의 바르다.',
          '명예과 규칙, 약속을 소중히 여긴다.',
          '받아들이는 배움에 순후한 열정이 있으니, 스승과 윗 어른들의 덕을 입는다.',
          '인내심, 지구력이 좋고, 완성도를 높이는 노력 요소로 제도권 공부(특히 고등학교 때)에 강한 장점을 가진다.',
          '상대에 대한 배려와 이해심이 좋고, 따뜻하고 상냥하다.',
          '에너지가 치우치면 독자적으로 판단하는 것에 두려움으로 어른들 말씀에 의지하고 잘 따르니, 마마 걸, 마마보이 소리를 듣는 경우가 빈번하다.',
          '다소 보수적인 성향, 변화 무쌍한 상황이나 편법을 즐기지 않으며, 안정적이고 클래식한 아이템을 좋아한다.',
          '매사에 도덕적 표준을 따르다 보니 다방면에 융통성을 발휘하는 능력이나 상업적 융통성이 부족한 단점이 따른다.',
        ]
      },
      {
        title: '내면의 추구성과 개성',
        contents: []
      },
      {
        title: '격에 어울리는 직업 분야',
        contents: [
          '직업이 글과 학문, 자격을 떠나지 않는 형이라 주로 글공부를 통한 직업 형성과 성공이 많이 이루어진다.',
          '공직, 자격증 분야, 교육, 언론방송, 전문성이 있는 분야나 보편적인 기술 사업 등에 인연이 많고 천성과도 잘 어울린다.'
        ]
      },
      {
        title: '성공 solution',
        contents: [
          '본인이 도덕적 표준을 잘 따르므로 여러 사람에 대한 이해 부족, 정신적 갈등도 한번씩 겪는다.',
          '상업적 융통성이 부족하고 낭만성이 부족한데 여러 사람을 대면하거나 적극적인 비즈니스를 구한다면 이런 점들을 잘 극복할 필요가 있다.',
        ]
      },
    ]
  },
};

export const gyeok = (key: string, dayGan: string) => {
  gyeokMap[key].list[1].contents = ganChar[dayGan];
  return gyeokMap[key];
};
