import React, {useState} from 'react';
import * as styled from './style';
import axios from "axios";
import {API_URL} from "../../config";
import {useTimer} from "react-timer-hook";
import {useHistory} from "react-router";
import {Spin} from "antd";

const time = new Date();
time.setSeconds(time.getSeconds() + 300); // 5 minutes timer

export const PasswordHelper = () => {
  const [email, setEmail] = useState<string>('');
  const [randomCode, setRandomCode] = useState<string>('');

  const [emailError, setEmailError] = useState<string>('');
  const [noEmail, setNoEmail] = useState<string>('');
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [randomCodeError, setRandomCodeError] = useState<string>('');

  const history = useHistory();

  const getCode = async () => {
    if (email === '') return setEmailError('이메일을 입력해주세요.');
    try {
      setIsSending(true);
      await axios.post(API_URL + '/fpassword', {email: email});
      setIsSent(true);
      setNoEmail('');
    } catch (e) {
      const {status} = e.response;
      if (status === 400) setNoEmail('가입되어 있지 않은 이메일입니다.');
    } finally {
      setIsSending(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const data = {
        ...{email},
        // random_code: randomCode,
      };
      const {status} = await axios.get(API_URL + '/fpassword', {headers: data});
      history.push({pathname: '/help/reset-password', state: {email: email}});
    } catch (e) {
      const {status} = e.response;
      if (status === 404) setRandomCodeError('인증코드가 틀렸습니다.');
      if (status === 400) setEmailError('등록되어 있지 않은 이메일입니다.')
    }
  };

  return (
      <styled.Container>
        {isSending &&
        <styled.LoadingWrapper>
          <styled.LoadingDimmed/>
          <Spin tip={"코드 발송 중..."}/>
        </styled.LoadingWrapper>
        }
        <styled.Title>
          이메일 인증
        </styled.Title>
        <styled.Label>가입하신 이메일을 입력해주세요.</styled.Label>
        <styled.InputWrapper>
          <styled.Input type='text' placeholder="이메일을 입력해주세요." onChange={(e) => setEmail(e.target.value)}/>
          {/*  <styled.BtnSend disabled={!!emailError || isSent || isSending} onClick={getCode}>인증코드 발송</styled.BtnSend>*/}
        </styled.InputWrapper>
        {/*{(!!noEmail && email !== '') && <styled.HelpText isError={true}>가입되어 있지 않거나 잘못된 이메일 형식입니다.</styled.HelpText>}*/}
        {/*{isSent &&*/}
        {/*<>*/}
        {/*<styled.HelpText isError={false}>위의 이메일로 인증코드를 발송하였습니다.</styled.HelpText>*/}
        {/*<styled.Label>인증코드</styled.Label>*/}
        {/*<styled.InputWrapper>*/}
        {/*  <styled.Input type='text' placeholder="인증코드를 입력해주세요." onChange={(e) => setRandomCode(e.target.value)}/>*/}
        {/*</styled.InputWrapper>*/}
        {/*{!!randomCodeError && <styled.HelpText isError={true}>{randomCodeError}</styled.HelpText>}*/}
        {/*</>*/}
        {/*}*/}
        <>
          {!!emailError && <styled.HelpText isError={true}>{emailError}</styled.HelpText>}
        </>
        <styled.BtnSignup
            onClick={handleSubmit}
        >
          이메일 확인하기
        </styled.BtnSignup>
      </styled.Container>
  )
};

function MyTimer({expiryTimestamp}: any) {
  const {
    seconds,
    minutes,
  } = useTimer({expiryTimestamp, onExpire: () => console.warn('onExpire called')});

  return (
      <div style={{
        display: 'inline-flex',
        alignItems: 'center',
        height: '32p',
        fontSize: '14px',
        paddingLeft: '5px',
        marginLeft: '10px'
      }}>
        제한시간 <span style={{paddingLeft: '5px'}}>{minutes}</span>:<span>{seconds < 10 ? "0" : ""}{seconds}</span>
      </div>
  );
}
