import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const NarrowMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: white;
  padding-top: 50px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px 0px
`;

export const NavFont = styled.div`
  padding-top: 3px;
  font-size: 1.2rem;
  @media only screen and (max-width: 590px) {
    font-size: 1.2rem;
  }
`;
