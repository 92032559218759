import styled from "styled-components";

export const ShoppingContainer = styled.div`
  display: flex;
  height: 100vh;
  padding-top: 40px;
  padding-right: 50px;
  padding-left: 50px;
  justify-content: center;
  @media (max-width: 992px) {
    padding: 0 16px;
  }
  @media (max-width: 576px) {
    padding: 0 48px;
  }
`;

export const Notification = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
  max-width: 100%;
`;

export const SummaryBox = styled.div`
  border: 1px solid #e8e9eb;
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, .1), 0 3px 1px 0 rgba(20, 23, 28, .1);
  box-sizing: border-box;
  display: table;
  margin-bottom: 10px;
  padding: 24px 20px 0 24px;
  position: sticky;
  top: 8em;
  width: 500px;
  @media (max-width: 992px) {
    width: 300px;
    padding: 24px 24px 0 24px;
  }
`;

export const CheckoutText = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33em;
`;

export const CheckoutHeader = styled.div`
  line-height: 24px;
  vertical-align: middle;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 16px;
`;

export const CheckoutDetailsOriginal = styled.table`
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #cacbcc;
  border-collapse: separate;
`;

export const CheckoutDetailsLabel = styled.td`
  line-height: 24px;
  vertical-align: middle;
  width: 100%;
  padding-bottom: 5px;
`;

export const CheckoutDetailsAmount = styled.td`
  min-width: 65px;
  padding-left: 10px;
  text-align: end;
`;

export const CheckoutDetailsTotal = styled.table`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
`;

export const OrderDetailsBox = styled.div`
  //@media (max-width: 576px) {
  //  margin: -10px -5px;
  //}
`;

export const OrderDetailsText = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const ShoppingList = styled.div`
  margin-right: 32px;
  width: 60%;
`;
