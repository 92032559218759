import styled from "styled-components";

export const Container = styled.div`
  padding-left: 10%;
  padding-top: 25px;
  padding-bottom: 50px;
  height: 100vh;
  @media (max-width: 1500px) {
    height: auto;
  }
`;

export const Titile = styled.div`
  font-weight: 800;
  font-size: 24px;
`;
