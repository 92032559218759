import styled from 'styled-components';
import starcoin from '../../assets/icon_starcoin_24.png';

interface ITabItem {
  isActive: boolean;
}
interface ITabHighLighter {
  left: string;
}

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 670px;
  padding: 80px 0px;
  color: #242424;
  @media only screen and (max-width: 500px) {
    padding: 40px 15px;
  }
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LoadingDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;
export const TitleWrapper = styled.div`
  width: 100%;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    font-size: 30px;
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

export const TabItem = styled.div<ITabItem>`
  flex: 1;
  padding: 20px 0;
  text-align: center;
  color: ${(props) => props.isActive ? '#242424' : '#c8c8c8'};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

export const TabHighLighterWrapper = styled.div`
  width: 100%;
  height: 4px;
  background-color: #f2f2f5;
  position: relative;
  margin-bottom: 40px;
`;
export const TabHighLighter = styled.div<ITabHighLighter>`
  width: 50%;
  position: absolute;
  top: 0;
  height: 4px;
  left: ${(props) => props.left};
  transition: left 0.2s linear;
  background-color: #242424;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff;  
  padding: 40px;
  text-align: center;
  @media only screen and (max-width: 500px) {
    padding: 20px;
  }
`;
export const listWrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  //background-color: #f6f6f6;
`;

export const tableWrapper = styled.div`
  //padding-top: 60px;
  //max-width: 1080px;
  margin: 0 auto;
  height: 100%;
  //min-width: 768px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 768px) {
    padding-top: 30px;
    height: auto;
    width: 100%;
    min-width: auto;
    max-width: auto;
  }
`;
export const ListRowHover = styled.a`
 color: black;
 position: relative;
 display: inline-block;
 transition: .3s;
 text-decoration: none;
 font-weight: 700;
 &::after {
 position: absolute;
 bottom: .3em;
 left: 0;
 content: '';
 width: 100%;
 height: 1px;
 /* background-color: RGB(37, 147, 252); */
 opacity: 0;
 transition: .3s;
 }
 &:hover::after {
 bottom: 0;
 opacity: 1;
 }
 &:hover {
 color: RGB(37, 147, 252);
 }
`;

export const ProfileName = styled.div`
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 40px;
  text-align: left;
  @media only screen and (max-width: 500px) {
    font-size: 30px;
  }
`;

export const StarCoinWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #f2f2f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconStarCoin = styled.img.attrs({src: starcoin})`
  width: 24px;
  height: 24px;
  margin-right: 40px;
`;

export const TextStarCoin = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const ContentRowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;

export const ContentRowTitle = styled.div`
  flex: 1;
  font-size: 16px;
  min-width: 120px;
  color: #979797;
  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const ContentRowContent = styled.div`
  font-size: 20px;
  flex: 2.5;
  color: #242424;
  display: flex;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

export const BtnEdit = styled.button`
  margin-top: 50px;
  padding: 10px 40px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #242424;
  outline: none;
  border: none;
  text-align: center;
  color: white;
`;
