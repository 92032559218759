import styled from 'styled-components';

interface ILetterWrapper{
  backgroudColor: string;
  letterColor: string;
}

export const LetterWrapper = styled.div<ILetterWrapper>`
  background-color: ${(props) => props.backgroudColor || 'white'};
  color: ${(props) => props.letterColor || 'black'};
  border-radius: 10px;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  &:first-child {
      margin-bottom: 5px;
  }
`;

interface ILetter{
  letterSize: string;
}

export const Letter = styled.div<ILetter>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.letterSize || 'inherit'};
  font-weight: bold;
  @media (max-width: 500px) {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;
