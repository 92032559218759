import React, {useEffect, useState} from 'react';
import * as styled from './style';
import axios from "axios";
import {API_URL} from "../../config";

export const PasswordReset = (location: any) => {
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordConfirmError, setPasswordConfirmError] = useState<string>('');

  const handleSubmit = async () => {
    const {history} = location;
    const {state} = history.location;
    try {
      const data = {
        password: passwordConfirm,
        email: state.email,
        confirm: passwordConfirm,
      };
      const {status} = await axios.patch(API_URL + '/users', data, {});
      if (status === 200) history.push('/login');
    } catch (e) {
      if (e.response) {
        const {status} = e.response;
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (password.length < 8) setPasswordError('8글자 이상이어야 합니다.');
    else setPasswordError('');

    if (password !== passwordConfirm) setPasswordConfirmError('비밀번호가 일치하지 않습니다.');
    else setPasswordConfirmError('');
  }, [password, passwordConfirm]);

  return (
      <styled.Container>
        <styled.Title>
          비밀번호 재설정
        </styled.Title>
        <styled.Label>패스워드</styled.Label>
        <styled.InputWrapper>
          <styled.Input type='password' placeholder="********" onChange={(e) => setPassword(e.target.value)}/>
        </styled.InputWrapper>
        {(!!passwordError && password !== '') && <styled.HelpText isError={true}>{passwordError}</styled.HelpText>}
        <styled.Label>패스워드 확인</styled.Label>
        <styled.InputWrapper>
          <styled.Input type='password' placeholder="********" onChange={(e) => setPasswordConfirm(e.target.value)}/>
        </styled.InputWrapper>
        {(passwordConfirmError !== '' && passwordConfirm !== '') &&
        <styled.HelpText isError={true}>{passwordConfirmError}</styled.HelpText>}
        <styled.BtnSignup
            disabled={!!passwordConfirmError || !!passwordError}
            onClick={handleSubmit}
        >
          비밀번호 재설정
        </styled.BtnSignup>
      </styled.Container>
  )
};
