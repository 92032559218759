import {ActionType} from 'typesafe-actions';
import {createAsyncStarCoinTypes} from "../../utils/reduxUtil";

const prefix: string = 'starcoin';

export const T_LOAD_COIN_INFO = createAsyncStarCoinTypes(`${prefix}/LOAD_COIN_INFO`);
export const T_DECREASE_COIN_AMOUNT = `${prefix}/DECREASE_COIN_AMOUNT`;
export const T_INCREASE_COIN_AMOUNT = `${prefix}/INCREASE_COIN_AMOUNT`;

export type StarCoinAction = ActionType<any>;

export type StarCoinState = {
  starCoin: number;
  normal: number;
  bonus: number;
  nickname: string;
}
