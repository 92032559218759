import styled from 'styled-components';
import iconQ from '../../../assets/icon_q.png';

export const FAQListItemWrapper = styled.div`
  padding: 15px 20px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff;
  margin-bottom: 15px;
`;
export const FAQLeftWrapper = styled.div`
  width: 48px;
  margin-right: 25px;
`;
export const FAQRightWrapper = styled.div`
  flex: 1;
`;

export const FAQListItemQuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FAQListItemAnswerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FAQListItemQuestion = styled.div`
  text-align: left;
  font-weight: bold;
  color: #242424;
`;

export const FAQListItemQuestionArrow = styled.img`
  width: 20px;
  height: 20px;
`;

export const FAQListItemAnswer = styled.div`
  margin-top: 20px;
`;
export const FAQListItemPre = styled.pre`
  white-space: pre-wrap;
`;

export const IconQuestion = styled.img.attrs({src: iconQ})`
`;
