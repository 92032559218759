import styled from 'styled-components';
// import imgLogo from '../../../assets/logo/bo5_logo_white.png';
import imgLogo from '../../../assets/logobeta_gnb.png';
import starcoin from '../../../assets/icon_starcoin_24.png';

export const Wrapper = styled.div`
  width: 100%;
  background: #242424;
`;
export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px;
`;
export const ImgLogo = styled.img.attrs({src: imgLogo})`
  height: 45px;
  cursor: pointer;
`;
export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

interface IMenuItem {
  isActive?: boolean;
}

export const MenuItem = styled.div<IMenuItem>`
  color: ${(props) => props.isActive ? 'white' : '#909090'};
  margin-right: 50px;
  cursor: pointer;
  @media (max-width: 1080px) {
    margin-right: 30px;
  }
  &:hover {
    color: white;
  }
  &:last-child {
    margin-right: 0px;
  }
`;
export const StarCoinWrapper = styled.div`
  padding: 5px 13px;
  border-radius: 10px;
  background-color: #484848;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const IconStarCoin = styled.img.attrs({src: starcoin})`
  width: 24px;
  height: 24px;
  margin-right: 40px;
`;
export const TextStarCoin = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff; 
`;
export const Profile = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: white;
  font-size: 26px;
  font-weight: 900;
  color: #3e3a39;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BtnSignin = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  padding: 5px 13px;
  border-radius: 10px;
  background-color: white;
  color: black;
`;

export const AccountWrapper = styled.div`
  float: right;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

export const WideMenuWrapper = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
  display: block;
  }
`;

export const NarrowMenuWrapper = styled.div`
  display: block;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  float: left;
  z-index: 10;
`;

export const LogoAtag = styled.a`
  max-width: 100%;
  max-height: 100%;
`;

export const LogoImg = styled.img`
  height: -webkit-fill-available;
  @media only screen and (max-width: 510px) {
    height: 44px;
    width: 120px;
  }
`;

export const NavFont = styled.div`
  padding-top: 7px;
  font-size: 1.1rem;
  @media only screen and (max-width: 590px) {
    font-size: 0.95rem;
  }
`;

export const Name = styled.span`
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 1.5rem;
  @media only screen and (max-width: 590px) {
    font-size: 0.95rem;
  }
`;
