import React from 'react';
import {Card, Col, Row} from 'antd';
import * as styled from './Style';


const {Meta} = Card;

export const Blog = () => {
  return (
      <styled.Container>
        <styled.Titile>
          <h1>블로그 홈</h1>
        </styled.Titile>
        <Row style={{paddingBottom: '24px'}}>
          <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 1}}>
            <Card
                hoverable
                style={{width: '240px', borderColor: "black"}}
                cover={<img alt={"example"} src={"https://picsum.photos/id/237/200/300"}/>}
                onClick={() => window.open("https://www.notion.so/_1-dffe8dbf8a26425d9ea19ffac437d6fd")}
            >
              <Meta title="天干合(천간 합)_1" description="천간 합은 무엇일까요?"/>
            </Card>
          </Col>
          <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 1}}>
            <Card
                hoverable
                style={{width: '240px', borderColor: "black"}}
                cover={<img alt={"example"} src={"https://picsum.photos/seed/picsum/200/300"}/>}
                onClick={() => window.open("https://www.notion.so/_1-dffe8dbf8a26425d9ea19ffac437d6fd")}
            >
              <Meta title="天干合(천간 합)_1" description="천간 합은 무엇일까요?"/>
            </Card>
          </Col>
          <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 1}}>
            <Card
                hoverable
                style={{width: '240px', borderColor: "black"}}
                cover={<img alt={"example"} src={"https://picsum.photos/200/300?grayscale"}/>}
                onClick={() => window.open("https://www.notion.so/_1-dffe8dbf8a26425d9ea19ffac437d6fd")}
            >
              <Meta title="天干合(천간 합)_1" description="천간 합은 무엇일까요?"/>
            </Card>
          </Col>
        </Row>

        <Row style={{paddingBottom: '24px'}}>
          <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 1}}>
            <Card
                hoverable
                style={{width: '240px', borderColor: "black"}}
                cover={<img alt={"example"} src={"https://picsum.photos/id/237/200/300"}/>}
                onClick={() => window.open("https://www.notion.so/_1-dffe8dbf8a26425d9ea19ffac437d6fd")}
            >
              <Meta title="天干合(천간 합)_1" description="천간 합은 무엇일까요?"/>
            </Card>
          </Col>
          <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 1}}>
            <Card
                hoverable
                style={{width: '240px', borderColor: "black"}}
                cover={<img alt={"example"} src={"https://picsum.photos/seed/picsum/200/300"}/>}
                onClick={() => window.open("https://www.notion.so/_1-dffe8dbf8a26425d9ea19ffac437d6fd")}
            >
              <Meta title="天干合(천간 합)_1" description="천간 합은 무엇일까요?"/>
            </Card>
          </Col>
          <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 1}}>
            <Card
                hoverable
                style={{width: '240px', borderColor: "black"}}
                cover={<img alt={"example"} src={"https://picsum.photos/200/300?grayscale"}/>}
                onClick={() => window.open("https://www.notion.so/_1-dffe8dbf8a26425d9ea19ffac437d6fd")}
            >
              <Meta title="天干合(천간 합)_1" description="천간 합은 무엇일까요?"/>
            </Card>
          </Col>
        </Row>
      </styled.Container>
  )
};
