import React, {useEffect, useState} from 'react';
import './App.css';
import {RefreshTokenAsync} from "./modules/auth";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Loading} from "./containers/Loading";
import {StarCoinAsync} from "./modules/starcoin";
import {RootState} from "./modules/types";

const App = (props: any) => {
      const [isLoading, setIsLoading] = useState<boolean>(true);
      const user = useSelector((state: RootState) => state.auth.user);

      const dispatch = useDispatch();
      const [t] = useTranslation();

      useEffect(() => {
        const refreshToken = localStorage.getItem('refresh_token');

        if (refreshToken) handleToken();
        else setIsLoading(false);
      }, []);

      useEffect(() => {
        !isLoading && dispatch(StarCoinAsync());
      }, [isLoading]);

      const handleToken = async () => {
        const success = () => setIsLoading(false);
        dispatch(RefreshTokenAsync(success));
      };

      if (isLoading) return <Loading/>;

      return (
          <div>
            {props.children}
          </div>
      )
    }
;

export default App;
