import React, {useEffect, useState} from 'react';
import {Button, Input, Modal, Skeleton, Space, Table} from 'antd';
import * as styled from "./Style";
import {useSelector} from "react-redux";
import {isLoginedSelector} from "../../modules/selectors";
import {getApiAxiosWithToken} from "../../utils/axiosUtil";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {API_URL} from "../../config";
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./Style.css";
import {chineseZodiac} from 'src/utils/dragonConstants';

const BirthList = () => {
  const axiosWithToken = getApiAxiosWithToken();
  const [isLoading, setIsLoading] = useState(false);
  const [birthList, setBirthList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const isLogined = useSelector(isLoginedSelector);
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    fetchBirthList();
  }, []);

  const fetchBirthList = async () => {
    const births = await axiosWithToken.get(API_URL + '/births');
    const birthDataList = births.data;
    setBirthList(Object.values(birthDataList.data));
    setIsLoading(true);
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('')
  };

  const showDeleteModal = async (e: React.MouseEvent, birth_id: string) => {
    e.stopPropagation();
    Modal.confirm({
      title: '생일 삭제',
      content: (
        <div>
          <p>삭제하시겠습니까?</p>
        </div>
      ),
      async onOk() {
        handleDelete(birth_id);
      },
      onCancel() {
      },
    });
  };

  const handleDelete = async (birth_id: string) => {
    try {
      await axiosWithToken.delete(`${API_URL}/births/${birth_id}/fateframe`);
      await fetchBirthList();
    } catch (e) {
      console.log(e);
    }
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="default"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            검 색
            </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            초 기 화
            </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined
      style={{ color: filtered ? '#1890ff' : undefined, fontSize: '20px' }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const columns = [
    {
      title: t('birthList.animalYear'),
      dataIndex: 'zodiac',
      render: (zodiac: string) => <styled.ListRowHover><styled.ImgZodiac src={chineseZodiac[zodiac].img} /></styled.ListRowHover>,
      sorter: (a: any, b: any) => (chineseZodiac[a.zodiac].month - chineseZodiac[b.zodiac].month),
    },
    {
      title: t('birthList.name'),
      dataIndex: 'name',
      render: (text: string) => <styled.ListRowNameHover>{text}</styled.ListRowNameHover>,
      sorter: (a: any, b: any) => (b.name.charCodeAt(0) - a.name.charCodeAt(0)),
    },
    {
      title: t('birthList.gender'),
      dataIndex: 'gender',
      render: (gender: string) => <styled.ListRowHover>{t(`birthList.${gender}`)}</styled.ListRowHover>,
    },
    {
      title: t('birthList.age'),
      dataIndex: 'age',
      render: (age: number) => <styled.ListRowHover>{age}{t(`birthList.ageUnit`)}</styled.ListRowHover>,
      sorter: (a: any, b: any) => (a.age - b.age)
    },
    {
      title: t('birthList.birthday'),
      dataIndex: 'birthday',
      render: (text: string) => <styled.ListRowHover>{text.slice(0, 16)}</styled.ListRowHover>,
      sorter: (a: any, b: any) => (
        new Date(a.birthday).getTime() - new Date(b.birthday).getTime()
      ),
    },
    {
      title: t('birthList.updated'),
      dataIndex: 'created_at',
      render: (text: string) => <styled.ListRowHover className="updated_at">{text.slice(0, 16)}</styled.ListRowHover>,
      sorter: (a: any, b: any) => (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (birth_id: string) => <styled.ListRowHover onClick={(e: React.MouseEvent) => showDeleteModal(e, birth_id)}>삭제</styled.ListRowHover >,
    }
  ];

  return (
    (isLogined && isLoading ?
      <styled.listWrapper>
        <styled.tableWrapper>
          {/*<h1>{t("birthList.title")}</h1>*/}
          {/*@ts-ignore*/}
          <Table
            columns={columns}
            dataSource={birthList}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  history.push({
                    pathname: `/dragon/${record.id}`,
                    state: { knowTime: record.knowTime === 'True' }
                  })
                },
              };
            }}
          />
        </styled.tableWrapper>
      </styled.listWrapper>
      :
      <Skeleton>
        {t("isLogin.no")}
      </Skeleton>
    )
  );
};

export default BirthList;
