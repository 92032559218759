import styled from 'styled-components';
import {Btn} from "../Signup/Styles";

export const Container = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 70px 20px 137px;
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 35px;
`;

export const InputWrapper = styled.div`
  border-radius: 10px;
  display: flex;
  width: 100%;
  @media only screen and (max-width: 500px) {
    display: block;
    text-align: center;
  }
`;
export const Input = styled.input`
  border-radius: 10px;
  border: solid 2px #484848;
  max-height: 45px;
  padding: 15px 20px;
  outline: none;
  flex: 1;
  width: 100%;
  &::placeholder {
    color: #c1c1c1;
  }
`;
export const Label = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  &:first-child {
    margin-top: 0px;
  }
`;
export const BtnSend = styled(Btn)`
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    padding: 10px 20px;
  }
`;

interface IHelpText {
  isError: boolean;
}

export const HelpText = styled.div<IHelpText>`
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  color: ${(props) => props.isError ? 'red' : 'blue'};
`;
export const BtnSignup = styled(Btn)`
  margin-top: 30px;
  cursor: pointer;
`;
export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LoadingDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;
