import React from 'react';
import * as styled from './Style';

// const timeMap = ['자시', '축시', '인시', '묘시', '진시', '사시', '오시', '미시', '신시', '유시', '술시', '해시'];
const timeMap = [
  '0-1시', '1-3시', '3-5시', '5-7시', '7-9시',
  '9-11시', '11-13시', '13-15시', '15-17시',
  '17-19시', '19-21시', '21-23시', '23-0시'
];

export const TimeTab = ({ timeIndex, setTimeIndex }: { timeIndex: number, setTimeIndex: Function }) => {
  return (
    <styled.Container>
      {timeMap.map((t: string, idx: number) => {
        return (
          <styled.Item isActive={idx === timeIndex} key={'time-tab-' + idx} onClick={() => setTimeIndex(idx)}>
            {t}
          </styled.Item>
        )
      })}
      <styled.HighLighter top={timeIndex} />
    </styled.Container>
  )
};

export const MobileTimeTab = ({ timeIndex, setTimeIndex }: { timeIndex: number, setTimeIndex: Function }) => {
  return (
    <styled.MobileContainer>
      {timeMap.map((t: string, idx: number) => {
        return (
          <styled.MobileItem isActive={idx === timeIndex} key={'mobile-time-tab-' + idx} onClick={() => setTimeIndex(idx)}>
            {t}
          </styled.MobileItem>
        )
      })}
      <styled.MobileHighLighterWrapper left={timeIndex}>
        <styled.MobileHighLighter />
      </styled.MobileHighLighterWrapper>
    </styled.MobileContainer>
  )
};
