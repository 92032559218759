import styled from 'styled-components';
import iconRewind from '../../../assets/icon_rewind.png';

interface ISectionWrapper {
  backgroundColor: string;
}
export const SectionWrapper = styled.div<ISectionWrapper>`
  background-color: ${(props) => props.backgroundColor};
  padding: 40px;
  min-height: 200px;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;
export const ContentContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  position: relative;
`;
export const CardWrapper = styled.div`
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #ffffff;
`;
export const ProfileCardWrapper = styled(CardWrapper)`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 15px;
  }
`;
export const ImgProfile = styled.img`
  width: 350px;
  height: 270px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
export const ProfileInfoWrapper = styled.div`
  flex: 1;
  padding: 50px 20px;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    padding: 25px;
  }
`;
export const ProfileName = styled.div`
  font-size: 40px;
  font-weight: 900;
  color: #242424;
  margin-bottom: 50px;
  @media (max-width: 500px) {
    font-size: 30px;
  }
`;
export const ProfileInfoItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;
interface IProfileInfoItem {
  flexNum: number;
}
export const ProfileInfoItem = styled.div<IProfileInfoItem>`
  text-align: center;
  min-width: 50px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
export const ProfileInfoItemTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #979797;
`;
export const ProfileInfoItemContent = styled.div`
  font-size: 17px;
  font-weight: 900;
  color: #242424;
`;
export const BtnAnotherBirthDate = styled.div`
  padding: 8px 24px;
  border-radius: 22px;
  box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
  background-color: #242424;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
`;
export const TextBtnRewind = styled.div`
  color: white;
  margin-right: 10px;
`;
export const IconRewind = styled.img.attrs({ src: iconRewind })`
  width: 15px;
`;
export const DynamicFlexWrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const ColumnCardWrapper = styled(CardWrapper)`
  flex: 1;
  display: flex;
  &:first-child {
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    &:first-child {
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }
`;
interface ICardTitle {
  color: string;
}
export const CardTitle = styled.div<ICardTitle>`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 900;
  margin-left: 40px;
  margin-bottom: 30px;
  @media (max-width: 500px) {
    font-size: 24px;
    margin-left: 20px;
  }
`;
export const SingleColumnCardWrapper = styled(CardWrapper)`
  flex: 1;
  margin-bottom: 20px;
  padding: 30px 40px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;
export const CardDetailTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
`;
export const DoubleColumnCardWrapper = styled(CardWrapper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  &:first-child {
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    &:first-child {
      margin-right: 15px;
    }
  }
`;