import styled from 'styled-components';

export const AccountWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 35px;
`;

export const AccountFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: 500px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Label = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  &:first-child {
    margin-top: 0px;
  }
`;
export const InputWrapper = styled.div`
  border-radius: 10px;
  display: flex;
  width: 100%;
  @media only screen and (max-width: 500px) {
    display: block;
    text-align: center;
  }
`;
export const Input = styled.input`
  border-radius: 10px;
  border: solid 2px #484848;
  max-height: 45px;
  padding: 15px 20px;
  outline: none;
  flex: 1;
  width: 100%;
  &::placeholder {
    color: #c1c1c1;
  }
`;
export const Btn = styled.button`
  padding: 10px 35px;
  border-radius: 20px;
  margin-left: 10px;
  background-color: #242424;
  color: white;
  border: none;
  &:disabled {
    background-color: #c8c8c8;
    cursor: not-allowed;
  }
  @media only screen and (max-width: 500px) {
    margin-left: 0px;
    margin-top: 15px;
  }
`;
export const BtnSend = styled(Btn)`
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    padding: 10px 20px;
  }
`;
export const BtnCofirm = styled(Btn)`
`;
export const BtnSignup = styled(Btn)`
  margin-top: 30px;
  cursor: pointer;
`;
interface IHelpText {
  isError: boolean;
}
export const HelpText = styled.div<IHelpText>`
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  color: ${(props) => props.isError ? 'red' : 'blue'};
`;
export const CheckboxWrapper = styled.div`
  width: 100%;
  padding-left: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 10px;
`;
export const CheckboxText = styled.div`
  font-size: 16px;
  color: #c1c1c1;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: '#242424';
  }
`;
export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LoadingDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;
export const DuplicateEmail = styled.div`
  margin-left: 33%;
  text-align: left;
  color: red;
  padding-bottom: 24px;
  @media only screen and (max-width: 600px) {
    margin-left: 0%;
  }
`;
