import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 500px) {
    padding: 10px;
  }
`;
export const PolicyWrapper = styled.div`
  width: 70%;
  &:last-child {
    padding-bottom: 90px;
  }
`;
export const Title = styled.div`
  font-family: NotoSansCJKkr;
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 70px;
  padding-bottom: 20px;
  text-align: center;
  color: #242424;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 300px;
  padding: 34px 120px 19px 108px;
  border-radius: 10px;
  border: solid 2px #484848;
  @media (max-width: 500px) {
    padding: 10px;
  }
`;
