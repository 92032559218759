import styled from 'styled-components';
// import imgLogo from '../../assets/W_01_01/bo5_logo_white_b.png';
import imgLogo from '../../assets/logobeta_main.png'
import subImgLogo from '../../assets/logobeta_main_sub.png';
import downPointer from '../../assets/down_pointer.png'
import main1920 from '../../assets/W_01_01/image_main_1920.png';
import main1280 from '../../assets/W_01_01/image_main_1280.png';
import intro1 from '../../assets/W_01_01/image_intro_01.png';
import intro2 from '../../assets/img_02.png';
import intro3 from '../../assets/img_03.png';
import intro4 from '../../assets/img_intro_time.png';
import intro5 from '../../assets/img_05_modi.png';
import intro6 from '../../assets/img_06.png';
import iconMain1 from '../../assets/icon_main_01.png';
import iconMain2 from '../../assets/icon_main_02.png';
import iconMain3 from '../../assets/icon_main_03.png';

export const Container = styled.div`
`;

interface IContentWrapper {
  backgroundColor?: string;
}

export const IntroWrapper = styled.div`
  background-image: url(${main1920});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1500px) {
  background-image: url(${main1280});
  }
`;
export const ContentWrapper = styled.div<IContentWrapper>`
   background-color: ${(props) => props.backgroundColor || 'white'};
  @media (max-width: 650px) {
    display: block;
    text-align: center;
  }
`;
export const ContentWrapperColumn = styled.div<IContentWrapper>`
   background-color: ${(props) => props.backgroundColor || 'white'};
  @media (max-width: 650px) {
    display: block;
    text-align: center;
  }
`;
export const ContentContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const ContentContainerColumn = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const LogoWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 0px 30px;
  @media (max-width: 768px) {
   padding: 100px 30px 30px;
  }
`;
export const ImgLogo = styled.img.attrs({src: imgLogo})`
  margin-bottom: 15px;
  height: 162px;
  @media (max-width: 1000px) {
    height: 130px;
  }
  @media (max-width: 768px) {
    height: 100px;
  }
`;
export const LogoSubtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 75px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
export const DownText = styled.div`
  padding-top: 150px;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.69;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  @media (max-width: 500px) {
    padding-top: 10px;
  }
`;
export const DownPointer = styled.img.attrs({src: downPointer})`

`;
export const BtnNavigateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    display: block;
  }
`;
export const BtnNavitgateToForm = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: white;
  border-radius: 20px;
  color: #242424;
  font-size: 16px;
  padding: 13px 43px;
  font-weight: bold;
  margin-right: 20px;
  @media (max-width: 500px) {
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;
export const FlexWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 75px 0;
  overflow: hidden;
  @media (max-width: 768px) {
    text-align: center;
    padding: 40px 0;
  }
`;
export const FlexWrapperRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 75px 0;
  overflow: hidden;
  align-self: normal;
  @media (max-width: 768px) {
    text-align: center;
    padding: 40px 0;
  }
`;

interface IDescTitle {
  color?: string;
}

export const DescTitle = styled.div<IDescTitle>`
  font-size: 42px;
  font-weight: 900;
  margin-bottom: 28px;
  color: ${(props) => props.color || '#f6f6f6'};
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
export const DescSubTitle = styled.div<IDescTitle>`
  font-size: 16px;
  word-break: keep-all;
  font-weight: normal;
  color: ${(props) => props.color || '#f6f6f6'};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const DescIconSubTitle = styled(DescSubTitle)`
  padding-top: 30px;
  //max-width: 350px;
  text-align: center;
`;
export const ImgIntro = styled.img`
  margin: 0 auto;
  float: right;
  @media (max-width: 768px) {
    float: none;
  }
`;
export const SubLogoImg = styled.img.attrs({src: subImgLogo})`
`;
export const ImgIntro1 = styled(ImgIntro).attrs({src: intro1})`
  width: 90%;
  margin: 0 auto;
  max-width: 500px;
  float: left;
  //height: 348px;
  @media (max-width: 768px) {
    //height: 200px;
    float: none;
  }
`;
export const ImgIntro2 = styled(ImgIntro).attrs({src: intro2})`
  height: 339px;
  float: left;
  @media (max-width: 768px) {
    height: 200px;
    float: none;
  }
`;
export const ImgIntro3 = styled(ImgIntro).attrs({src: intro3})`
  height: 244px;
  float: left;
  @media (max-width: 768px) {
    height: 175px;
    float: none;
  }
`;
export const ImgIntro4 = styled(ImgIntro).attrs({src: intro4})`
  height: 244px;
  float: left;
  @media (max-width: 768px) {
    height: 175px;
    float: none;
  }
`;
export const ImgIntro5 = styled(ImgIntro).attrs({src: intro5})`
  height: 290px;
  float: left;
  @media (max-width: 768px) {
    height: 185px;
    float: none;
  }
`;
export const ImgIntro6 = styled(ImgIntro).attrs({src: intro6})`
  height: 244px;
  float: left;
  @media (max-width: 768px) {
    height: 175px;
    float: none;
  }
`;
export const IconMain1 = styled(ImgIntro).attrs({src: iconMain1})`
  height: 70px;
  float: left;
  @media (max-width: 768px) {
    height: 50px;
  }
`;
export const IconMain2 = styled(ImgIntro).attrs({src: iconMain2})`
  height: 70px;
  float: left;
  @media (max-width: 768px) {
    height: 50px;
  }
`;
export const IconMain3 = styled(ImgIntro).attrs({src: iconMain3})`
  height: 70px;
  float: left;
  @media (max-width: 768px) {
    height: 50px;
  }
`;
export const Line = styled.hr`
  height: 2px;
  background-color: #ebebeb;
`;
export const VerticalLine = styled.hr`
  background-color: #ebebeb;
`;
