import {Slider} from 'antd';
import React from 'react';
import DaeunResult from '../DaeunResult';
import {Chowho, FateFrameMain, FateFrameSub, SeaGod} from '../FateFrame';
import SajuResult from '../SajuResult';
import * as styled from './Style';
import {chineseZodiac, markChowhodo, markFateframe} from 'src/utils/dragonConstants';
import {MobileTimeTab, TimeTab} from '../TimeTab';
import {useHistory} from 'react-router-dom';

type TFateFrame = {
  saju_body: any[];
  daeun: any[];
  age: string;
  real_birth: string;
  nickname: string;
  gender: string;
  chowhodo: any;
  main_fateframe: any;
  sub_fateframe: any;
  seagod: any;
  whaframe: any;
  filter: any;
}

export const Result = ({ fateFrame, timeIndex, setTimeIndex }: { fateFrame: TFateFrame, timeIndex?: number, setTimeIndex?: Function }) => {
  const history = useHistory();
  if (!fateFrame.nickname) return <div>계산이 진행되고 있습니다.</div>;

  const {
    nickname,
    chowhodo,
    sub_fateframe,
    daeun,
    age,
    filter,
    gender,
    main_fateframe,
    real_birth,
    saju_body,
    seagod,
    whaframe
  } = fateFrame;

  let birthArr = [''];
  if (real_birth) birthArr = real_birth.split(' ');

  const twelveEarthlyBranches = () => {
    const earthlyBranchKey = saju_body[3].gabja[1];
    return chineseZodiac[earthlyBranchKey].img;
  };

  return (
    <>
      <styled.SectionWrapper backgroundColor={'#f6f6f6'}>
        <styled.ContentContainer>
          {(timeIndex !== undefined && setTimeIndex) && <TimeTab timeIndex={timeIndex} setTimeIndex={setTimeIndex} />}
          <styled.ProfileCardWrapper>
            <styled.ImgProfile src={twelveEarthlyBranches()}/>
            <styled.ProfileInfoWrapper>
              <styled.ProfileName>
                {nickname}
              </styled.ProfileName>
              <styled.ProfileInfoItemWrapper>
                <styled.ProfileInfoItem flexNum={1}>
                  <styled.ProfileInfoItemTitle>성별</styled.ProfileInfoItemTitle>
                  <styled.ProfileInfoItemContent>{gender === '남' ? '남자' : '여자'}</styled.ProfileInfoItemContent>
                </styled.ProfileInfoItem>
                <styled.ProfileInfoItem flexNum={1}>
                  <styled.ProfileInfoItemTitle>나이</styled.ProfileInfoItemTitle>
                  <styled.ProfileInfoItemContent>{age} 세</styled.ProfileInfoItemContent>
                </styled.ProfileInfoItem>
                <styled.ProfileInfoItem flexNum={2}>
                  <styled.ProfileInfoItemTitle>생년월일</styled.ProfileInfoItemTitle>
                  <styled.ProfileInfoItemContent>{birthArr[0]}</styled.ProfileInfoItemContent>
                </styled.ProfileInfoItem>
                {/*{*/}
                {/*  birthArr.length === 2 &&*/}
                {/*      <styled.ProfileInfoItem flexNum={1.2}>*/}
                {/*        <styled.ProfileInfoItemTitle>&nbsp;</styled.ProfileInfoItemTitle>*/}
                {/*        <styled.ProfileInfoItemContent>{birthArr[1] || '01:20'}</styled.ProfileInfoItemContent>*/}
                {/*      </styled.ProfileInfoItem>*/}
                {/*}*/}
                <styled.BtnAnotherBirthDate onClick={() => history.push('/birth')}>
                  <styled.TextBtnRewind>
                    다른 생일 입력
                  </styled.TextBtnRewind>
                  <styled.IconRewind />
                </styled.BtnAnotherBirthDate>
              </styled.ProfileInfoItemWrapper>
            </styled.ProfileInfoWrapper>
          </styled.ProfileCardWrapper>
          {(timeIndex !== undefined && setTimeIndex) && <MobileTimeTab timeIndex={timeIndex} setTimeIndex={setTimeIndex} />}
          <styled.DynamicFlexWrapper>
            <styled.ColumnCardWrapper>
              <SajuResult sajuBody={saju_body} age={age} />
            </styled.ColumnCardWrapper>
            <styled.ColumnCardWrapper>
              <DaeunResult daeun={daeun} age={age} />
            </styled.ColumnCardWrapper>
          </styled.DynamicFlexWrapper>
        </styled.ContentContainer>
      </styled.SectionWrapper>
      <styled.SectionWrapper backgroundColor={'#242424'}>
        <styled.ContentContainer>
          <styled.CardTitle color="#ffffff">AI 격국 용신 정보</styled.CardTitle>
          <styled.DynamicFlexWrapper>
            <styled.ColumnCardWrapper style={{ display: 'block', background: 'none', boxShadow: 'none' }}>
              <styled.SingleColumnCardWrapper>
                <styled.CardDetailTitle>신강신약</styled.CardDetailTitle>
                <>
                  <Slider
                    marks={markFateframe} included={true}
                    value={main_fateframe.percentage} disabled={true}
                  />
                </>
              </styled.SingleColumnCardWrapper>
            </styled.ColumnCardWrapper>
            <styled.ColumnCardWrapper style={{ display: 'block', background: 'none', boxShadow: 'none' }}>
              <styled.SingleColumnCardWrapper>
                <styled.CardDetailTitle>조후도</styled.CardDetailTitle>
                <>
                  <Slider
                    marks={markChowhodo} included={true}
                    value={chowhodo.percentage} disabled={true}
                  />
                </>
              </styled.SingleColumnCardWrapper>
            </styled.ColumnCardWrapper>
          </styled.DynamicFlexWrapper>
          <styled.DynamicFlexWrapper>
            <styled.ColumnCardWrapper style={{ background: 'none', boxShadow: 'none' }}>
              <styled.DoubleColumnCardWrapper style={{marginRight: '0px'}}>
                <styled.CardDetailTitle>AI 방식의 격 분류 및 명칭</styled.CardDetailTitle>
                <>
                  <FateFrameMain main={main_fateframe} special={filter} dayGan={saju_body[1].gabja[0]}/>
                </>
              </styled.DoubleColumnCardWrapper>
              {/*<styled.DoubleColumnCardWrapper style={{ background: 'none', boxShadow: 'none' }} />*/}
            </styled.ColumnCardWrapper>
            <styled.ColumnCardWrapper style={{ background: 'none', boxShadow: 'none' }}>
              <styled.DoubleColumnCardWrapper>
                <styled.CardDetailTitle>격국에 따른 용신</styled.CardDetailTitle>
                <>
                  <SeaGod seagod={seagod}/>
                </>
              </styled.DoubleColumnCardWrapper>
              <styled.DoubleColumnCardWrapper>
                <styled.CardDetailTitle>조후 용신</styled.CardDetailTitle>
                <>
                  <Chowho chowho={chowhodo}/>
                </>
              </styled.DoubleColumnCardWrapper>
              {/*<styled.DoubleColumnCardWrapper style={{ background: 'none', boxShadow: 'none' }} />*/}
            </styled.ColumnCardWrapper>
          </styled.DynamicFlexWrapper>
        </styled.ContentContainer>
      </styled.SectionWrapper>
      <styled.SectionWrapper backgroundColor={'#f6f6f6'}>
        <styled.ContentContainer>
          <styled.CardTitle color="#242424">기준 별 격국 용신 정보</styled.CardTitle>
          <styled.DynamicFlexWrapper>
            <styled.ColumnCardWrapper style={{ background: 'none', boxShadow: 'none' }}>
              <styled.DoubleColumnCardWrapper>
                <styled.CardDetailTitle>월지 중심 육친의 투출에 따른 격의 성립과 명칭</styled.CardDetailTitle>
                <FateFrameSub content={sub_fateframe[0].성격_8정격.length !== 0 ? `팔정격 투출기준 - ${sub_fateframe[0].성격_8정격}` : '해당없음.'} />
              </styled.DoubleColumnCardWrapper>
              <styled.DoubleColumnCardWrapper>
                <styled.CardDetailTitle>10격(월지 강약) 오행 강약에 따른 격의 성립과 명칭</styled.CardDetailTitle>
                <FateFrameSub content={sub_fateframe[1].성격_10정격.length !== 0 ? `십정격 투출기준 - ${sub_fateframe[1].성격_10정격}` : '해당없음.'} />
              </styled.DoubleColumnCardWrapper>
            </styled.ColumnCardWrapper>
            <styled.ColumnCardWrapper style={{ background: 'none', boxShadow: 'none' }}>
              <styled.DoubleColumnCardWrapper>
                <styled.CardDetailTitle>오행 강약 대세 기준 성격과 명칭</styled.CardDetailTitle>
                <FateFrameSub content={sub_fateframe[2].성격_왕자.length !== 0 ? `왕자입력 - ${sub_fateframe[2].성격_왕자}` : '해당없음.'} />
              </styled.DoubleColumnCardWrapper>
              <styled.DoubleColumnCardWrapper>
                <styled.CardDetailTitle>지장간의 사령에 따른 격의 성립과 명칭</styled.CardDetailTitle>
                <FateFrameSub
                    content={sub_fateframe[3].성격_월득령.length !== 0 ? `월지 당령 기준격 - ${sub_fateframe[3].성격_월득령}` : '해당없음.'}/>
              </styled.DoubleColumnCardWrapper>
            </styled.ColumnCardWrapper>
          </styled.DynamicFlexWrapper>
        </styled.ContentContainer>
      </styled.SectionWrapper>
    </>
  )
};
