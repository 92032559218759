import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Router} from "react-router";
import {createBrowserHistory} from "history";
import Root from './routes';
import './lang/i18n';
import './customAnt.css';
import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./modules";
import thunk from 'redux-thunk';
import {Provider} from "react-redux";

const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(
    thunk
)));

ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Root/>
      </Router>
    </Provider>,
    document.getElementById('root')
)
;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
