import React from 'react';
import SajuLetter from "../SajuLetter";
import * as styled from './Style';


const ageLetterColor = (age: number, daeunAge: string) => {
  if (parseInt(daeunAge) < age && age < parseInt(daeunAge) + 10) {
    return 'rgb(252, 13, 27)';
  }
  return '#242424';
};

const SajuResult = ({ sajuBody, age }: any) => {
  return (
    <styled.TableRowWrapper>
      {sajuBody.map((s: any, idx: number) => {
        const [upperLetter, downLetter] = s.gabja;
        return (
          <styled.TableCellWrapper key={s.name}>
            <styled.TableTitle daeunAgeColor={ageLetterColor(age, s.age)}>
              {s.name}
            </styled.TableTitle>
            <styled.SajuColumnWrapper>
              <SajuLetter hanja={upperLetter} index={idx}/>
              <SajuLetter hanja={downLetter} index={idx}/>
            </styled.SajuColumnWrapper>
          </styled.TableCellWrapper>
        )
      })}
    </styled.TableRowWrapper>
  )
};

export default SajuResult;
