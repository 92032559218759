import {combineReducers} from "redux";
import auth from './auth';
import starcoin from './starcoin';

const rootReducer = combineReducers({
  auth,
  starcoin
});

export default rootReducer;
