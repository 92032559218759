import styled from 'styled-components';

export const listWrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  background-color: #f6f6f6;
`;

export const tableWrapper = styled.div`
  padding-top: 60px;
  max-width: 1080px;
  margin: 0 auto;
  height: 100%;
  min-width: 768px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 768px) {
    padding-top: 30px;
    height: auto;
    width: 100%;
    min-width: auto;
    max-width: auto;
  }
`;

export const ListRowHover = styled.a`
 color: black;
 position: relative;
 display: inline-block;
 transition: .3s;
 text-decoration: none;
 font-weight: 700;
 &::after {
   position: absolute;
   bottom: .3em;
   left: 0;
   content: '';
   width: 100%;
   height: 1px;
   opacity: 0;
   transition: .3s;
 }
 &:hover::after {
   bottom: 0;
   opacity: 1;
 }
 &:hover {
   color: RGB(37, 147, 252);
 }
`;

export const ListRowNameHover = styled(ListRowHover)`
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ImgZodiac = styled.img`
  max-width: 100px;
  height: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  @media (max-width: 500px) {
    max-width: 70px;
  }
  @media (max-width: 330px) {
    height: 50px;
  }
`;
