import React, {useEffect, useState} from 'react';
import {Form, Input} from 'antd';
import {Redirect, useHistory} from "react-router";
import "antd/dist/antd.css";
import * as styled from './Styles';
import {useDispatch, useSelector} from "react-redux";
import {LoginAsync, T_LOGIN_PARAMS} from "../../modules/auth";
import {useTranslation} from "react-i18next";
import {isLoginedSelector} from "../../modules/selectors";
import KaKaoLogin from 'react-kakao-login';
import {API_URL} from "../../config";

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16},
};

declare global {
  interface Window {
    naver: any;
  }
}

const {naver} = window;

export const Login = () => {
  const [isInvalidAccount, setIsInvalidAccount] = useState(false);

  const history = useHistory();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isLogined = useSelector(isLoginedSelector);

  useEffect(Login, []);

  function Login() {
    Naver();
    UserProfile();
  }

  function Naver() {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: 'ESXH8YUFW43bbYW69Z67',
      callbackUrl: 'https://bo5stars.com/login',
      callbackHandle: true,
      loginButton: {
        color: 'green',
        type: 5,
        height: 50,
      }
    });
    naverLogin.init();
  }

  const UserProfile = () => {
    window.location.href.includes('access_token') && GetUser();

    function UserSnsLogin(token: string) {
      const success = () => {
        history.push('/birth');
      };
      const values = {
        ContentType: "application/json",
        SnsType: "naver",
        Authorization: token,
      };
      dispatch(LoginAsync(values, success, () => {
      }, () => {
      }));
    }

    function GetUser() {
      const location = window.location.href.split('=')[1];
      const token = location.split('&')[0];
      fetch(API_URL + `/signup-naver`, {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "Sns-type": "naver",
              "Authorization": token
            },
          }
      ).then((res) => {
        UserSnsLogin(token);
        return res.json();
      }).catch(
          err => console.log("err: ", err)
      )
    }
  };

  function handleKakaoSuccess(res: any) {
    const data = {"email": res.profile.kakao_account.email, "nickname": res.profile.kakao_account.profile.nickname};
    fetch(API_URL + `/signup-kakao`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json",
          },
        }
    ).then((res) => {
      UserSnsLogin(data);
      return res.json();
    }).catch(
        err => console.log("err: ", err)
    );

    const UserSnsLogin = (data: any) => {
      const success = () => {
        history.push('/birth');
      };
      const values = {
        ContentType: "application/json",
        SnsType: "kakao",
        email: data.email,
        nickname: data.nickname,
      };

      dispatch(LoginAsync(values, success, () => {
      }, () => {
      }));
    }
  }

  function handleKakaoFail(res: any) {
    console.log(res);
  }

  const onFinish = async (values: T_LOGIN_PARAMS) => {
    const success = () => {
      history.push('/birth');
    };
    const validAccount = () => setIsInvalidAccount(false);
    const invalidAccount = () => setIsInvalidAccount(true);
    dispatch(LoginAsync(values, success, validAccount, invalidAccount));
  };

  const onFinishFailed = (errorInfo: object) => {
    console.log('Failed:', errorInfo);
  };

  return (
      isLogined ? <Redirect to={"/birth"}/> :
          <styled.LoginWrapper>
            <Form
                {...layout}
                name="basic"
                initialValues={{remember: true}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
              <styled.LoginFormWrapper>
                <styled.LoginTitle>
                  로그인
                </styled.LoginTitle>
                <styled.EmailSubTitle>
                  이메일
                </styled.EmailSubTitle>
                <Form.Item
                    // label={t("loginForm.email")}
                    name="email"
                    rules={[
                      {required: true, message: t('loginForm.inputEmail')},
                      // {type: 'email', message: t('loginForm.formatEmail')}
                    ]}
                >
                  <styled.InputEmail placeholder={"이메일을 입력해주세요"}/>
                </Form.Item>
                <styled.EmailSubTitle>
                  패스워드
                </styled.EmailSubTitle>
                <Form.Item
                    // label={t("loginForm.password")}
                    name="password"
                    rules={[{required: true, message: t('loginForm.inputPassword')}]}
                >
                  <Input.Password
                      placeholder={"********"}
                      style={{width: '100%', height: '48px', borderRadius: '10px', border: 'solid 2px #484848'}}
                  />
                </Form.Item>
                {isInvalidAccount &&
                <styled.InvalidAccountWrapper>
                  <styled.InvalidAccountText>
                    {t("loginForm.invalidAccount")}
                  </styled.InvalidAccountText>
                </styled.InvalidAccountWrapper>
                }
                <styled.Wrapper>
                  <styled.PasswordWrapper onClick={() => history.push('/help/password')}>
                    비밀번호를 잊으셨나요?
                  </styled.PasswordWrapper>
                  <styled.SignupWrapper
                      onClick={() => history.push('/signup')}
                  >
                    회원가입 하기
                  </styled.SignupWrapper>
                </styled.Wrapper>
                <styled.SNSContainer>
                  <styled.NaverContainer
                      onClick={Login}
                      id="naverIdLogin"
                  />
                  <styled.KakaoContainer>
                    <KaKaoLogin
                        token={'212f9a371ddb28213640dcd9207bfeb9'}
                        onSuccess={handleKakaoSuccess}
                        onFail={handleKakaoFail}
                    />
                  </styled.KakaoContainer>
                </styled.SNSContainer>
                <styled.BtnLoginWrapper>
                  <styled.BtnLogin>
                    <styled.BtnLetter>
                      {t("loginForm.login")}
                    </styled.BtnLetter>
                  </styled.BtnLogin>
                </styled.BtnLoginWrapper>
              </styled.LoginFormWrapper>
            </Form>
          </styled.LoginWrapper>
  );
};
