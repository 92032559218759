import React from 'react';
import {TFAQ} from 'src/containers/GuideFaq';
import * as styled from './Style';

interface IFAQListItem {
  tag: number;
  faq: TFAQ;
  open: boolean;
  idx: number;
  handleClick: (n: number) => void;
}

const FAQListItem = ({tag, faq, open, idx, handleClick}: IFAQListItem) => {
  return (
    <styled.FAQListItemWrapper onClick={() => handleClick(idx)} >
      <styled.FAQListItemQuestionWrapper>
        <styled.FAQLeftWrapper>
          <styled.IconQuestion />
        </styled.FAQLeftWrapper>
        <styled.FAQRightWrapper>
          <styled.FAQListItemQuestion>
            {faq.question}
          </styled.FAQListItemQuestion>
        </styled.FAQRightWrapper>
      </styled.FAQListItemQuestionWrapper>
      <styled.FAQListItemAnswerWrapper>
        <styled.FAQLeftWrapper />
        <styled.FAQRightWrapper>
          {open &&
            <styled.FAQListItemAnswer>
              <styled.FAQListItemPre>
                {faq.answer}
                {tag === 0 ?
                    <div><br/>문의 혹은 요청이 있다면. <a href={"mailto:info.bo5stars@gmail.com"}>info.bo5stars@gmail.com</a>으로
                      문의주시기 바랍니다.</div> :
                    null
                }
              </styled.FAQListItemPre>
            </styled.FAQListItemAnswer>
          }
        </styled.FAQRightWrapper>
      </styled.FAQListItemAnswerWrapper>
    </styled.FAQListItemWrapper>
  )
};

export default FAQListItem;
