import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyCYfxS8UzJM0r8BkVckPSmdhUOwfZGX9aA');
Geocode.setLanguage('kr');

export const getLatLngFromAddr = async (currentAddr: NodeListOf<HTMLElement>) => {
  // @ts-ignore
  return Geocode.fromAddress(currentAddr)
      .then(response => {
        const {lat, lng} = response.results[0].geometry.location;
        return {lat, lng}
      })
      .catch(err => console.log(err))
};

// export default getLatLngFromAddr;
