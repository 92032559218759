import React, { useState } from 'react';
import * as styled from './Style';
import { CloseOutlined } from "@ant-design/icons/lib";

interface IGuideModalProps {
  show: boolean,
  onClose: () => void,
}

const GuideModal = ({ show, onClose }: IGuideModalProps) => {
  const [neverSee, setNeverSee] = useState<boolean>(false);
  const handleClose = () => {
    neverSee && localStorage.setItem('never_see_guide', 'true');
    onClose();
  }
  if (!show) return null;

  return (
    <styled.Wrapper>
      <styled.Dimmed onClick={onClose} />
      <styled.ModalWrapper>
        <styled.BtnClose onClick={handleClose}><CloseOutlined /></styled.BtnClose>
        <styled.Title>확인해보세요!</styled.Title>
        <styled.ContentWrapper>
          <styled.ImgGuide1 />
          <styled.ContentTitle>
            출생 시간을 모를 경우에 13개의 결과값을 확인하고 비교하여 <br />
            더욱 더 정확한 분석이 가능합니다!
          </styled.ContentTitle>
          <styled.ContentDesc>
            WEB에서 좌측바, MOBILE에서 상단 시간 바에서 <br />
            비교하고 싶은 시간을 확인해보세요
          </styled.ContentDesc>
          <styled.ImgGuide2 />
          <styled.ContentTitle>
            검은색 박스가 된 격명칭들을 클릭/터치해보세요! <br />
            밸런스오브파이브만의 격 해석을 확인하실 수 있습니다
          </styled.ContentTitle>
          <styled.ContentDesc>
            WEB에서 클릭, MOBILE에서 터치해주세요!
          </styled.ContentDesc>
        </styled.ContentWrapper>
        <styled.CheckBoxWrapper>
          <styled.CheckBox checked={neverSee} onChange={() => setNeverSee((v) => !v)} id="never-see-guide" />
          <styled.TextCheckBox htmlFor="never-see-guide">다시 보지 않기</styled.TextCheckBox>
        </styled.CheckBoxWrapper>
        <styled.BtnWrapper>
          <styled.BtnCheck onClick={handleClose}>
            확인
            </styled.BtnCheck>
        </styled.BtnWrapper>
      </styled.ModalWrapper>
    </styled.Wrapper>
  );
};

export default GuideModal;
